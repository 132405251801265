import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EditCompany() {
  const baseURL = BASE_URL + "company/add";
  const countyListURL = BASE_URL + "county/list";
  const townListURL = BASE_URL + "town/listbycounty";
  const uploadRsvtiAuthorisationURL = BASE_URL + "company/uploadsingleauthorisation";
  const uploadCompanyAndRsvtiAuthorisationURL = BASE_URL + "company/uploadbothauthorisations";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    companyName: "",
    shortName: "",
    code: "",
    registrationNumber: "",
    fiscalCode: "",
    phone: "",
    fax: "",
    email: "",
    cncirContractNumber: "",
    cncirContractDate: "",
    companyMainContactLastName: "",
    companyMainContactFirstName: "",
    companyMainContactRole: "",
    bankAccount: "",
    bankName: "",
    bankLocation: "",
    countyUuid: "",
    townUuid: "",
    addressDetails1: "",
    addressDetails2: "",
    postalCode: "",
    otherAddressDetails: "",
    rsvtiOperatorCompanyName: "",
    rsvtiOperatorCompanyPermitNumber: "",
    rsvtiOperatorCompanyIssuedDate: "",
    rsvtiOperatorCompanyExpirationDate: "",
    rsvtiOperatorLastName: "",
    rsvtiOperatorFirstName: "",
    rsvtiOperatorPermitNumber: "",
    rsvtiOperatorPermitIssuedDate: "",
    rsvtiOperatorPermitExpirationDate: "",
    rsvtiOperatorPhone: "",
    rsvtiOperatorEmail: "",
    companyRVSTIBoardMainPersonLastName: "",
    companyRVSTIBoardMainPersonFirstName: "",
    companyRVSTIBoardMainPersonRole: "",
    companyRVSTIBoardMainPersonPhone: "",
    companyRVSTIBoardMainPersonEmail: "",
    companyRVSTIBoardSecondPersonLastName: "",
    companyRVSTIBoardSecondPersonFirstName: "",
    companyRVSTIBoardThirdPersonLastName: "",
    companyRVSTIBoardThirdPersonFirstName: "",
  });

  // used to dynamically load the towns drop down
  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);
  const [ddOptionsTown, setDdOptionsTown] = useState([]);
  const [fileCompanyA, setFileCompanyA] = useState(null);
  const [fileRsvtiPersonA, setFileRsvtiPersonA] = useState(null);

  const inputs = [
    {
      id: 0,
      name: "companyName",
      type: "text",
      placeholder: "Denumire firmă",
      label: "Denumire firmă",
    },
    {
      id: 1,
      name: "shortName",
      type: "text",
      placeholder: "Nume prescurtat firmă (pentru documente)",
      label: "Nume prescurtat firmă (pentru documente)",
    },
    {
      id: 2,
      name: "code",
      type: "text",
      placeholder: "Cod sediu social",
      label: "Cod sediu social",
    },
    {
      id: 3,
      name: "registrationNumber",
      type: "text",
      placeholder: "Număr înregistrare registrul comerțului",
      label: "Număr înregistrare registrul comerțului",
    },
    {
      id: 4,
      name: "fiscalCode",
      type: "text",
      placeholder: "Cod TVA",
      label: "Cod TVA",
    },
    {
      id: 5,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon fix",
      label: "Număr telefon fix",
    },
    {
      id: 6,
      name: "fax",
      type: "text",
      placeholder: "Număr fax",
      label: "Număr fax",
    },
    {
      id: 7,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail",
      label: "Adresă e-mail",
    },
    {
      id: 8,
      name: "cncirContractNumber",
      type: "text",
      placeholder: "Număr contract CNCIR",
      label: "Număr contract CNCIR",
    },
    {
      id: 9,
      name: "cncirContractDate",
      type: "date",
      placeholder: "Dată contract CNCIR",
      label: "Dată contract CNCIR",
    },
    {
      id: 10,
      name: "companyMainContactLastName",
      type: "text",
      placeholder: "Nume familie reprezentant firmă",
      label: "Nume familie reprezentant firmă",
    },
    {
      id: 11,
      name: "companyMainContactFirstName",
      type: "text",
      placeholder: "Prenume reprezentant firmă",
      label: "Prenume reprezentant firmă",
    },
    {
      id: 12,
      name: "companyMainContactRole",
      type: "text",
      placeholder: "Funcție reprezentant firmă",
      label: "Funcție reprezentant firmă",
    },
    {
      id: 13,
      name: "bankAccount",
      type: "text",
      placeholder: "Număr cont bancar (IBAN)",
      label: "Număr cont bancar (IBAN)",
    },
    {
      id: 14,
      name: "bankName",
      type: "text",
      placeholder: "Denumire bancă",
      label: "Denumire bancă",
    },
    {
      id: 15,
      name: "bankLocation",
      type: "text",
      placeholder: "Sucursală bancă",
      label: "Sucursală bancă",
    },
    {
      id: 16,
      name: "countyUuid",
      type: "selectClass",
      placeholder: "Județ/sector",
      label: "Județ/sector",
    },
    {
      id: 17,
      name: "townUuid",
      type: "selectClass",
      placeholder: "Localitate",
      label: "Localitate",
    },
    {
      id: 18,
      name: "addressDetails1",
      type: "text",
      placeholder: "strada (bulevard, aleea, etc) si numarul",
      label: "Adresa 1",
    },
    {
      id: 19,
      name: "addressDetails2",
      type: "text",
      placeholder: "bloc, scara, etaj, apartment",
      label: "Adresa 2",
    },
    {
      id: 20,
      name: "postalCode",
      type: "text",
      placeholder: "Cod poștal",
      label: "Cod poștal",
    },
    {
      id: 21,
      name: "otherAddressDetails",
      type: "text",
      placeholder: "Alte detalii adresă",
      label: "Alte detalii adresă",
    },
    {
      id: 22,
      name: "rsvtiOperatorCompanyName",
      type: "text",
      placeholder: "Nume firmă operator RSVTI",
      label: "Nume firmă operator RSVTI",
    },
    {
      id: 23,
      name: "rsvtiOperatorCompanyPermitNumber",
      type: "text",
      placeholder: "Număr autorizaţie firmă operator RSVTI",
      label: "Număr autorizaţie firmă operator RSVTI",
    },
    {
      id: 24,
      name: "rsvtiOperatorCompanyIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizaţie firmă operator RSVTI",
      label: "Dată eliberare autorizaţie firmă operator RSVTI",
    },
    {
      id: 25,
      name: "rsvtiOperatorCompanyExpirationDate",
      type: "date",
      placeholder: "Valabilitate autorizaţie firmă operator RSVTI",
      label: "Valabilitate autorizaţie firmă operator RSVTI",
    },
    {
      id: 26,
      name: "rsvtiOperatorLastName",
      type: "text",
      placeholder: "Nume familie operator RSVTI",
      label: "Nume familie operator RSVTI",
    },
    {
      id: 27,
      name: "rsvtiOperatorFirstName",
      type: "text",
      placeholder: "Prenume operator RSVTI",
      label: "Prenume operator RSVTI",
    },
    {
      id: 28,
      name: "rsvtiOperatorPermitNumber",
      type: "text",
      placeholder: "Număr autorizație operator RSVTI",
      label: "Număr autorizație operator RSVTI",
    },
    {
      id: 29,
      name: "rsvtiOperatorPermitIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizație operator RSVTI",
      label: "Dată eliberare autorizație operator RSVTI",
    },
    {
      id: 30,
      name: "rsvtiOperatorPermitExpirationDate",
      type: "date",
      placeholder: "Valabilitate autorizație operator RSVTI",
      label: "Valabilitate autorizație operator RSVTI",
    },
    {
      id: 31,
      name: "rsvtiOperatorPhone",
      type: "text",
      placeholder: "Număr telefon mobil operator RSVTI",
      label: "Număr telefon mobil operator RSVTI",
    },
    {
      id: 32,
      name: "rsvtiOperatorEmail",
      type: "text",
      placeholder: "Adresă e-mail operator RSVTI",
      label: "Adresă e-mail operator RSVTI",
    },
    {
      id: 33,
      name: "companyRVSTIBoardMainPersonLastName",
      type: "text",
      placeholder: "Nume familie persoană responsabilă semnare acte ISCIR",
      label: "Nume familie persoană responsabilă semnare acte ISCIR",
    },
    {
      id: 34,
      name: "companyRVSTIBoardMainPersonFirstName",
      type: "text",
      placeholder: "Prenume persoană responsabilă semnare acte ISCIR",
      label: "Prenume persoană responsabilă semnare acte ISCIR",
    },
    {
      id: 35,
      name: "companyRVSTIBoardMainPersonRole",
      type: "text",
      placeholder: "Funcție persoană responsabilă semnare acte ISCIR",
      label: "Funcție persoană responsabilă semnare acte ISCIR",
    },
    {
      id: 36,
      name: "companyRVSTIBoardMainPersonPhone",
      type: "text",
      placeholder: "Număr telefon mobil persoană responsabilă semnare acte ISCIR",
      label: "Număr telefon mobil persoană responsabilă semnare acte ISCIR",
    },
    {
      id: 37,
      name: "companyRVSTIBoardMainPersonEmail",
      type: "text",
      placeholder: "E-mail persoană responsabilă semnare acte ISCIR",
      label: "E-mail persoană responsabilă semnare acte ISCIR",
    },
    {
      id: 38,
      name: "companyRVSTIBoardSecondPersonLastName",
      type: "text",
      placeholder: "Nume familie membru 2 comisie tehnică",
      label: "Nume familie  membru 2 comisie tehnică",
    },
    {
      id: 39,
      name: "companyRVSTIBoardSecondPersonFirstName",
      type: "text",
      placeholder: "Prenume  membru 2 comisie tehnică",
      label: "Prenume  membru 2 comisie tehnică",
    },
    {
      id: 40,
      name: "companyRVSTIBoardThirdPersonLastName",
      type: "text",
      placeholder: "Nume familie membru 3 comisie tehnică",
      label: "Nume familie membru 3 comisie tehnică",
    },
    {
      id: 41,
      name: "companyRVSTIBoardThirdPersonFirstName",
      type: "text",
      placeholder: "Prenume membru 3 comisie tehnică",
      label: "Prenume membru 3 comisie tehnică",
    },
  ];

  // load counties
  useEffect(() => {
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        console.log("Error loading counties");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCounty = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
    setDdOptionsTown([]);
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Towns loaded");
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        console.log("Error loading towns");
        console.log(error);
        alert("Orasele nu au putut fi citite");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        baseURL,
        {
          companyName: values.companyName,
          shortName: values.shortName,
          code: values.code,
          registrationNumber: values.registrationNumber,
          fiscalCode: values.fiscalCode,
          phone: values.phone,
          fax: values.fax,
          email: values.email,
          cncirContractNumber: values.cncirContractNumber,
          cncirContractDate: values.cncirContractDate,
          companyMainContactLastName: values.companyMainContactLastName,
          companyMainContactFirstName: values.companyMainContactFirstName,
          companyMainContactRole: values.companyMainContactRole,
          bankAccount: values.bankAccount,
          bankName: values.bankName,
          bankLocation: values.bankLocation,
          countyUuid: values.countyUuid,
          townUuid: values.townUuid,
          addressDetails1: values.addressDetails1,
          addressDetails2: values.addressDetails2,
          postalCode: values.postalCode,
          otherAddressDetails: values.otherAddressDetails,
          rsvtiOperatorCompanyName: values.rsvtiOperatorCompanyName,
          rsvtiOperatorCompanyPermitNumber: values.rsvtiOperatorCompanyPermitNumber,
          rsvtiOperatorCompanyIssuedDate: values.rsvtiOperatorCompanyIssuedDate,
          rsvtiOperatorCompanyExpirationDate: values.rsvtiOperatorCompanyExpirationDate,
          rsvtiOperatorLastName: values.rsvtiOperatorLastName,
          rsvtiOperatorFirstName: values.rsvtiOperatorFirstName,
          rsvtiOperatorPermitNumber: values.rsvtiOperatorPermitNumber,
          rsvtiOperatorPermitIssuedDate: values.rsvtiOperatorPermitIssuedDate,
          rsvtiOperatorPermitExpirationDate: values.rsvtiOperatorPermitExpirationDate,
          rsvtiOperatorPhone: values.rsvtiOperatorPhone,
          rsvtiOperatorEmail: values.rsvtiOperatorEmail,
          companyRVSTIBoardMainPersonLastName: values.companyRVSTIBoardMainPersonLastName,
          companyRVSTIBoardMainPersonFirstName: values.companyRVSTIBoardMainPersonFirstName,
          companyRVSTIBoardMainPersonRole: values.companyRVSTIBoardMainPersonRole,
          companyRVSTIBoardMainPersonPhone: values.companyRVSTIBoardMainPersonPhone,
          companyRVSTIBoardMainPersonEmail: values.companyRVSTIBoardMainPersonEmail,
          companyRVSTIBoardSecondPersonLastName: values.companyRVSTIBoardSecondPersonLastName,
          companyRVSTIBoardSecondPersonFirstName: values.companyRVSTIBoardSecondPersonFirstName,
          companyRVSTIBoardThirdPersonLastName: values.companyRVSTIBoardThirdPersonLastName,
          companyRVSTIBoardThirdPersonFirstName: values.companyRVSTIBoardThirdPersonFirstName,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        console.log("Company added");
        console.log(res.data);
        alert("Firma adaugata");
        uploadAuthorisations(res.data);
        let file = document.querySelector(".fileAF");
        if (file !== null) {
          file.value = "";
        }
        file = document.querySelector(".fileAP");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        console.log("Error adding company");
        console.log(error);
        alert("Firma nu a putut fi adaugata");
      });
    // console.log("Submit");
  };

  const uploadAuthorisations = (rsvtiOperatorUuid) => {
    if (fileRsvtiPersonA === null) {
      return;
    }
    const formData = new FormData();
    formData.append("rsvtiOperatorUuid", rsvtiOperatorUuid);
    formData.append("fileRsvtiPersonA", fileRsvtiPersonA);
    let URL = uploadCompanyAndRsvtiAuthorisationURL;
    if (fileCompanyA === null) {
      URL = uploadRsvtiAuthorisationURL;
    } else {
      formData.append("fileCompanyA", fileCompanyA);
    }
    axios
      .post(URL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        console.log("Authorisations uploaded");
        console.log(res.data);
        alert("Autorizatii salvate");
      })
      .catch((error) => {
        console.log("Error uploading authorisations");
        console.log(error);
        alert("Autorizatiile nu au putut fi salvate");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button className="buttonClassGreen">Modifică firmă</button>
      <div className="spaceDiv" />
      <Link to={"/company/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyList)} alt="Listă firme" title="Listă firme" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      <form className="formArea">
        {/* Header Company */}
        <div className="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii firmă">
              {/* Company Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Company Short Name and Code */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[2].id} {...inputs[2]} value={values[inputs[2].name]} onChange={onChange} />
                </div>
              </div>

              {/* Registration Number and Fiscal Code */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[3].id} {...inputs[3]} value={values[inputs[3].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
                </div>
              </div>

              {/* Phone and Fax */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[5].id} {...inputs[5]} value={values[inputs[5].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[6].id} {...inputs[6]} value={values[inputs[6].name]} onChange={onChange} />
                </div>
              </div>

              {/* Email */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[7].id} {...inputs[7]} value={values[inputs[7].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <FormInput key={inputs[8].id} {...inputs[8]} value={values[inputs[8].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[9].id} {...inputs[9]} value={values[inputs[9].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii reprezentant firmă">
              {/* Main Contact Last Name and Main Contact First Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[10].id} {...inputs[10]} value={values[inputs[10].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[11].id} {...inputs[11]} value={values[inputs[11].name]} onChange={onChange} />
                </div>
              </div>

              {/* Main Contact Role */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[12].id} {...inputs[12]} value={values[inputs[12].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii bancare">
              {/* Banck Account */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[13].id} {...inputs[13]} value={values[inputs[13].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Bank Name and Bank Location */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[14].id} {...inputs[14]} value={values[inputs[14].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[15].id} {...inputs[15]} value={values[inputs[15].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare adresă sediu social firmă">
              {/* County and Town */}
              <div className="row">
                <div className="column">
                  <FormSelect
                    key={inputs[16].id}
                    name={inputs[16].name}
                    label={inputs[16].label}
                    value={values[inputs[16].name]}
                    ddOptions={ddOptionsCounty}
                    onChange={onChangeCounty}
                  />
                </div>
                <div className="column">
                  <FormSelect
                    key={inputs[17].id}
                    name={inputs[17].name}
                    label={inputs[17].label}
                    value={values[inputs[17].name]}
                    ddOptions={ddOptionsTown}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Address Details 1 and Address Details 2 */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[18].id} {...inputs[18]} value={values[inputs[18].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[19].id} {...inputs[19]} value={values[inputs[19].name]} onChange={onChange} />
                </div>
              </div>

              {/* Postal Code */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[20].id} {...inputs[20]} value={values[inputs[20].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[21].id} {...inputs[21]} value={values[inputs[21].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare operator RSVTI (sediu social)">
              {/* RSVTI Operator company name and permit number */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[22].id} {...inputs[22]} value={values[inputs[22].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[23].id} {...inputs[23]} value={values[inputs[23].name]} onChange={onChange} />
                </div>
              </div>

              {/* RSVTI Operator company permit issued date and expiration date */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[24].id} {...inputs[24]} value={values[inputs[24].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[25].id} {...inputs[25]} value={values[inputs[25].name]} onChange={onChange} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  Adaugă poză/pdf autorizaţie firmă operator RSVTI:{" "}
                  <input type="file" className="fileAF" onChange={(e) => setFileCompanyA(e.target.files[0])} />
                </div>
                <div className="column">&nbsp;</div>
              </div>

              {/* RSVTI Operator Lastname and firstname */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[26].id} {...inputs[26]} value={values[inputs[26].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[27].id} {...inputs[27]} value={values[inputs[27].name]} onChange={onChange} />
                </div>
              </div>

              {/* RSVTI Operator Permit number */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[28].id} {...inputs[28]} value={values[inputs[28].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* RSVTI Operator Permit issued date and expiration date */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[29].id} {...inputs[29]} value={values[inputs[29].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[30].id} {...inputs[30]} value={values[inputs[30].name]} onChange={onChange} />
                </div>
              </div>

              {/* RSVTI Operator phone and email */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[31].id} {...inputs[31]} value={values[inputs[31].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[32].id} {...inputs[32]} value={values[inputs[32].name]} onChange={onChange} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  Adaugă poză/pdf autorizaţie operator RSVTI:{" "}
                  <input type="file" className="fileAP" onChange={(e) => setFileRsvtiPersonA(e.target.files[0])} />
                </div>
                <div className="column">&nbsp;</div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii înregistrare persoană responsabilă semnare acte ISCIR sediu social">
              {/* RVSTI Board Main Member Last Name and RVSTI Board Main Member First Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[33].id} {...inputs[33]} value={values[inputs[33].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[34].id} {...inputs[34]} value={values[inputs[34].name]} onChange={onChange} />
                </div>
              </div>

              {/* RVSTI Board Main Member Role */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[35].id} {...inputs[35]} value={values[inputs[35].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Company Contact Phone and Company Contact Email */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[36].id} {...inputs[36]} value={values[inputs[36].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[37].id} {...inputs[37]} value={values[inputs[37].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii comisie tehnică sediu social">
              {/* RVSTI Board Second Member Last Name and RVSTI Board Second Member First Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[38].id} {...inputs[38]} value={values[inputs[38].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[39].id} {...inputs[39]} value={values[inputs[39].name]} onChange={onChange} />
                </div>
              </div>

              {/* RVSTI Board Third Member Last Name and RVSTI Board Third Member First Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[40].id} {...inputs[40]} value={values[inputs[40].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[41].id} {...inputs[41]} value={values[inputs[41].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        {/* Submit Buttons */}
        <div className="rowdoublecentered">
          <div className="columnbutton">
            <img
              className="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.CompanySave)}
              alt="Salvează deservent auxiliar"
              title="Salvează deservent auxiliar"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
      <div className="spaceDiv" />
      <Link to={"/company/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyList)} alt="Listă firme" title="Listă firme" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      <div className="spaceDiv" />
    </div>
  );
}
