import React from "react";

import { ICONS } from "../common/globals";

const ProcessFormErrors = (props) => {
  const { validationErrors } = props;

  return (
    <>
      <div className="rowdouble">
        <div className="column">
          <h3 className="labelred">Corectează următoarele erori de validare:</h3>
        </div>
        {validationErrors.map((error, index) => (
          <div className="column">
            <img className="imgdownload" src={require("../static/images/" + ICONS.ValidationError)} alt="Eroare" title="Eroare" />
            &nbsp;&nbsp;
            <a className="imgdownload" href={"#internalLink" + error.id}>
              {error.value}
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProcessFormErrors;
