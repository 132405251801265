import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function IrEquipments() {
    return (
        <div className="IrEquipments">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_015.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Registre instalații de ridicat (model)</div>

                    <div className="pmarg">
                        <div className="card">
                            <Accordion activeIndex={0}>

                                <AccordionTab header="Ascensor">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Otis Gen2 Comfort</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(ascensor persoane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/lift/otisgen2_reg.pdf")}>
                                                            <img alt="registru Otis Gen2 Comfort" className="image-tutorial" src={require("../static/images/200x265_registers/lift/otisgen2_200x265.jpg")} />
                                                            </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Schindler 5500</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(ascensor persoane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/lift/schindler5500_reg.pdf")}>
                                                                    <img alt="registru Schindler 5500" className="image-tutorial" src={require("../static/images/200x265_registers/lift/schindler5500_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Romversis 1500 M</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(ascensor persoane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/lift/romversis1500m_reg.pdf")}>
                                                            <img alt="registru Romversis 1500M" className="image-tutorial" src={require("../static/images/200x265_registers/lift/romversis1500m_200x265.jpg")} />
                                                            </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Kone MiniSpace</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(ascensor persoane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/lift/koneminispace_reg.pdf")}>
                                                            <img alt="registru Kone MiniSpace" className="image-tutorial" src={require("../static/images/200x265_registers/lift/koneminispace_200x265.jpg")} />
                                                            </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>



                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Elevator pentru vehicule">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Twin Busch TW 242 A</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(elevator auto 2 coloane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/autolift/twinbuschtw242a_reg.pdf")}>
                                                                    <img alt="registru Twin Busch TW242A" className="image-tutorial" src={require("../static/images/200x265_registers/autolift/twinbuschtw242a_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Ravaglioli KPE 32</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(elevator auto 2 coloane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/autolift/ravagliolikpe32_reg.pdf")}>
                                                                    <img alt="registru Ravaglioli KPE32" className="image-tutorial" src={require("../static/images/200x265_registers/autolift/ravagliolikpe32_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">ATH 2.40</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(elevator auto 2 coloane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/autolift/ath2.40_reg.pdf")}>
                                                                    <img alt="registru ATH 2.40" className="image-tutorial" src={require("../static/images/200x265_registers/autolift/ath2.40_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Hofmann MTF 3000</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(elevator auto 2 coloane) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/autolift/hofmannmtf3000_reg.pdf")}>
                                                                    <img alt="registru Hofmann MTF3000" className="image-tutorial" src={require("../static/images/200x265_registers/autolift/hofmannmtf3000_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Macara">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Telemac HT 125</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(automacara) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/ubemarht125_reg.pdf")}>
                                                                    <img alt="registru Telemac HT 125" className="image-tutorial" src={require("../static/images/200x265_registers/crane/ubemarht125_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Grove GMK 5130-1</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(automacara) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/grovegmk5130-1_reg.pdf")}>
                                                                    <img alt="registru Grove GMK 5130-1" className="image-tutorial" src={require("../static/images/200x265_registers/crane/grovegmk5130-1_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Demag AC 50-1</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(automacara) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/demagac50-1_reg.pdf")}>
                                                                    <img alt="registru Demag AC 50-1" className="image-tutorial" src={require("../static/images/200x265_registers/crane/demagac50-1_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">PPM ATT 400</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(automacara) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/ppmatt400_reg.pdf")}>
                                                                    <img alt="registru PPM ATT 400" className="image-tutorial" src={require("../static/images/200x265_registers/crane/ppmatt400_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Hiab 175-2</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara de încărcare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/hiab175-2_reg.pdf")}>
                                                                    <img alt="registru Hiab 175-2" className="image-tutorial" src={require("../static/images/200x265_registers/crane/hiab175-2_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Palfinger PK 36002</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara de încărcare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/palfingerpk36002_reg.pdf")}>
                                                                    <img alt="registru Palfinger PK 36002" className="image-tutorial" src={require("../static/images/200x265_registers/crane/palfingerpk36002_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Atlas AK 105.1 A1</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara de încărcare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/atlasak105.1_reg.pdf")}>
                                                                    <img alt="registru Atlas AK 105.1 A1" className="image-tutorial" src={require("../static/images/200x265_registers/crane/atlasak105.1_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Penz 9100 H</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara de încărcare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/penz9100h_reg.pdf")}>
                                                                    <img alt="registru Penz 9100 H" className="image-tutorial" src={require("../static/images/200x265_registers/crane/penz9100h_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Potain GMR 326D</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara autoridicătoare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/potaingmr326d_reg.pdf")}>
                                                                    <img alt="registru Potain GMR 326D" className="image-tutorial" src={require("../static/images/200x265_registers/crane/potaingmr326d_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Comansa HT 31</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara autoridicătoare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/comansaht31_reg.pdf")}>
                                                                    <img alt="registru Comansa HT31" className="image-tutorial" src={require("../static/images/200x265_registers/crane/comansaht31_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Liebherr 35 K</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara autoridicătoare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/liebherr35k_reg.pdf")}>
                                                                    <img alt="registru Liebherr 35K" className="image-tutorial" src={require("../static/images/200x265_registers/crane/liebherr35k_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Comedil CBR 32</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara autoridicătoare) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/comedilcbr32_reg.pdf")}>
                                                                    <img alt="registru Comedil CBR32" className="image-tutorial" src={require("../static/images/200x265_registers/crane/comedilcbr32_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Liebherr 110 EC-B6 Fr.tronic </span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara turn) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/liebherr110ecb6_reg.pdf")}>
                                                                    <img alt="registru Liebherr 110 EC-B6 Fr.tronic" className="image-tutorial" src={require("../static/images/200x265_registers/crane/liebherr110ecb6_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Krøll K 180</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara turn) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/krollk180_reg.pdf")}>
                                                                    <img alt="registru Krøll K180" className="image-tutorial" src={require("../static/images/200x265_registers/crane/krollk180_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Terex CTT 91 - 5 TS12</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara turn) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/terexctt91_reg.pdf")}>
                                                                    <img alt="registru Terex CTT91-5TS12" className="image-tutorial" src={require("../static/images/200x265_registers/crane/terexctt91_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Potain MC 50B</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(macara turn) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/crane/potainmc50b_reg.pdf")}>
                                                                    <img alt="registru Potain MC 50B" className="image-tutorial" src={require("../static/images/200x265_registers/crane/potainmc50b_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Platformă autoridicătoare">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Anton Ruthmahnn TB 220</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(nacelă pe șasiu vehicul) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/platform/ruthmanntb220_reg.pdf")}>
                                                                    <img alt="registru Anton Ruthmahnn TB220" className="image-tutorial" src={require("../static/images/200x265_registers/platform/ruthmanntb220_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">GSR E209PX</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(nacelă pe șasiu vehicul) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/gsre209px_reg.pdf")}>
                                                                    <img alt="registru GSR E209PX" className="image-tutorial" src={require("../static/images/200x265_registers/platform/gsre209px_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Multitel MX 270</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(nacelă pe șasiu vehicul) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/multitelmx270_reg.pdf")}>
                                                                    <img alt="registru Multitel MX270" className="image-tutorial" src={require("../static/images/200x265_registers/platform/multitelmx270_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">France Elévateur  101 TLE</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(nacelă pe șasiu vehicul) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/franceelevateur101tle_reg.pdf")}>
                                                                    <img alt="registru France Elévateur 101TLE" className="image-tutorial" src={require("../static/images/200x265_registers/platform/franceelevateur101tle_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Airo X14 EW</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă deplasabilă tip foarfecă) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/platform/airox14ew_reg.pdf")}>
                                                                    <img alt="registru Airo X14 EW" className="image-tutorial" src={require("../static/images/200x265_registers/platform/airox14ew_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Haulotte H18SXL</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă deplasabilă tip foarfecă) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/haulotteh18sxl_reg.pdf")}>
                                                                    <img alt="registru Haulotte H18SXL" className="image-tutorial" src={require("../static/images/200x265_registers/platform/haulotteh18sxl_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">JCB S4046E</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă deplasabilă tip foarfecă) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/jcbs4046e_reg.pdf")}>
                                                                    <img alt="registru JCB S4046E" className="image-tutorial" src={require("../static/images/200x265_registers/platform/jcbs4046e_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">PB Lifttechnik S151-12E</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă deplasabilă tip foarfecă) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/pbs151-12e_reg.pdf")}>
                                                                    <img alt="registru PB Lifttechnik S151-12E" className="image-tutorial" src={require("../static/images/200x265_registers/platform/pbs151-12e_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">HMK MS 25100/20</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă nedeplasabilă bicoloană) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/platform/hekms25100_reg.pdf")}>
                                                                    <img alt="registru HMK MS 25100/20" className="image-tutorial" src={require("../static/images/200x265_registers/platform/hekms25100_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Electroelsa P40</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă nedeplasabilă bicoloană) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/elsap40_reg.pdf")}>
                                                                    <img alt="registru Electroelsa P40" className="image-tutorial" src={require("../static/images/200x265_registers/platform/elsap40_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Macrel PEC-120</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă nedeplasabilă bicoloană) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/macrelpec120_reg.pdf")}>
                                                                    <img alt="registru Macrel PEC-120" className="image-tutorial" src={require("../static/images/200x265_registers/platform/macrelpec120_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Doruktek DL 700</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(platformă nedeplasabilă bicoloană) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/platform/doruktekdl700_reg.pdf")}>
                                                                    <img alt="registru Doruktek DL 700" className="image-tutorial" src={require("../static/images/200x265_registers/platform/doruktekdl700_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Stivuitor">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Manitou CD 20 P</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu furci frontale) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                                <Link target="_blank" to={require("../static/documents/registers/forklift/manitoucd20p_reg.pdf")}>
                                                                    <img alt="registru Manitou CD20P" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/manitoucd20p_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Jungheinrich EFG 540</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu furci frontale) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/jungheinrichefg540_reg.pdf")}>
                                                                    <img alt="registru Jungheinrich EFG540" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/jungheinrichefg540_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Toyota 02-8FDF25</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu furci frontale) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/toyota02-8fdf25_reg.pdf")}>
                                                                    <img alt="registru Toyota 02-8FDF25" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/toyota02-8fdf25_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Still RCE 25</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu furci frontale) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/stillrce25_reg.pdf")}>
                                                                    <img alt="registru Still RCE25" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/stillrce25_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Liebherr TL 442-13</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu braț telescopic) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/liebherrtl442_reg.pdf")}>
                                                                    <img alt="registru Liebherr TL442-13" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/liebherrtl442_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Manitou MRT 2145</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu braț telescopic) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/manitoumrt2145_reg.pdf")}>
                                                                    <img alt="registru Manitou MRT2145" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/manitoumrt2145_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Haulotte HTL 4017</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu braț telescopic) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/haulottehtl4017_reg.pdf")}>
                                                                    <img alt="registru Haulotte HTL4017" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/haulottehtl4017_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Merlo ROTO 45.21 KSC</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(stivuitor cu braț telescopic) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/forklift/merloroto45.21ksc_reg.pdf")}>
                                                                    <img alt="registru Merlo ROTO 45.21KSC" className="image-tutorial" src={require("../static/images/200x265_registers/forklift/merloroto45.21ksc_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>

                                <AccordionTab header="Transpaletă electrică">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Jungheinrich EJE 116</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(transpaletă electrică pietonală) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/electricpallettruck/jungheinricheje116_reg.pdf")}>
                                                                    <img alt="registru Jungheinrich EJE 116" className="image-tutorial" src={require("../static/images/200x265_registers/electricpallettruck/jungheinricheje116_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Linde T 20</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(transpaletă electrică pietonală) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/electricpallettruck/lindet20_reg.pdf")}>
                                                                    <img alt="registru Linde T 20" className="image-tutorial" src={require("../static/images/200x265_registers/electricpallettruck/lindet20_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">Crown WP 2315</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(transpaletă electrică pietonală) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/electricpallettruck/crownwp2315_reg.pdf")}>
                                                                    <img alt="registru Crown WP 2315" className="image-tutorial" src={require("../static/images/200x265_registers/electricpallettruck/crownwp2315_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip">UniCarriers PLL 180</span>
                                                        </div>
                                                        <div className="txt-ctr">
                                                            <span className="font-register-equip-class">(transpaletă electrică pietonală) </span>
                                                        </div>
                                                        <div>
                                                            <br />
                                                        </div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div className=" align-items-center ">
                                                            <Link target="_blank" to={require("../static/documents/registers/electricpallettruck/unicarrierspll180_reg.pdf")}>
                                                                    <img alt="registru UniCarriers PLL 180" className="image-tutorial" src={require("../static/images/200x265_registers/electricpallettruck/unicarrierspll180_200x265.jpg")} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>


                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>


                </div>




            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
