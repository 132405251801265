

import "primeflex/primeflex.css";

import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function Documents() {
    return (
        <div className="Documents">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_012.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Instalații de ridicat</div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Deservenți</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link to={"/public/iroperators"}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Deservenți auxiliari</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_002.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link to={"/public/irworkers"}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Echipamente</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_003.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link to={"/public/irequipments"}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Instalații sub presiune</div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Deservenți</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_004.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                    <Link to={"/public/ipoperators"}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Deservenți auxiliari</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_005.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/model_adaugare_firma.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full txt-ctr">
                                <div className="text-900 font-medium text-xl mb-2">Echipamente</div>
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_006.jpg")} />
                                    </div>
                                    {/* <br></br> */}
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/model_adaugare_firma.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Documentație</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
