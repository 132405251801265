import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";

import { BASE_URL } from "../../common/globals";
import GenerateButtonWorker from "../../components/GenerateButtonWorker";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsStatus() {
  const sessionStatusURL = BASE_URL + "workerdocuments/get/status";
  const typesURL = BASE_URL + "workerdocuments/get/session/type";

  const uuid = sessionStorage.getItem("workerSessionUuid");

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [types, setTypes] = useState([]);

  useEffect(() => {
    axios
      .post(
        sessionStatusURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfigs(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Configuratia certificarii nu a putut fi citita!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .post(
        typesURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Configuratia certificarii nu a putut fi citita!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="clientArea">
      <Sidebar />

      <button className="buttonClassGreen">Documente generate</button>
      <div className="spaceDiv" />

      <div className="rowdouble">
        <div className="column">
          <p className="pTitle">
          Documente {types.workerType} {types.equipmentClassification} generate.
          </p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div className="rowdouble">
            <div className="column">
              Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
            </div>
          </div>
          <div className="rowdouble">
            <div className="column">
              <GenerateButtonWorker status={config.status} sessionUuid={uuid} companyUuid={config.uuid} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
