import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelectMultiple from "../../components/FormSelectMultiple";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EditWorker() {
  const getWorkerURL = BASE_URL + "worker/get";
  const updateWorkerURL = BASE_URL + "worker/update";
  const countryListURL = BASE_URL + "country/list";
  const countyListURL = BASE_URL + "county/clientlist";
  const townListURL = BASE_URL + "town/clientlistbycounty";
  const genderListURL = BASE_URL + "structure/genders";
  const companyListURL = BASE_URL + "company/listsimplecompaniesandbranches";
  const workerTypesURL = BASE_URL + "structure/worker/types";
  const structureChildrenURL = BASE_URL + "structure/worker/children";
  const uploadWorkerCertificateURL = BASE_URL + "worker/certificate/upload";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    lastName: "",
    firstName: "",
    identifier: "",
    genderUuid: "",
    idCardSerial: "",
    idCardNumber: "",
    idPassport: "",
    idIdentity: "",
    birthDate: "",
    countyUuid: "",
    townUuid: "",
    countryUuid: "",
    externalTown: "",
    phone: "",
    email: "",
    workerTypeUuid: "",
    equipmentClassificationUuid: "",
    equipmentGroupUuid: [],
    equipmentTypeUuid: "",
    certificateNumber: "",
    certificateIssuedDate: "",
    companyUuid: "",
    maxMass: "",
    countyNew: "",
    townNew: "",
  });

  // used to dynamically load the countries drop down
  const [ddOptionsCountry, setDdOptionsCountry] = useState([]);

  // used to dynamically load the counties drop down
  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);

  // used to dynamically load the towns drop down
  const [ddOptionsTown, setDdOptionsTown] = useState([]);

  // used to dynamically load the towns drop down
  const [ddOptionsGender, setDdOptionsGender] = useState([]);

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);

  const [workerTypes, setWorkerTypes] = useState([]);
  const [equipmentClassifications, setEquipmentClassifications] = useState([]);
  const [equipmentGroups, setEquipmentGroups] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  const [fileCertificate, setFileCertificate] = useState(null);

  const inputs = [
    {
      id: 0,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie deservent auxiliar",
      label: "Nume familie deservent auxiliar",
    },
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Prenume deservent auxiliar",
      label: "Prenume deservent auxiliar",
    },
    {
      id: 2,
      name: "identifier",
      type: "text",
      placeholder: "CNP deservent auxiliar",
      label: "CNP deservent auxiliar",
    },
    {
      id: 3,
      name: "genderUuid",
      type: "selectGender",
      placeholder: "Sex deservent auxiliar",
      label: "Sex deservent auxiliar",
    },
    {
      id: 4,
      name: "idCardSerial",
      type: "text",
      placeholder: "Serie CI deservent auxiliar",
      label: "Serie CI deservent auxiliar",
    },
    {
      id: 5,
      name: "idCardNumber",
      type: "text",
      placeholder: "Număr CI deservent auxiliar",
      label: "Număr CI deservent auxiliar",
    },
    {
      id: 6,
      name: "idPassport",
      type: "text",
      placeholder: "Număr pașaport deservent auxiliar (dacă nu are CI)",
      label: "Număr pașaport deservent auxiliar (dacă nu are CI)",
    },
    {
      id: 7,
      name: "idIdentity",
      type: "text",
      placeholder: "Număr act identitate (dacă nu are CI sau pașaport)",
      label: "Număr act identitate (dacă nu are CI sau pașaport)",
    },
    {
      id: 8,
      name: "birthDate",
      type: "date",
      label: "Dată naștere deservent auxiliar",
    },
    {
      id: 9,
      name: "countryUuid",
      type: "selectCountry",
      placeholder: "Țară naștere deservent auxiliar (dacă nu este născut în România)",
      label: "Țară naștere deservent auxiliar (dacă nu este născut în România)",
    },
    {
      id: 10,
      name: "countyUuid",
      type: "selectCounty",
      placeholder: "Județ/sector naștere deservent auxiliar",
      label: "Județ/sector naștere deservent auxiliar",
    },
    {
      id: 11,
      name: "townUuid",
      type: "selectTown",
      placeholder: "Localitate naștere deservent auxiliar",
      label: "Localitate naștere deservent auxiliar",
    },
    {
      id: 12,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon mobil deservent auxiliar",
      label: "Număr telefon mobil deservent auxiliar",
    },
    {
      id: 13,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail deservent auxiliar",
      label: "Adresă e-mail deservent auxiliar",
    },
    {
      id: 14,
      name: "workerTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent auxiliar",
      label: "Tip deservent auxiliar",
    },
    {
      id: 15,
      name: "equipmentClassificationUuid",
      type: "selectClass",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
    },
    {
      id: 16,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament (dacă este cazul)",
      label: "Grupă echipament (dacă este cazul)",
    },
    {
      id: 17,
      name: "equipmentTypeUuid",
      type: "selectClass",
      placeholder: "Tip echipament",
      label: "Tip echipament",
    },
    {
      id: 18,
      name: "certificateNumber",
      type: "text",
      placeholder: "Număr adeverință",
      label: "Număr adeverință",
    },
    {
      id: 19,
      name: "certificateIssuedDate",
      type: "date",
      placeholder: "Dată eliberare adeverință",
      label: "Dată eliberare adeverință",
    },
    {
      id: 20,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
    },
    {
      id: 21,
      name: "maxMass",
      type: "text",
      placeholder: "Sarcină maximă",
      label: "Sarcină maximă",
    },
    {
      id: 22,
      name: "externalTown",
      type: "text",
      placeholder: "Localitate naştere (în afara țării)",
      label: "Localitate naştere (în afara țării)",
    },
    {
      id: 23,
      name: "countyNew",
      type: "text",
      placeholder: "Județ/sector nume vechi",
      label: "Județ/sector nume vechi",
    },
    {
      id: 24,
      name: "townNew",
      type: "text",
      placeholder: "Localitate nume vechi",
      label: "Localitate nume vechi",
    },
  ];

  // load worker data
  useEffect(() => {
    axios
      .post(
        getWorkerURL,
        { uuid: sessionStorage.getItem("editWorkerUuid") },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("cri");
        console.log(response);
        console.log(response.data);
        let data = [];
        data = { ...data, lastName: response.data.lastName !== null ? response.data.lastName : "" };
        data = { ...data, firstName: response.data.firstName !== null ? response.data.firstName : "" };
        data = { ...data, identifier: response.data.identifier !== null ? response.data.identifier : "" };
        data = { ...data, idCardSerial: response.data.idCardSerial !== null ? response.data.idCardSerial : "" };
        data = { ...data, idCardNumber: response.data.idCardNumber !== null ? response.data.idCardNumber : "" };
        data = { ...data, idPassport: response.data.idPassport !== null ? response.data.idPassport : "" };
        data = { ...data, idIdentity: response.data.idIdentity !== null ? response.data.idIdentity : "" };
        data = { ...data, birthDate: response.data.birthDate !== null ? response.data.birthDate : "" };
        data = { ...data, externalTown: response.data.externalTown !== null ? response.data.externalTown : "" };
        data = { ...data, phone: response.data.phone !== null ? response.data.phone : "" };
        data = { ...data, email: response.data.email !== null ? response.data.email : "" };
        data = { ...data, certificateNumber: response.data.certificateNumber !== null ? response.data.certificateNumber : "" };
        data = { ...data, certificateIssuedDate: response.data.certificateIssuedDate !== null ? response.data.certificateIssuedDate : "" };
        data = { ...data, maxMass: response.data.maxMass !== null ? response.data.maxMass : "" };
        console.log("data");
        console.log(data);
        setValues({ ...values, ...data });
      })
      .catch((error) => {
        console.log("Error loading countries");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load countries
  useEffect(() => {
    axios
      .get(countryListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCountry(response.data);
      })
      .catch((error) => {
        console.log("Error loading countries");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ddOptionsCountry.length > 0) {
      setValues({ ...values, countryUuid: "9398f013-d0c8-11ed-b3c8-902b346c1788" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddOptionsCountry]);

  // load counties
  useEffect(() => {
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        console.log("Error loading counties");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCounty = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "", countyNew: "" });
    setDdOptionsTown([]);
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Towns loaded");
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        console.log("Error loading towns");
        console.log(error);
        alert("Orasele nu au putut fi citite");
      });
  };

  const onChangeTown = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, townNew: "" });
  };

  const onChangeCountyNew = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, countyUuid: "" });
    setDdOptionsTown([]);
  };

  const onChangeTownNew = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
  };

  // load genders
  useEffect(() => {
    axios
      .post(
        genderListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsGender(response.data);
      })
      .catch((error) => {
        console.log("Error loading genders");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .post(
        workerTypesURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Worker types loaded");
        setWorkerTypes(response.data);
      })
      .catch((error) => {
        console.log("Error loading worker types");
        console.log(error);
        alert("Tipurile de deserventi auxiliari nu au putut fi citite");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        updateWorkerURL,
        {
          companyUuid: values.companyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          identifier: values.identifier,
          genderUuid: values.genderUuid,
          idCardSerial: values.idCardSerial,
          idCardNumber: values.idCardNumber,
          idPassport: values.idPassport,
          idIdentity: values.idIdentity,
          birthDate: values.birthDate,
          countyUuid: values.countyUuid,
          townUuid: values.townUuid,
          countryUuid: values.countryUuid,
          externalTown: values.externalTown,
          phone: values.phone,
          email: values.email,
          workerTypeUuid: values.workerTypeUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          equipmentTypeUuid: values.equipmentTypeUuid,
          certificateNumber: values.certificateNumber,
          certificateIssuedDate: values.certificateIssuedDate,
          maxMass: values.maxMass,
          countyNew: values.countyNew,
          townNew: values.townNew,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        console.log("Worker added");
        console.log(res.data);
        alert("Deservent auxiliar adaugat");
        uploadCertificate(res.data);
        let file = document.querySelector(".fileC");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        console.log("Error adding worker");
        console.log(error);
        alert("Deserventul auxiliar nu a putut fi adaugat");
      });
    // console.log("Submit");
  };

  const uploadCertificate = (workerCertificateUuid) => {
    if (fileCertificate === null) {
      return;
    }
    const formData = new FormData();
    formData.append("workerCertificateUuid", workerCertificateUuid);
    formData.append("fileCertificate", fileCertificate);
    axios
      .post(uploadWorkerCertificateURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        console.log("Certificate uploaded");
        console.log(res.data);
        alert("Certificat salvat");
      })
      .catch((error) => {
        console.log("Error uploading certificate");
        console.log(error);
        alert("Autorizatia nu a putut fi salvata");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeWorkerType = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassificationUuid: "", equipmentGroupUuid: [], equipmentTypeUuid: "" });
    setEquipmentClassifications([]);
    setEquipmentGroups([]);
    setEquipmentTypes([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Worker types loaded");
        setEquipmentClassifications(response.data.values);
      })
      .catch((error) => {
        console.log("Error loading worker types");
        console.log(error);
        alert("Tipurile de deserventi auxiliari nu au putut fi citite");
      });
  };

  const onChangeEquipmentClassification = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: [], equipmentTypeUuid: "" });
    setEquipmentGroups([]);
    setEquipmentTypes([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Worker types loaded");
        if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        } else {
          setEquipmentTypes(response.data.values);
        }
      })
      .catch((error) => {
        console.log("Error loading worker types");
        console.log(error);
        alert("Tipurile de deserventi auxiliari nu au putut fi citite");
      });
  };

  const onChangeEquipmentGroup = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeEquipmentType = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button className="buttonClassGreen">Modifică deservent auxiliar</button>
      <div className="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      <form className="formArea">
        <div className="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii date personale deservent auxiliar">
              {/* Last Name and First Name */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <FormSelect
                    key={inputs[20].id}
                    name={inputs[20].name}
                    label={inputs[20].label}
                    value={values[inputs[20].name]}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* CNP and Gender */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[2].id} {...inputs[2]} value={values[inputs[2].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormSelect
                    key={inputs[3].id}
                    name={inputs[3].name}
                    label={inputs[3].label}
                    value={values[inputs[3].name]}
                    ddOptions={ddOptionsGender}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* ID Card Serial and ID Card Number */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[5].id} {...inputs[5]} value={values[inputs[5].name]} onChange={onChange} />
                </div>
              </div>

              {/* ID Passport and ID Other Identifier */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[6].id} {...inputs[6]} value={values[inputs[6].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[7].id} {...inputs[7]} value={values[inputs[7].name]} onChange={onChange} />
                </div>
              </div>

              {/* Date of birth */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[8].id} {...inputs[8]} value={values[inputs[8].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* County of birth and Town of birth */}
              <div className="row">
                <div className="column">
                  <FormSelect
                    key={inputs[10].id}
                    name={inputs[10].name}
                    label={inputs[10].label}
                    value={values[inputs[10].name]}
                    ddOptions={ddOptionsCounty}
                    onChange={onChangeCounty}
                  />
                </div>
                <div className="column">
                  <FormSelect
                    key={inputs[11].id}
                    name={inputs[11].name}
                    label={inputs[11].label}
                    value={values[inputs[11].name]}
                    ddOptions={ddOptionsTown}
                    onChange={onChangeTown}
                  />
                </div>
              </div>

              {/* County old and Town old */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[23].id} {...inputs[23]} value={values[inputs[23].name]} onChange={onChangeCountyNew} />
                </div>
                <div className="column">
                  <FormInput key={inputs[24].id} {...inputs[24]} value={values[inputs[24].name]} onChange={onChangeTownNew} />
                </div>
              </div>

              {/* Country of birth and External city - if out of Romania */}
              <div className="row">
                <div className="column">
                  <FormSelect
                    key={inputs[9].id}
                    name={inputs[9].name}
                    label={inputs[9].label}
                    value={values[inputs[9].name]}
                    ddOptions={ddOptionsCountry}
                    onChange={onChange}
                  />
                </div>
                <div className="column">
                  <FormInput key={inputs[22].id} {...inputs[22]} value={values[inputs[22].name]} onChange={onChange} />
                </div>
              </div>

              {/* Phone and Email */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[12].id} {...inputs[12]} value={values[inputs[12].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[13].id} {...inputs[13]} value={values[inputs[13].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii tip autorizare deservent auxiliar">
              {/* Worker Class */}
              <div className="row">
                <div className="column">
                  <FormSelect
                    key={inputs[14].id}
                    name={inputs[14].name}
                    label={inputs[14].label}
                    value={values[inputs[14].name]}
                    ddOptions={workerTypes}
                    onChange={onChangeWorkerType}
                  />
                </div>
                <div className="column">
                  <FormSelect
                    key={inputs[15].id}
                    name={inputs[15].name}
                    label={inputs[15].label}
                    value={values[inputs[15].name]}
                    ddOptions={equipmentClassifications}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
              </div>

              {/* Equipment Type and Equipment Group */}
              <div className="row">
                <div className="column">
                  <FormSelectMultiple
                    key={inputs[16].id}
                    name={inputs[16].name}
                    label={inputs[16].label}
                    value={values[inputs[16].name]}
                    ddOptions={equipmentGroups}
                    onChange={onChangeEquipmentGroup}
                  />
                </div>
                <div className="column">
                  {values.workerTypeUuid !== "28b087a4-5a10-11ee-8699-902b346c1788" ? (
                    <FormSelect
                      key={inputs[17].id}
                      name={inputs[17].name}
                      label={inputs[17].label}
                      value={values[inputs[17].name]}
                      ddOptions={equipmentTypes}
                      onChange={onChangeEquipmentType}
                    />
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </div>
              </div>

              {/* Max mass */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[21].id} {...inputs[21]} value={values[inputs[21].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Certificate Number and Certificate Issued Date */}
              <div className="row">
                <div className="column">
                  <FormInput key={inputs[18].id} {...inputs[18]} value={values[inputs[18].name]} onChange={onChange} />
                </div>
                <div className="column">
                  <FormInput key={inputs[19].id} {...inputs[19]} value={values[inputs[19].name]} onChange={onChange} />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  Adaugă poză/pdf adeverinţă deservent auxiliar:{" "}
                  <input type="file" className="fileC" onChange={(e) => setFileCertificate(e.target.files[0])} />
                </div>
                <div className="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        {/* Submit Buttons */}
        <div className="rowdoublecentered">
          <div className="columnbutton">
            <img
              className="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerSave)}
              alt="Salvează deservent auxiliar"
              title="Salvează deservent auxiliar"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
      <div className="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      <div className="spaceDiv" />
    </div>
  );
}
