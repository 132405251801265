import "../../static/css/pageLayout.css";

import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateNumber, validateString, validateMinMaxText, validateMinMaxTextForNumber } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EquipmentGenerateDocsOrder() {
  const instructionsConfigURL = BASE_URL + "equipmentdocuments/get/list/configs";
  const instructionsConfigSaveURL = BASE_URL + "equipmentdocuments/generate/order";

  const uuid = sessionStorage.getItem("equipmentSessionUuid");

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [values, setValues] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  useEffect(() => {
    axios
      .post(
        instructionsConfigURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, ["urgent" + i]: "" };
          data = { ...data, ["rsvtiPerson" + i]: "" };
          data = { ...data, ["contactPerson" + i]: "" };
          data = { ...data, ["orderSeries" + i]: "" };
          data = { ...data, ["orderNumber" + i]: "" };
          data = { ...data, ["orderIssuedDate" + i]: "" };
          data = { ...data, ["inspectionDateLifting" + i]: "" };
          data = { ...data, ["forInspectorLifting" + i]: "" };
          data = { ...data, ["inspectionDatePressure" + i]: "" };
          data = { ...data, ["forInspectorPressure" + i]: "" };
        }
        setValues({ ...values, ...data });
        setConfigs(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].rsvtiPersons.length === 1) {
        data = { ...data, ["rsvtiPerson" + i]: configs[i].rsvtiPersons[0].uuid };
      }
      if (configs[i].contactPersons.length === 1) {
        data = { ...data, ["contactPerson" + i]: configs[i].contactPersons[0].uuid };
      }
    }
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      let newfield = {
        uuid: configs[i].uuid,
        urgent: values["urgent" + i],
        iscirOwnerUuid: values["rsvtiPerson" + i],
        contactUuid: values["contactPerson" + i],
        orderSeries: values["orderSeries" + i],
        orderNumber: values["orderNumber" + i],
        orderIssuedDate: values["orderIssuedDate" + i],
        inspectionDateLifting: values["inspectionDateLifting" + i],
        forInspectorLifting: values["forInspectorLifting" + i],
        inspectionDatePressure: values["inspectionDatePressure" + i],
        forInspectorPressure: values["forInspectorPressure" + i],
      };
      data = [...data, newfield];
    }
    axios
      .post(
        instructionsConfigSaveURL,
        {
          uuid: uuid,
          configs: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/equipmentdocuments/generatedocsview");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (!validateNumber(values["orderNumber" + i], FIELD_SIZE.Zero, FIELD_SIZE.PVMax)) {
        data.push({
          id: i * 10 + 1,
          value: "Număr comandă (" + configs[i].code + ") " + validateMinMaxTextForNumber(FIELD_SIZE.Zero, FIELD_SIZE.PVMax),
        });
        result = false;
      }
      if (isEmptyString(values["orderIssuedDate" + i])) {
        data.push({
          id: i * 10 + 2,
          value: "Dată emitere comandă (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
      if (!validateString(values["inspectionDateLifting" + i], FIELD_SIZE.Zero, FIELD_SIZE.InspectionDateMax)) {
        data.push({
          id: i * 10 + 3,
          value:
            "Data inspecţie (instalaţii de ridicat) (" + configs[i].code + ") " + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.InspectionDateMax),
        });
        result = false;
      }
      if (!validateString(values["forInspectorLifting" + i], FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
        data.push({
          id: i * 10 + 4,
          value:
            "În atenţia: Inspector .................... (instalaţii de ridicat) (" +
            configs[i].code +
            ") " +
            validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
        });
        result = false;
      }
      if (!validateString(values["inspectionDatePressure" + i], FIELD_SIZE.Zero, FIELD_SIZE.InspectionDateMax)) {
        data.push({
          id: i * 10 + 5,
          value:
            "Data inspecţie (instalaţii sub presiune) (" + configs[i].code + ") " + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.InspectionDateMax),
        });
        result = false;
      }
      if (!validateString(values["forInspectorPressure" + i], FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax)) {
        data.push({
          id: i * 10 + 6,
          value:
            "În atenţia: Inspector .................... (instalaţii sub presiune) (" +
            configs[i].code +
            ") " +
            validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.LastNameMax),
        });
        result = false;
      }
      if (isEmptyString(values["rsvtiPerson" + i])) {
        data.push({
          id: i * 10 + 7,
          value: "Operator RSVTI (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
      if (isEmptyString(values["contactPerson" + i])) {
        data.push({
          id: i * 10 + 8,
          value: "Responsabil punct de lucru (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <div className="rowdouble">
        <div className="column">
          <p className="pTitle">Listă evidență instalații ISCIR</p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 131313}>
          <div className="rowdouble">
            <div className="column">
              <p className="pTitle">
                Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
              </p>
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + index * 10}>
              <Checkbox
                key={index * 10}
                inputId={"urgent" + index}
                name={"urgent" + index}
                value="urgent"
                onChange={onChangeCheckBox}
                checked={values["urgent" + index] === "urgent"}
              />
              <label htmlFor={"urgent" + index} className="ml-2">
                &nbsp;&nbsp;Urgenţă
              </label>
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 10 + 1)}>
              <FormInput
                key={index * 10 + 1}
                type="text"
                name={"orderNumber" + index}
                label="Număr comandă"
                value={values["orderNumber" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 10 + 2)}>
              <FormInput
                key={index * 10 + 2}
                type="date"
                name={"orderIssuedDate" + index}
                label="Dată emitere comandă"
                value={values["orderIssuedDate" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 10 + 3)}>
              <FormInput
                key={index * 10 + 3}
                type="text"
                name={"inspectionDateLifting" + index}
                label="Alege data inspecţie (instalaţii de ridicat)"
                value={values["inspectionDateLifting" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 10 + 4)}>
              <FormInput
                key={index * 10 + 4}
                type="text"
                name={"forInspectorLifting" + index}
                label="În atenţia: Inspector .................... (instalaţii de ridicat)"
                value={values["forInspectorLifting" + index]}
                onChange={onChange}
              />
            </div>
            <div className="rowdouble">
              <div className="column" id={"internalLink" + (index * 10 + 5)}>
                <FormInput
                  key={index * 10 + 5}
                  type="text"
                  name={"inspectionDatePressure" + index}
                  label="Alege data inspecţie (instalaţii sub presiune)"
                  value={values["inspectionDatePressure" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="rowdouble">
              <div className="column" id={"internalLink" + (index * 10 + 6)}>
                <FormInput
                  key={index * 10 + 6}
                  type="text"
                  name={"forInspectorPressure" + index}
                  label="În atenţia: Inspector .................... (instalaţii sub presiune)"
                  value={values["forInspectorPressure" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="rowdouble">
              <div className="column" id={"internalLink" + (index * 10 + 7)}>
                <FormSelect
                  key={index * 10 + 7}
                  name={"rsvtiPerson" + index}
                  label="Operator RSVTI"
                  value={values["rsvtiPerson" + index]}
                  ddOptions={config.rsvtiPersons}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="rowdouble">
              <div className="column" id={"internalLink" + (index * 10 + 8)}>
                <FormSelect
                  key={index * 10 + 8}
                  name={"contactPerson" + index}
                  label="Responsabil punct de lucru"
                  value={values["contactPerson" + index]}
                  ddOptions={config.contactPersons}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div className="rowdouble">
          <div className="column">
            <button className="longButtonClassGray" onClick={handleGenerate}>
              Generare comandă CNCIR
            </button>
          </div>
        </div>
      ) : null}
      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
