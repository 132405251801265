import { useNavigate } from "react-router-dom";

import Sidebar from "../components/Sidebar";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goToStats = () => navigate("/client/statistics");

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">NEAUTORIZAT</button>
      <div className="spaceDiv" />
      <strong className="labelred">NU AI DREPTUL SĂ ACCESEZI ACEASTĂ PAGINĂ!</strong>
      <div className="spaceDiv" />
      <button className="longButtonClassGrayNoPadding" onClick={goToStats}>
        Mergi la pagina de sumar!
      </button>
    </div>
  );
};

export default Unauthorized;
