// prime core css
import "primereact/resources/primereact.min.css";
// prime theme css
import "primereact/resources/themes/nova/theme.css";
// custom css
import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function IconsLegend() {
  return (
    <div className="clientArea">
      <Sidebar />
      <Link to={"/help/allowedclassifications"}>
        <button className="buttonClassGray">Clasificări permise</button>
      </Link>
      <Link to={"/help/importtemplates"}>
        <button className="buttonClassGray">Definiţii fişiere import</button>
      </Link>
      <button className="buttonClassGreen">Legendă iconiţe</button>
      <div className="spaceDiv" />
      <div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/company_main.png")}
              alt="Iconiţă companie"
              title="Iconiţă companie"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică firmelor.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/company_branch.png")}
              alt="Iconiţă punct de lucru"
              title="Iconiţă punct de lucru"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică punctelor de lucru.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/operator.png")}
              alt="Iconiţă deservent"
              title="Iconiţă deservent"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică deservenţilor.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/worker.png")}
              alt="Iconiţă deservent auxiliar"
              title="Iconiţă deservent auxiliar"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică deservenţilor auxiliari.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/equipment_lifting.png")}
              alt="Iconiţă echipament de ridicat"
              title="Iconiţă echipament de ridicat"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică echipamentelor de ridicat.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/equipment_lifting_chain.png")}
              alt="Iconiţă chingi"
              title="Iconiţă chingi"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică chingilor.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/equipment_pressure.png")}
              alt="Iconiţă echipament sub presiune"
              title="Iconiţă echipament sub presiune"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică echipamentelor sub presiune.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/equipment_pressure_safety_valve.png")}
              alt="Iconiţă supapă de siguranţă"
              title="Iconiţă supapă de siguranţă"
            />
            &nbsp;Acţiunile care includ această iconiţă se aplică supapelor de siguranţă.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img className="imgdownload" src={require("../../static/images/buttons_templates/list.png")} alt="Iconiţă listă" title="Iconiţă listă" />
            &nbsp;Iconiţă pentru listarea informaţiilor din aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img className="imgdownload" src={require("../../static/images/buttons_templates/add.png")} alt="Iconiţă adăugare" title="Iconiţă adăugare" />
            &nbsp;Iconiţă pentru adăugare informaţii în aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img className="imgdownload" src={require("../../static/images/buttons_templates/save.png")} alt="Iconiţă salvare" title="Iconiţă salvare" />
            &nbsp;Iconiţă pentru salvare informaţii în aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/delete.png")}
              alt="Iconiţă ştergere"
              title="Iconiţă ştergere"
            />
            &nbsp;Iconiţă pentru ştergere informaţii din aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img className="imgdownload" src={require("../../static/images/buttons_templates/upload.png")} alt="Iconiţă import" title="Iconiţă import" />
            &nbsp;Iconiţă pentru import informaţii în aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/save_all.png")}
              alt="Iconiţă importă informaţii"
              title="Iconiţă importă informaţii"
            />
            &nbsp;Iconiţă pentru salvare informaţii importate în aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons_templates/download.png")}
              alt="Iconiţă descarcă document"
              title="Iconiţă descarcă document"
            />
            &nbsp;Iconiţă pentru descărcat documente din aplicaţie.
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img className="imgdownload" src={require("../../static/images/buttons_templates/about.png")} alt="Iconiţă detalii" title="Iconiţă detalii" />
            &nbsp;Iconiţă pentru afişare detaliile unei informaţii din aplicaţie.
          </div>
        </div>
      </div>
    </div>
  );
}
