import "../../static/css/pageLayout.css";

import axios from "axios";
import { RadioButton } from "primereact/radiobutton";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, STRING_CONST, dialogFooterContent, ICONS } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function OperatorGenerateDocsCertificates() {
  const generateCertificatesURL = BASE_URL + "operatordocuments/generate/certificates";
  const operatorsURL = BASE_URL + "operatordocuments/get/operators";

  const uuid = sessionStorage.getItem("operatorSessionUuid");
  const companyUuid = sessionStorage.getItem("companyUuid");

  const [operators, setOperators] = useState([]);
  const [results, setResults] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [values, setValues] = useState({
    pvResultNumber: "",
    pvResultIssuedDate: "",
    pvAuthoriseNumber: "",
    pvAuthoriseIssuedDate: "",
  });

  const inputs = [
    {
      id: 0,
      name: "pvResultNumber",
      type: "text",
      placeholder: "Alege număr PV rezultate testare",
      label: "Alege număr PV rezultate testare",
    },
    {
      id: 1,
      name: "pvResultIssuedDate",
      type: "date",
      placeholder: "Alege dată emitere PV rezultate testare",
      label: "Alege dată emitere PV rezultate testare",
    },
    {
      id: 2,
      name: "pvAuthoriseNumber",
      type: "text",
      placeholder: "Alege număr PV reautorizare",
      label: "Alege număr PV reautorizare",
    },
    {
      id: 3,
      name: "pvAuthoriseIssuedDate",
      type: "date",
      placeholder: "Alege dată emitere PV reautorizare",
      label: "Alege dată emitere PV reautorizare",
    },
  ];

  const FIELD = {
    pvResultNumber: 0,
    pvResultIssuedDate: 1,
    pvAuthoriseNumber: 2,
    pvAuthoriseIssuedDate: 3,
  };

  useEffect(() => {
    axios
      .post(
        operatorsURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, [response.data[i].uuid + "result"]: "Admis" };
        }
        setResults({ ...results, ...data });
        setOperators(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeRadio = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    let data = [];
    for (let i = 0; i < operators.length; i++) {
      let newfield = { uuid: operators[i].uuid, result: results[operators[i].uuid + "result"] };
      data = [...data, newfield];
    }
    axios
      .post(
        generateCertificatesURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
          pvResultNumber: values.pvResultNumber,
          pvResultIssuedDate: values.pvResultIssuedDate,
          pvAuthoriseNumber: values.pvAuthoriseNumber,
          pvAuthoriseIssuedDate: values.pvAuthoriseIssuedDate,
          results: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/operator/generatedocsstatus");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (!validateString(values.pvResultNumber, FIELD_SIZE.Zero, FIELD_SIZE.PVMax)) {
      data.push({
        id: inputs[FIELD.pvResultNumber].id,
        value: inputs[FIELD.pvResultNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PVMax),
      });
      result = false;
    }
    if (isEmptyString(values.pvResultIssuedDate)) {
      data.push({
        id: inputs[FIELD.pvResultIssuedDate].id,
        value: inputs[FIELD.pvResultIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    if (!validateString(values.pvAuthoriseNumber, FIELD_SIZE.Zero, FIELD_SIZE.PVMax)) {
      data.push({
        id: inputs[FIELD.pvAuthoriseNumber].id,
        value: inputs[FIELD.pvAuthoriseNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PVMax),
      });
      result = false;
    }
    if (isEmptyString(values.pvAuthoriseIssuedDate)) {
      data.push({
        id: inputs[FIELD.pvAuthoriseIssuedDate].id,
        value: inputs[FIELD.pvAuthoriseIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS,
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/operator/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button className="buttonClassGreen">Generare documente reautorizare</button>
      <div className="spaceDiv" />

      <div className="rowdouble">
        <div className="column">
          <table className="tableReport">
            <tbody>
              {operators.map((operator, index) => (
                <tr>
                  <td className="columnReport">{operator.lastName}</td>
                  <td className="columnReport">{operator.firstName}</td>
                  <td className="columnReport">{operator.identifier}</td>
                  <td className="columnReport">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId={operator.uuid + "A"}
                        name={operator.uuid + "result"}
                        value="Admis"
                        onChange={onChangeRadio}
                        checked={results[operator.uuid + "result"] === "Admis"}
                      />
                      <label htmlFor={operator.uuid + "A"} className="flex align-items-center">
                        Admis
                      </label>
                    </div>
                  </td>
                  <td className="columnReport">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId={operator.uuid + "R"}
                        name={operator.uuid + "result"}
                        value="Respins"
                        onChange={onChangeRadio}
                        checked={results[operator.uuid + "result"] === "Respins"}
                      />
                      <label htmlFor={operator.uuid + "R"} className="flex align-items-center">
                        Respins
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="rowdouble">
        <div className="column">
          <p className="pTitle">Proces verbal rezultate testare deservenți</p>
        </div>
      </div>

      <div className="rowdouble">
        <div className="column" id={"internalLink" + inputs[FIELD.pvResultNumber].id}>
          <FormInput
            key={inputs[FIELD.pvResultNumber].id}
            {...inputs[FIELD.pvResultNumber]}
            value={values[inputs[FIELD.pvResultNumber].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="rowdouble">
        <div className="column" id={"internalLink" + inputs[FIELD.pvResultIssuedDate].id}>
          <FormInput
            key={inputs[FIELD.pvResultIssuedDate].id}
            {...inputs[FIELD.pvResultIssuedDate]}
            value={values[inputs[FIELD.pvResultIssuedDate].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="rowdouble">
        <div className="column">
          <p className="pTitle">Proces verbal reautorizare deservenți</p>
        </div>
      </div>

      <div className="rowdouble">
        <div className="column" id={"internalLink" + inputs[FIELD.pvAuthoriseNumber].id}>
          <FormInput
            key={inputs[FIELD.pvAuthoriseNumber].id}
            {...inputs[FIELD.pvAuthoriseNumber]}
            value={values[inputs[FIELD.pvAuthoriseNumber].name]}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="rowdouble">
        <div className="column" id={"internalLink" + inputs[FIELD.pvAuthoriseIssuedDate].id}>
          <FormInput
            key={inputs[FIELD.pvAuthoriseIssuedDate].id}
            {...inputs[FIELD.pvAuthoriseIssuedDate]}
            value={values[inputs[FIELD.pvAuthoriseIssuedDate].name]}
            onChange={onChange}
          />
        </div>
      </div>
      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div className="rowdoublecentered">
          <div className="columnbutton">
            <img
              className="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.OperatorGenerateWordDocuments)}
              alt="Generare documente reautorizare deservenți"
              title="Generare documente reautorizare deservenți"
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}
      <div className="rowdoublecentered">
        <div className="columnbutton">
          <img
            className="imgtoolbar"
            src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
            alt="Vezi documentele generate deja"
            title="Vezi documentele generate deja"
            onClick={(e) => handleViewDocuments(uuid, e)}
          />
        </div>
      </div>

      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
