import "../../static/css/pageLayout.css";

import axios from "axios";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isDuplicateEntry, isEmptyString, isValidResponse, validateString, validateMinMaxText } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelectMultiple from "../../components/FormSelectMultiple";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AddOperator() {
  const addOperatorURL = BASE_URL + "operator/add";
  const addOperatorConfigURL = BASE_URL + "operator/config/add";
  const uploadRegistrationURL = BASE_URL + "operator/registration/upload";
  const uploadRegistrationAndPermitURL = BASE_URL + "operator/registrationpermit/upload";
  const uploadPermitURL = BASE_URL + "operator/permit/upload";

  const [classification, setClassification] = useState([]);
  const [liftingPrescriptions, setLiftingPrescriptions] = useState([]);
  const [pressurePrescriptions, setPressurePrescriptions] = useState([]);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [ddOptionsPrescriptions, setDdOptionsPrescriptions] = useState([]);
  const [ddOptionsOperatorTypes, setDdOptionsOperatorTypes] = useState([]);
  const [ddOptionsEquipmentClassifications, setDdOptionsEquipmentClassifications] = useState([]);
  const [ddOptionsEquipmentClasses, setDdOptionsEquipmentClasses] = useState([]);
  const [ddOptionsEquipmentGroups, setDdOptionsEquipmentGroups] = useState([]);
  const [fileRegistration, setFileRegistration] = useState(null);
  const [filePermit, setFilePermit] = useState(null);
  const [showConfigNotLoadedDialog, setShowConfigNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const { auth } = useAuth();

  const equipmentTypes = [
    {
      uuid: 1,
      value: "Instalaţii de ridicat",
    },
    {
      uuid: 2,
      value: "Instalaţii sub presiune",
    },
  ];

  const [values, setValues] = useState({
    lastName: "",
    firstName: "",
    identifier: "",
    phone: "",
    email: "",
    operatorTypeUuid: "",
    equipmentClassificationUuid: "",
    equipmentClassUuid: "",
    equipmentGroupUuid: [],
    registrationNumber: "",
    registrationIssuedDate: "",
    permitNumber: "",
    permitIssuedDate: "",
    permitVisaDate: "",
    companyUuid: "",
    equipmentType: "",
    prescriptionUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie deservent",
      label: "Nume familie deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Prenume deservent",
      label: "Prenume deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 2,
      name: "identifier",
      type: "text",
      placeholder: "CNP deservent",
      label: "CNP deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon mobil deservent",
      label: "Număr telefon mobil deservent",
    },
    {
      id: 4,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail deservent",
      label: "Adresă e-mail deservent",
    },
    {
      id: 5,
      name: "operatorTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent",
      label: "Tip deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 6,
      name: "equipmentClassificationUuid",
      type: "selectClass",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 7,
      name: "equipmentClassUuid",
      type: "selectClass",
      placeholder: "Clasă echipament (dacă este cazul)",
      label: "Clasă echipament (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 8,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament (dacă este cazul)",
      label: "Grupă echipament (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 9,
      name: "registrationNumber",
      type: "text",
      placeholder: "Număr autorizație",
      label: "Număr autorizație",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 10,
      name: "registrationIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizație",
      label: "Dată eliberare autorizație",
    },
    {
      id: 11,
      name: "permitNumber",
      type: "text",
      placeholder: "Număr talon",
      label: "Număr talon",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 12,
      name: "permitIssuedDate",
      type: "date",
      placeholder: "Dată eliberare talon",
      label: "Dată eliberare talon",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 13,
      name: "permitVisaDate",
      type: "date",
      placeholder: "Dată ultima viză valabilă",
      label: "Dată ultima viză valabilă",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 14,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 15,
      name: "equipmentType",
      type: "selectClass",
      placeholder: "Tip prescripţie",
      label: "Tip prescripţie",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 16,
      name: "prescriptionUuid",
      type: "selectClass",
      placeholder: "Prescripţie",
      label: "Prescripţie",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
  ];

  const FIELD = {
    lastName: 0,
    firstName: 1,
    identifier: 2,
    phone: 3,
    email: 4,
    operatorTypeUuid: 5,
    equipmentClassificationUuid: 6,
    equipmentClassUuid: 7,
    equipmentGroupUuid: 8,
    registrationNumber: 9,
    registrationIssuedDate: 10,
    permitNumber: 11,
    permitIssuedDate: 12,
    permitVisaDate: 13,
    companyUuid: 14,
    equipmentType: 15,
    prescriptionUuid: 16,
  };

  // load config
  useEffect(() => {
    axios
      .post(
        addOperatorConfigURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (isValidResponse(response)) {
          setDdOptionsCompany(response.data.branches);
          setLiftingPrescriptions(response.data.liftingPrescriptions);
          setPressurePrescriptions(response.data.pressurePrescriptions);
          setClassification(response.data.classification);
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeEquipmentType = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      prescriptionUuid: "",
      operatorTypeUuid: "",
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setDdOptionsPrescriptions([]);
    setDdOptionsOperatorTypes([]);
    setDdOptionsEquipmentClassifications([]);
    setDdOptionsEquipmentClasses([]);
    setDdOptionsEquipmentGroups([]);
    if (1 === e.target.value) {
      setDdOptionsPrescriptions(liftingPrescriptions);
    } else {
      setDdOptionsPrescriptions(pressurePrescriptions);
    }
  };

  const onChangePrescription = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      operatorTypeUuid: "",
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setDdOptionsOperatorTypes([]);
    setDdOptionsEquipmentClassifications([]);
    setDdOptionsEquipmentClasses([]);
    setDdOptionsEquipmentGroups([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsOperatorTypes(listItem.values);
        break;
      }
    }
  };

  const onChangeOperatorType = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setDdOptionsEquipmentClassifications([]);
    setDdOptionsEquipmentClasses([]);
    setDdOptionsEquipmentGroups([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsEquipmentClassifications(listItem.values);
        break;
      }
    }
  };

  const onChangeEquipmentClassification = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassUuid: "", equipmentGroupUuid: [] });
    setDdOptionsEquipmentClasses([]);
    setDdOptionsEquipmentGroups([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        if ("451617f2-57e1-11ee-b39a-902b346c1788" === listItem.typeUuid) {
          setDdOptionsEquipmentGroups(listItem.values);
          break;
        } else {
          setDdOptionsEquipmentClasses(listItem.values);
          break;
        }
      }
    }
  };

  const onChangeEquipmentClass = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: [] });
    setDdOptionsEquipmentGroups([]);
    for (const listItem of classification) {
      if (e.target.value === listItem.parentUuid) {
        setDdOptionsEquipmentGroups(listItem.values);
        break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowProcessingDialog(true);
    if (!validateForm()) {
      setShowProcessingDialog(false);
      return;
    }
    axios
      .post(
        addOperatorURL,
        {
          companyUuid: values.companyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          identifier: values.identifier,
          phone: values.phone,
          email: values.email,
          operatorTypeUuid: values.operatorTypeUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentClassUuid: values.equipmentClassUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          permitNumber: values.permitNumber,
          permitIssuedDate: values.permitIssuedDate,
          permitVisaDate: values.permitVisaDate,
          registrationNumber: values.registrationNumber,
          registrationIssuedDate: values.registrationIssuedDate,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (isValidResponse(response)) {
          if (isDuplicateEntry(response)) {
            setShowProcessingDialog(false);
            let data = [];
            data.push({ id: "none", value: "Pentru acest punct de lucru această persoană există deja în aplicaţie!" });
            setValidationErrors(data);
          } else {
            uploadDocuments(response.data.value.registrationUuid, response.data.value.permitUuid);
            let file = document.querySelector(".fileR");
            if (file !== null) {
              file.value = "";
            }
            file = document.querySelector(".fileP");
            if (file !== null) {
              file.value = "";
            }
          }
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const uploadDocuments = (registrationUuid, permitUuid) => {
    if (fileRegistration === null && filePermit === null) {
      setShowProcessingDialog(false);
      setShowProcessedDialog(true);
      return;
    }
    let URL = "";
    const formData = new FormData();
    if (fileRegistration === null && filePermit !== null) {
      URL = uploadPermitURL;
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    if (fileRegistration !== null && filePermit === null) {
      URL = uploadRegistrationURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
    }
    if (fileRegistration !== null && filePermit !== null) {
      URL = uploadRegistrationAndPermitURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    axios
      .post(URL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setShowProcessingDialog(false);
        if (isValidResponse(response)) {
          setShowProcessedDialog(true);
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.lastName)) {
      data.push({ id: inputs[FIELD.lastName].id, value: inputs[FIELD.lastName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.lastName, FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.lastName].id,
        value: inputs[FIELD.lastName].label + validateMinMaxText(FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.firstName)) {
      data.push({ id: inputs[FIELD.firstName].id, value: inputs[FIELD.firstName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.firstName, FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.firstName].id,
        value: inputs[FIELD.firstName].label + validateMinMaxText(FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.companyUuid)) {
      data.push({ id: inputs[FIELD.companyUuid].id, value: inputs[FIELD.companyUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.identifier)) {
      data.push({ id: inputs[FIELD.identifier].id, value: inputs[FIELD.identifier].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.identifier, FIELD_SIZE.One, FIELD_SIZE.IdentifierMax)) {
      data.push({
        id: inputs[FIELD.identifier].id,
        value: inputs[FIELD.identifier].label + validateMinMaxText(FIELD_SIZE.One, FIELD_SIZE.IdentifierMax),
      });
      result = false;
    }
    if (!validateString(values.phone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({ id: inputs[FIELD.phone].id, value: inputs[FIELD.phone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax) });
      result = false;
    }
    if (!validateString(values.email, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({ id: inputs[FIELD.email].id, value: inputs[FIELD.email].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax) });
      result = false;
    }
    if (
      !isEmptyString(values.equipmentType) ||
      !isEmptyString(values.prescriptionUuid) ||
      !isEmptyString(values.operatorTypeUuid) ||
      !isEmptyString(values.equipmentClassificationUuid) ||
      !isEmptyString(values.equipmentClassUuid) ||
      !isEmptyString(values.equipmentGroupUuid) ||
      !isEmptyString(values.registrationNumber)
    ) {
      if (isEmptyString(values.equipmentType)) {
        data.push({
          id: inputs[FIELD.equipmentType].id,
          value: inputs[FIELD.equipmentType].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.prescriptionUuid)) {
        data.push({
          id: inputs[FIELD.prescriptionUuid].id,
          value: inputs[FIELD.prescriptionUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.operatorTypeUuid)) {
        data.push({
          id: inputs[FIELD.operatorTypeUuid].id,
          value: inputs[FIELD.operatorTypeUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentClassificationUuid)) {
        data.push({
          id: inputs[FIELD.equipmentClassificationUuid].id,
          value: inputs[FIELD.equipmentClassificationUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentClassUuid) && null !== ddOptionsEquipmentClasses && ddOptionsEquipmentClasses.length > 0) {
        data.push({
          id: inputs[FIELD.equipmentClassUuid].id,
          value: inputs[FIELD.equipmentClassUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.equipmentGroupUuid) && null !== ddOptionsEquipmentGroups && ddOptionsEquipmentGroups.length > 0) {
        data.push({
          id: inputs[FIELD.equipmentGroupUuid].id,
          value: inputs[FIELD.equipmentGroupUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
      if (isEmptyString(values.registrationNumber)) {
        data.push({
          id: inputs[FIELD.registrationNumber].id,
          value: inputs[FIELD.registrationNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
        });
        result = false;
      }
    }
    if (
      !isEmptyString(values.registrationNumber) &&
      !validateString(values.registrationNumber, FIELD_SIZE.One, FIELD_SIZE.OperatorRegistrationNumberMax)
    ) {
      data.push({
        id: inputs[FIELD.registrationNumber].id,
        value: inputs[FIELD.registrationNumber].label + validateMinMaxText(FIELD_SIZE.One, FIELD_SIZE.OperatorRegistrationNumberMax),
      });
      result = false;
    }
    if (!isEmptyString(values.permitNumber) || !isEmptyString(values.permitIssuedDate) || !isEmptyString(values.permitVisaDate)) {
      if (isEmptyString(values.permitNumber)) {
        data.push({
          id: inputs[FIELD.permitNumber].id,
          value: inputs[FIELD.permitNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
      if (isEmptyString(values.permitIssuedDate)) {
        data.push({
          id: inputs[FIELD.permitIssuedDate].id,
          value: inputs[FIELD.permitIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
      if (isEmptyString(values.permitVisaDate)) {
        data.push({
          id: inputs[FIELD.permitVisaDate].id,
          value: inputs[FIELD.permitVisaDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
    }
    if (!isEmptyString(values.permitNumber) && !validateString(values.permitNumber, FIELD_SIZE.One, FIELD_SIZE.OperatorPermitNumberMax)) {
      data.push({
        id: inputs[FIELD.permitNumber].id,
        value: inputs[FIELD.permitNumber].label + validateMinMaxText(FIELD_SIZE.One, FIELD_SIZE.OperatorPermitNumberMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Adaugă deservent</button>
      <div className="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.OperatorAddDisabled)} alt="Adaugă deservent" title="Adaugă deservent" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <form className="formArea">
        <div className="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii înregistrare date personale deservent">
              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.lastName].id}>
                  <FormInput
                    key={inputs[FIELD.lastName].id}
                    {...inputs[FIELD.lastName]}
                    value={values[inputs[FIELD.lastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.firstName].id}>
                  <FormInput
                    key={inputs[FIELD.firstName].id}
                    {...inputs[FIELD.firstName]}
                    value={values[inputs[FIELD.firstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.companyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.companyUuid].id}
                    name={inputs[FIELD.companyUuid].name}
                    label={inputs[FIELD.companyUuid].label}
                    value={values[inputs[FIELD.companyUuid].name]}
                    mandatory={inputs[FIELD.companyUuid].mandatory}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.identifier].id}>
                  <FormInput
                    key={inputs[FIELD.identifier].id}
                    {...inputs[FIELD.identifier]}
                    value={values[inputs[FIELD.identifier].name]}
                    onChange={onChange}
                  />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii tip autorizație deservent">
              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.equipmentType].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentType].id}
                    name={inputs[FIELD.equipmentType].name}
                    label={inputs[FIELD.equipmentType].label}
                    value={values[inputs[FIELD.equipmentType].name]}
                    mandatory={inputs[FIELD.equipmentType].mandatory}
                    ddOptions={equipmentTypes}
                    onChange={onChangeEquipmentType}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.prescriptionUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.prescriptionUuid].id}
                    name={inputs[FIELD.prescriptionUuid].name}
                    label={inputs[FIELD.prescriptionUuid].label}
                    value={values[inputs[FIELD.prescriptionUuid].name]}
                    mandatory={inputs[FIELD.prescriptionUuid].mandatory}
                    ddOptions={ddOptionsPrescriptions}
                    onChange={onChangePrescription}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.operatorTypeUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.operatorTypeUuid].id}
                    name={inputs[FIELD.operatorTypeUuid].name}
                    label={inputs[FIELD.operatorTypeUuid].label}
                    value={values[inputs[FIELD.operatorTypeUuid].name]}
                    mandatory={inputs[FIELD.operatorTypeUuid].mandatory}
                    ddOptions={ddOptionsOperatorTypes}
                    onChange={onChangeOperatorType}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.equipmentClassificationUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassificationUuid].id}
                    name={inputs[FIELD.equipmentClassificationUuid].name}
                    label={inputs[FIELD.equipmentClassificationUuid].label}
                    value={values[inputs[FIELD.equipmentClassificationUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassificationUuid].mandatory}
                    ddOptions={ddOptionsEquipmentClassifications}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.equipmentClassUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassUuid].id}
                    name={inputs[FIELD.equipmentClassUuid].name}
                    label={inputs[FIELD.equipmentClassUuid].label}
                    value={values[inputs[FIELD.equipmentClassUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassUuid].mandatory}
                    ddOptions={ddOptionsEquipmentClasses}
                    onChange={onChangeEquipmentClass}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.equipmentGroupUuid].id}>
                  <FormSelectMultiple
                    key={inputs[FIELD.equipmentGroupUuid].id}
                    name={inputs[FIELD.equipmentGroupUuid].name}
                    label={inputs[FIELD.equipmentGroupUuid].label}
                    value={values[inputs[FIELD.equipmentGroupUuid].name]}
                    mandatory={inputs[FIELD.equipmentGroupUuid].mandatory}
                    ddOptions={ddOptionsEquipmentGroups}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.registrationNumber].id}>
                  <FormInput
                    key={inputs[FIELD.registrationNumber].id}
                    {...inputs[FIELD.registrationNumber]}
                    value={values[inputs[FIELD.registrationNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.registrationIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.registrationIssuedDate].id}
                    {...inputs[FIELD.registrationIssuedDate]}
                    value={values[inputs[FIELD.registrationIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.permitNumber].id}>
                  <FormInput
                    key={inputs[FIELD.permitNumber].id}
                    {...inputs[FIELD.permitNumber]}
                    value={values[inputs[FIELD.permitNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div className="column" id={"internalLink" + inputs[FIELD.permitIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.permitIssuedDate].id}
                    {...inputs[FIELD.permitIssuedDate]}
                    value={values[inputs[FIELD.permitIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column" id={"internalLink" + inputs[FIELD.permitVisaDate].id}>
                  <FormInput
                    key={inputs[FIELD.permitVisaDate].id}
                    {...inputs[FIELD.permitVisaDate]}
                    value={values[inputs[FIELD.permitVisaDate].name]}
                    onChange={onChange}
                  />
                </div>
                <div className="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div className="row">
                <div className="column">
                  Adaugă poză/pdf autorizaţie deservent:{" "}
                  <input className="fileR" type="file" onChange={(e) => setFileRegistration(e.target.files[0])} />
                </div>
                <div className="column">&nbsp;</div>
              </div>

              <div className="row">
                <div className="column">
                  Adaugă poză/pdf talon deservent: <input className="fileP" type="file" onChange={(e) => setFilePermit(e.target.files[0])} />
                </div>
                <div className="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
        {showProcessingDialog !== true ? (
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.OperatorSave)}
                alt="Salvează deservent"
                title="Salvează deservent"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </form>
      <div className="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.OperatorAddDisabled)} alt="Adaugă deservent" title="Adaugă deservent" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <div className="spaceDiv" />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogConfigurationNotProcessed
        isVisible={showConfigNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowConfigNotLoadedDialog(false))}
        onHide={() => setShowConfigNotLoadedDialog(false)}
      />
    </div>
  );
}
