import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import DialogProcessingData from "../../components/DialogProcessingData";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListOperatorSessions() {
  const listOperatorSessionURL = BASE_URL + "operator/session/list";
  const deleteURL = BASE_URL + "operator/session/delete";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);

  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteRowDialog, setShowDeleteRowDialog] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
          loadOperatorSessions(mainCompanySessionUuid);
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    loadOperatorSessions(e.target.value);
  };

  const loadOperatorSessions = (uuid) => {
    axios
      .post(
        listOperatorSessionURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
    // console.log("Submit");
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Şterge"
                  title="Şterge"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowDeleteRowDialog(true);
  };

  const deleteListItem = async (e) => {
    e.preventDefault();
    setShowDeleteRowDialog(false);
    setShowProcessingDialog(true);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.sessionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response) {
          const newList = list.filter(function (rowData) {
            return rowData.sessionUuid !== selectedRow.sessionUuid;
          });
          setList(newList);
          setShowProcessingDialog(false);
          setShowProcessedDialog(true);
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  // view operator generated documents status for selected session
  const viewDocuments = (e) => {
    e.preventDefault();
    if (selectedRow === null || selectedRow.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    sessionStorage.setItem("operatorSessionUuid", selectedRow.sessionUuid);
    navigate("/operator/generatedocsstatus");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Reautorizări deservenți</button>
      <div className="spaceDiv" />
      <Link to={"/company/sessions"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)} alt="Documente firmă" title="Documente firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocumentsDisabled)}
        alt="Documente reautorizări deservenți"
        title="Documente reautorizări deservenți"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
          alt="Documente certificări deservenți auxiliari"
          title="Documente certificări deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateDocuments)}
          alt="Documente echipamente"
          title="Documente echipamente"
        />
      </Link>
      <div className="spaceDiv" />
      <div className="cardDropDown">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="spaceDiv" />
      <div className="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRow}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="sessionUuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="type" sortable header="Tip deservent"></Column>
          <Column field="date" sortable dataType="date" header="Data"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <button className="longButtonClassGray" onClick={viewDocuments}>
        Vezi documente reautorizare deservenți
      </button>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează o reautorizare de deservenţi!</p>
      </Dialog>
      <Dialog
        visible={showDeleteRowDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContentTwoButtons(STRING_CONST.DA, deleteListItem, STRING_CONST.NU, () => setShowDeleteRowDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowDeleteRowDialog(false)}
      >
        <p className="m-0">
          Doreşti să ştergi documentele pentru: {null != selectedRow ? selectedRow.type : ""} din data: {null != selectedRow ? selectedRow.date : ""}{" "}
          ?
        </p>
      </Dialog>
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
