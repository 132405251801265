import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  BASE_URL,
  ICONS,
  STRING_CONST,
  dialogHeaderElement,
  dialogFooterContent,
  dialogFooterContentTwoButtons,
  doNothing,
} from "../../common/globals";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListCompanies() {
  const listCompanyURL = BASE_URL + "company/listcompanies";
  const deleteURL = BASE_URL + "company/delete";
  const companyCreateSessionURL = BASE_URL + "company/createsession";
  const downloadIscirWorkersURL = BASE_URL + "worker/list/iscir/company";
  const downloadIscirOperatorsURL = BASE_URL + "operator/list/iscir/company";
  const downloadIscirEquipmentsURL = BASE_URL + "equipment/list/iscir/company";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [list, setList] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showViewRowDialog, setShowViewRowDialog] = useState(null);
  const [showDeleteRowDialog, setShowDeleteRowDialog] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [showProcessingDocumentsDialog, setShowProcessingDocumentsDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    townName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullAddress: { value: null, matchMode: FilterMatchMode.CONTAINS },
    countyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    axios
      .get(listCompanyURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Info)}
                  alt="Detalii"
                  title="Detalii"
                  onClick={() => viewRow(rowData)}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Şterge"
                  title="Şterge"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowDeleteRowDialog(true);
  };

  const deleteListItem = async (e) => {
    e.preventDefault();
    setShowDeleteRowDialog(false);
    setShowProcessingDialog(true);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response) {
          const newList = list.filter(function (rowData) {
            return rowData.uuid !== selectedRow.uuid;
          });
          setList(newList);
          if (null !== mainCompanySessionUuid && mainCompanySessionUuid === selectedRow.uuid) {
            sessionStorage.removeItem("mainCompanySessionUuid");
          }
          setShowProcessingDialog(false);
          setShowProcessedDialog(true);
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
    setShowDeleteRowDialog(false);
  };

  const handleIscirWorkers = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi auxiliari.docx";
    let uuids = [selectedRows.uuid];
    axios
      .post(
        downloadIscirWorkersURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleIscirOperators = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi.docx";
    let uuids = [selectedRows.uuid];
    axios
      .post(
        downloadIscirOperatorsURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleIscirEquipments = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă instalaţii ISCIR.docx";
    let uuids = [selectedRows.uuid];
    axios
      .post(
        downloadIscirEquipmentsURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const goToEditCompany = (e) => {
    e.preventDefault();
    sessionStorage.setItem("editCompanyUuid", selectedRow.uuid);
    navigate("/company/edit");
  };

  const startGenerateDocs = async (e) => {
    e.preventDefault();
    if (selectedRows === null) {
      showSelectedRowsWarningDialog(true);
      return;
    }
    let uuids = [selectedRows.uuid];
    axios
      .post(
        companyCreateSessionURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("companySessionUuid", response.data);
        navigate("/companydocuments/generatedocs");
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea documentelor punctelor de lucru nu a putut fi pornită!");
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Listă firme</button>
      <div className="spaceDiv" />
      <img src={require("../../static/images/buttons/" + ICONS.CompanyListDisabled)} alt="Listă firme" title="Listă firme" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyExport)}
        alt="Exportă firme"
        title="Exportă firme"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIR"
        onClick={handleIscirEquipments}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)}
        alt="Generare documente firmă"
        title="Generare documente firmă"
        onClick={startGenerateDocs}
      />
      <div className="spaceDiv" />
      <div className="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="name" filter filterPlaceholder="Caută" sortable header="Denumire firmă"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod sediu social"></Column>
          <Column field="townName" filter filterPlaceholder="Caută" sortable header="Localitate sediu social"></Column>
          <Column field="fullAddress" filter filterPlaceholder="Caută" sortable header="Adresă sediu social"></Column>
          <Column field="countyName" filter filterPlaceholder="Caută" sortable header="Judeţ/sector sediu social"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <img src={require("../../static/images/buttons/" + ICONS.CompanyListDisabled)} alt="Listă firme" title="Listă firme" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyExport)}
        alt="Exportă firme"
        title="Exportă firme"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIR"
        onClick={handleIscirEquipments}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)}
        alt="Generare documente firmă"
        title="Generare documente firmă"
        onClick={startGenerateDocs}
      />
      <div className="spaceDiv" />
      <Dialog
        visible={showViewRowDialog}
        modal
        header={dialogHeaderElement("Detalii firmă")}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowViewRowDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setShowViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td className="tdleft"></td>
              <td className="tdrigth">
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.CompanyEdit)}
                  alt="Modifică firmă"
                  title="Modifică firmă"
                  onClick={(e) => goToEditCompany(e)}
                />
              </td>
            </tr>
            <tr>
              <td className="tdleft">Denumire firmă:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.name : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Cod sediu social:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Număr înregistrare:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.registrationNumber : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Cod Fiscal:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.fiscalCode : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Adresă sediu social:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.fullAddress : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Telefon:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.phone : ""}</td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={showDeleteRowDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContentTwoButtons(STRING_CONST.DA, deleteListItem, STRING_CONST.NU, () => setShowDeleteRowDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setShowDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi firma: {null != selectedRow ? selectedRow.name : ""} ?</p>
      </Dialog>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează o firmă!</p>
      </Dialog>
      <DialogProcessingDocuments isVisible={showProcessingDocumentsDialog} />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
