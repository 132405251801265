import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListOperatorsOnHold() {
  const listOnHoldOperatorURL = BASE_URL + "operator/list/onhold";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const deleteURL = BASE_URL + "operator/delete";
  const activateURL = BASE_URL + "operator/activate";
  const downloadIscirOperatorsURL = BASE_URL + "operator/list/iscir/selected";
  const downloadRegistrationDocumentURL = BASE_URL + "operator/registration/download";
  const downloadPermitDocumentURL = BASE_URL + "operator/permit/download";
  const downloadOperatorsExcelURL = BASE_URL + "operatordocuments/export/excel";
  const operatorCreateSessionURL = BASE_URL + "operatordocuments/createsession";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [showViewRowDialog, setShowViewRowDialog] = useState(null);
  const [showDeleteRowDialog, setShowDeleteRowDialog] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [showConfigNotLoadedDialog, setShowConfigNotLoadedDialog] = useState(null);
  const [showProcessingDocumentsDialog, setShowProcessingDocumentsDialog] = useState(null);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    operatorType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    registrationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load config
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsCompany(response.data);
          if (null !== mainCompanySessionUuid) {
            setValues({ ...values, companyUuid: mainCompanySessionUuid });
            loadOperators(mainCompanySessionUuid, "");
          }
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadOperators(e.target.value);
  };

  const loadOperators = (compUuid) => {
    axios
      .post(
        listOnHoldOperatorURL,
        {
          uuid: compUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Info)}
                  alt="Detalii"
                  title="Detalii"
                  onClick={() => viewRow(rowData)}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Şterge"
                  title="Şterge"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setShowDeleteRowDialog(true);
  };

  const deleteListItem = async (e) => {
    e.preventDefault();
    setShowDeleteRowDialog(false);
    setShowProcessingDialog(true);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response) {
          const newList = list.filter(function (rowData) {
            return rowData.uuid !== selectedRow.uuid;
          });
          setList(newList);
          setShowProcessingDialog(false);
          setShowProcessedDialog(true);
        } else {
          setShowProcessingDialog(false);
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const activate = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDialog(true);
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        activateURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDialog(false);
        if (null !== response) {
          navigate("/operator/list");
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  // process start operator recertification workflow
  const startGenerateDocs = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        operatorCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: values.operatorTypeUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          sessionStorage.setItem("operatorSessionUuid", response.data);
          navigate("/operator/generatedocsinstructions");
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleIscirOperators = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirOperatorsURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
          );
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleGetDocument = async (documentUuid, documentFileName, type, e) => {
    e.preventDefault();
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = documentFileName;
    let URL_D = downloadRegistrationDocumentURL;
    if (type === 2) {
      URL_D = downloadPermitDocumentURL;
    }
    axios
      .post(
        URL_D,
        {
          uuid: documentUuid,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(new Blob([response.data], {}));
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    setShowProcessingDocumentsDialog(true);
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă deservenţi.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadOperatorsExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setShowProcessingDocumentsDialog(false);
        if (null !== response && null !== response.data) {
          link.href = URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
          link.click();
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDocumentsDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  const goToEditOperator = (e) => {
    e.preventDefault();
    sessionStorage.setItem("editOperatorUuid", selectedRow.uuid);
    navigate("/operator/edit");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Listă deservenți</button>
      <div className="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorAdd)} alt="Adaugă deservent" title="Adaugă deservent" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
        alt="Exportă deservenți"
        title="Exportă deservenți"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
        alt="Documente reautorizare deservenți"
        title="Documente reautorizare deservenți"
        onClick={startGenerateDocs}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorActivate)}
        alt="Activează deservenți"
        title="Activează deservenți"
        onClick={activate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorOnHoldListDisabled)}
        alt="Listă deservenți inactivi"
        title="Listă deservenți inactivi"
      />
      <div className="spaceDiv" />
      <div className="cardDropDown">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="spaceDiv" />
      <div className="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="fullName" filter filterPlaceholder="Caută" sortable header="Nume angajat"></Column>
          <Column field="identifier" filter filterPlaceholder="Caută" sortable header="CNP"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru"></Column>
          <Column field="operatorType" filter filterPlaceholder="Caută" sortable header="Tip deservent"></Column>
          <Column field="registrationNumber" filter filterPlaceholder="Caută" sortable header="Număr autorizație"></Column>
          <Column field="permitVisaDate" sortable dataType="date" header="Dată vizare talon"></Column>
          <Column field="permitExpirationDate" sortable dataType="date" header="Dată expirare talon"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorAdd)} alt="Adaugă deservent" title="Adaugă deservent" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
        alt="Exportă deservenți"
        title="Exportă deservenți"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
        alt="Documente reautorizare deservenți"
        title="Documente reautorizare deservenți"
        onClick={startGenerateDocs}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorActivate)}
        alt="Activează deservenți"
        title="Activează deservenți"
        onClick={activate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorOnHoldListDisabled)}
        alt="Listă deservenți inactivi"
        title="Listă deservenți inactivi"
      />
      <div className="spaceDiv" />
      <Dialog
        visible={showViewRowDialog}
        modal
        header={dialogHeaderElement("Detalii deservent")}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowViewRowDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setShowViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td className="tdleft"></td>
              <td className="tdrigth">
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.OperatorEdit)}
                  alt="Modifică deservent"
                  title="Modifică deservent"
                  onClick={(e) => goToEditOperator(e)}
                />
              </td>
            </tr>
            <tr>
              <td className="tdleft">Nume angajat:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.fullName : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">CNP:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.identifier : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Cod punct de lucru:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Tip deservent:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.operatorType : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Număr autorizație:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.registrationNumber : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Dată vizare talon:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.permitVisaDate : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Dată expirare talon:</td>
              <td className="tdrigth"> {null != selectedRow ? selectedRow.permitExpirationDate : ""}</td>
            </tr>
            <tr>
              <td className="tdleft"></td>
              <td className="tdrigth">
                {null != selectedRow && selectedRow.registrationDocumentUuid !== null && selectedRow.registrationDocumentUuid !== "" ? (
                  <>
                    <img
                      className="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
                      alt={"Descarca " + selectedRow.registrationDocumentFileName}
                      title={"Descarca " + selectedRow.registrationDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.registrationDocumentUuid, selectedRow.registrationDocumentFileName, 1, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.registrationDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="tdleft"></td>
              <td className="tdrigth">
                {null != selectedRow && selectedRow.permitDocumentUuid !== null && selectedRow.permitDocumentUuid !== "" ? (
                  <>
                    <img
                      className="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
                      alt={"Descarca " + selectedRow.permitDocumentFileName}
                      title={"Descarca " + selectedRow.permitDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.permitDocumentUuid, selectedRow.permitDocumentFileName, 2, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.permitDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={showDeleteRowDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContentTwoButtons(STRING_CONST.DA, deleteListItem, STRING_CONST.NU, () => setShowDeleteRowDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi autorizaţia: {null != selectedRow ? selectedRow.registrationNumber : ""} ?</p>
      </Dialog>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează minim un deservent!</p>
      </Dialog>
      <DialogProcessingDocuments isVisible={showProcessingDocumentsDialog} />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogConfigurationNotProcessed
        isVisible={showConfigNotLoadedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowConfigNotLoadedDialog(false))}
        onHide={() => setShowConfigNotLoadedDialog(false)}
      />
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
