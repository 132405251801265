// prime core css
import "primereact/resources/primereact.min.css";
// prime theme css
import "primereact/resources/themes/nova/theme.css";
// custom css
import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ImportTemplates() {
  return (
    <div className="clientArea">
      <Sidebar />
      <Link to={"/help/allowedclassifications"}>
        <button className="buttonClassGray">Clasificări permise</button>
      </Link>
      <button className="buttonClassGreen">Definiții fișiere import</button>
      <Link to={"/help/iconslegend"}>
        <button className="buttonClassGray">Legendă iconițe</button>
      </Link>
      <div className="spaceDiv" />
      <div>
        <div className="rowdouble">
          <div className="column">Fişiere standard în format excel pentru import informaţii în aplicaţie:</div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
              alt="Iconiţă import firme"
              title="Iconiţă import firme"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import firme - informatii.xlsx"
              to={require("../../static/documents/import_files/templates/import-firme-informatii.xlsx")}
            >
              <span>Descarcă fişier standard pentru import firme şi puncte de lucru.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
              alt="Iconiţă import firme"
              title="Iconiţă import firme"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import firme - personal punct de lucru.xlsx"
              to={require("../../static/documents/import_files/templates/import-firme-personal-punct-de-lucru-firma.xlsx")}
            >
              <span>Descarcă fişier standard pentru import personal puncte de lucru.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.OperatorImport)}
              alt="Iconiţă import deservenţi"
              title="Iconiţă import deservenţi"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import deservent.xlsx"
              to={require("../../static/documents/import_files/templates/import-deservent.xlsx")}
            >
              <span>Descarcă fişier standard pentru import deservenţi.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
              alt="Iconiţă import deservenţi auxiliari"
              title="Iconiţă import deservenţi auxiliari"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import deservent auxiliar.xlsx"
              to={require("../../static/documents/import_files/templates/import-deservent-auxiliar.xlsx")}
            >
              <span>Descarcă fişier standard pentru import deservenţi auxiliari.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)}
              alt="Iconiţă import instalaţii de ridicat"
              title="Iconiţă import instalaţii de ridicat"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii de ridicat.xlsx"
              to={require("../../static/documents/import_files/templates/import-instalatii-de-ridicat.xlsx")}
            >
              <span>Descarcă fişier standard pentru import instalaţii de ridicat.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)}
              alt="Iconiţă import instalaţii sub presiune"
              title="Iconiţă import instalaţii sub presiune"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii sub presiune.xlsx"
              to={require("../../static/documents/import_files/templates/import-instalatii-sub-presiune.xlsx")}
            >
              <span>Descarcă fişier standard pentru import instalaţii sub presiune.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)}
              alt="Iconiţă import instalaţii sub presiune"
              title="Iconiţă import instalaţii sub presiune"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii sub presiune - supape de siguranta.xlsx"
              to={require("../../static/documents/import_files/templates/import-instalatii-sub-presiune-supape-de-siguranta.xlsx")}
            >
              <span>Descarcă fişier standard pentru import supape de siguranţă.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <div className="spaceDiv" />
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">Fişiere exemplu în format excel pentru import:</div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
              alt="Iconiţă import firme"
              title="Iconiţă import firme"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import firme - informatii - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-firme-informatii-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import firme şi puncte de lucru.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
              alt="Iconiţă import firme"
              title="Iconiţă import firme"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import firme - personal punct de lucru firma - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-firme-personal-punct-de-lucru-firma-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import personal puncte de lucru.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.OperatorImport)}
              alt="Iconiţă import deservenţi"
              title="Iconiţă import deservenţi"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import deservent WritersTrading - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-deservent-WritersTrading-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import deservenţi.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
              alt="Iconiţă import deservenţi auxiliari"
              title="Iconiţă import deservenţi auxiliari"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import deservent auxiliar WritersTrading - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-deservent-auxiliar-WritersTrading-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import deservenţi auxiliari.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)}
              alt="Iconiţă import instalaţii de ridicat"
              title="Iconiţă import instalaţii de ridicat"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii de ridicat WritersTrading - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-instalatii-de-ridicat-WritersTrading-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import instalaţii de ridicat.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)}
              alt="Iconiţă import instalaţii sub presiune"
              title="Iconiţă import instalaţii sub presiune"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii sub presiune WritersTrading - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-instalatii-sub-presiune-WritersTrading-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import instalaţii sub presiune.</span>
            </Link>
          </div>
        </div>
        <div className="rowdouble">
          <div className="column">
            <img
              className="imgdownload"
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)}
              alt="Iconiţă import instalaţii sub presiune"
              title="Iconiţă import instalaţii sub presiune"
            />
            &nbsp;
            <Link
              target="_blank"
              download="import instalatii sub presiune - supape de siguranta - WritersTrading - demo.xlsx"
              to={require("../../static/documents/import_files/examples/import-instalatii-sub-presiune-supape-de-siguranta-WritersTrading-demo.xlsx")}
            >
              <span>Descarcă fişier exemplu pentru import supape de siguranţă.</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
