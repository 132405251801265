// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContent } from "../../common/globals";
import DialogListNotLoaded from "../../components/DialogListNotLoaded";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListEquipmentSessions() {
  const baseURL = BASE_URL + "equipment/session/list";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);

  // state objects
  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showSelectedRowsWarningDialog, setShowSelectedRowsWarningDialog] = useState(false);
  const [showListNotLoadedDialog, setShowListNotLoadedDialog] = useState(null);

  // authentication details
  const { auth } = useAuth();

  // for app navigation
  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
          loadCompanySessions(mainCompanySessionUuid);
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    loadCompanySessions(e.target.value);
  };

  const loadCompanySessions = (uuid) => {
    axios
      .post(
        baseURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setList(response.data);
        } else {
          setShowListNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowListNotLoadedDialog(true);
      });
    // console.log("Submit");
  };

  // view equipment generated documents status for selected session
  const viewDocuments = async (e) => {
    e.preventDefault();
    if (selectedRow === null || selectedRow.length === 0) {
      setShowSelectedRowsWarningDialog(true);
      return;
    }
    sessionStorage.setItem("equipmentSessionUuid", selectedRow.sessionUuid);
    navigate("/equipmentdocuments/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Documente echipamente</button>
      <div className="spaceDiv" />
      <Link to={"/company/sessions"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)} alt="Documente firmă" title="Documente firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
          alt="Documente reautorizări deservenți"
          title="Documente reautorizări deservenți"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
          alt="Documente certificări deservenți auxiliari"
          title="Documente certificări deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateDocumentsDisabled)}
        alt="Documente echipamente"
        title="Documente echipamente"
      />
      <div className="spaceDiv" />
      <div className="cardDropDown">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="spaceDiv" />
      <div className="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRow}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="sessionUuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="type" sortable header="Tip documentaţie echipamente"></Column>
          <Column field="date" sortable dataType="date" header="Data"></Column>
        </DataTable>
      </div>
      <button className="longButtonClassGray" onClick={viewDocuments}>
        Vezi documente echipamente
      </button>
      <Dialog
        visible={showSelectedRowsWarningDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowSelectedRowsWarningDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSelectedRowsWarningDialog(false)}
      >
        <p className="m-0">Selectează un set de documente!</p>
      </Dialog>
      <DialogListNotLoaded
        isVisible={showListNotLoadedDialog}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowListNotLoadedDialog(false))}
        onHide={() => setShowListNotLoadedDialog(false)}
      />
    </div>
  );
}
