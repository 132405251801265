import "../static/css/tutorial.css";

import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

export default function Tutorials() {
    return (
        <div className="Tutorials">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_003.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">
                    <div className="text-900 font-bold text-6xl mb-4 text-center">Firme</div>
                    <div className="text-700 text-xl mb-6 text-center line-height-3">
                        (adăugare, import și generare documente firme care dețin echipamente și instalații ISCIR)
                        <br />
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/company_add.png")} />
                                    </div>
                                    {/* <br></br> */}
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum adaug o firmă în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial adăugare o firmă nouă în baza de date, cu sediul social și punctele de lucru ale acesteia.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <br />
                                    <br />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/adăugare_firmă.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/company_import.png")} />
                                    </div>
                                    {/* <br></br> */}
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum import o firmă în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial import o firmă nouă în baza de date, cu sediul social și punctele de lucru ale acesteia.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/import_firmă.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/company_gen_doc.png")} />
                                    </div>
                                    {/* <br></br> */}
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum generez documente pentru o firmă? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial generare documente pentru o firmă nouă, cu sediul social și punctele de lucru ale acesteia.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/documente_firmă.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">
                    <div className="text-900 font-bold text-6xl mb-4 text-center">Deservenți ISCIR</div>
                    <div className="text-700 text-xl mb-6 text-center line-height-3">
                        (adăugare, import și generare documente deservenți ISCIR)
                        <br />
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/operator_add.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum adaug un deservent în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial adăugare un deservent ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/adăugare_deservent.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/operator_import.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum import un deservent în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial import un deservent ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/import_deservent.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/operator_gen_doc.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum generez documente ptr. un deservent?</span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>
                                                Tutorial generare documente pentru un deservent ISCIR din baza de date, la un punct de lucru al unei firme existente.
                                            </span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/documente_deservent.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">
                    <div className="text-900 font-bold text-6xl mb-4 text-center">Deservenți auxiliari ISCIR</div>
                    <div className="text-700 text-xl mb-6 text-center line-height-3">
                        (adăugare, import și generare documente deservenți auxiliari ISCIR)
                        <br />
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/worker_add.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum adaug un deservent auxiliar nou în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial adăugare un deservent auxiliar ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/adăugare_deservent_auxiliar.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/worker_import.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum import un deservent auxiliar nou în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial import un deservent auxiliar ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/import_deservent_auxiliar.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/worker_gen_doc.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum generez un document pentru un deservent auxiliar? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>
                                                Tutorial generare documente pentru un deservent auxiliar ISCIR din baza de date, la un punct de lucru al unei firme existente.
                                            </span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/documente_deservent_auxiliar.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">
                    <div className="text-900 font-bold text-6xl mb-4 text-center">Echipamente și instalații ISCIR</div>
                    <div className="text-700 text-xl mb-6 text-center line-height-3">
                        (adăugare, import și generare documente echipamente și instalații ISCIR)
                        <br />
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/equipment_add.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum adaug un echipament nou în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial adăugare un echipament ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/adăugare_echipamentIR.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/equipment_import.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum import un echipament nou în baza de date? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>Tutorial import un echipament ISCIR nou în baza de date, la un punct de lucru al unei firme existente.</span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/import_echipamentIR.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="p-3 h-full">
                                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                    <div className=" align-items-center ">
                                        <img alt="" className="image-tutorial" src={require("../static/images/64x64/equipment_gen_doc.png")} />
                                    </div>
                                    <br></br>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <div className="flex align-items-center">
                                        <span className="font-subtit">Cum generez un document pentru un echipament? </span>
                                    </div>
                                    <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                    <ul className="list-none p-0 m-0 flex-grow-1">
                                        <li className="flex align-items-center mb-3">
                                            <span>
                                                Tutorial generare documente pentru un echipament ISCIR din baza de date, la un punct de lucru al unei firme existente.
                                            </span>
                                        </li>
                                    </ul>
                                    <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                    <div className="btn-lnk-blue">
                                        <Link target="_blank" to={require("../static/documents/tutorials/documente_echipamentIR.pdf")}>
                                            <span className=" p-3 w-full mt-auto txt-ctr">Descarcă tutorial</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <br />
                    <br />
                </div>
            </main>

            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}