import React from "react";
import { Link } from "react-router-dom";

import { ICONS } from "../common/globals";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <img className="logo-at" src={require("../static/images/" + ICONS.LogoRsvti)} alt="Asisco Tehnic SRL" />

        <input className="side-menu" type="checkbox" id="side-menu" />
        <label htmlFor="side-menu">
          <span></span>
        </label>

        <nav className="nav">
          <ul className="menu">
            <li>
              <Link to={"/public/terms"}>Termeni și condiții</Link>
            </li>
            <li>
              <Link to={"/public/gdpr"}>Politica de confidențialitate</Link>
            </li>
            <li>
              <Link to={"/public/contact"}></Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
