import "../../static/css/pageLayout.css";

import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogMissingImportFiles from "../../components/DialogMissingImportFiles";
import DialogProcessingData from "../../components/DialogProcessingData";
import FormSelect from "../../components/FormSelect";
import ProcessImportErrors from "../../components/ProcessImportErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ImportWorkers() {
  const importWorkerURL = BASE_URL + "import/worker";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [file, setFile] = useState(null);
  const [showConfigNotLoadedDialog, setShowConfigNotLoadedDialog] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [showMissingImportFilesDialog, setShowMissingImportFilesDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsCompany(response.data);
          if (null !== mainCompanySessionUuid) {
            setValues({ ...values, companyUuid: mainCompanySessionUuid });
          }
        } else {
          setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file === null) {
      setShowMissingImportFilesDialog(true);
      return;
    }
    setShowProcessingDialog(true);
    setValidationErrors(null);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("companyUuid", values.companyUuid);
    axios
      .post(importWorkerURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setShowProcessingDialog(false);
        if (null !== response && null !== response.data) {
          if (response.data.statusType !== "OK") {
            setValidationErrors(response.data);
          } else {
            setShowProcessedDialog(true);
          }
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Importă deservenți auxiliari</button>
      <div className="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerImportDisabled)}
        alt="Importă deservenți auxiliari"
        title="Importă deservenți auxiliari"
      />
      <form className="formArea">
        <div className="row">
          <div className="column">
            <FormSelect
              key={inputs[0].id}
              name={inputs[0].name}
              label={inputs[0].label}
              value={values[inputs[0].name]}
              ddOptions={ddOptionsCompany}
              onChange={onChange}
            />
          </div>
          <div className="column">
            <p>&nbsp;</p>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </div>
          <div className="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {showProcessingDialog !== true ? (
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.WorkerSaveAll)}
                alt="Importă"
                title="Importă"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {null !== validationErrors && null !== validationErrors.statusType && validationErrors.statusType !== "OK" ? (
          <ProcessImportErrors validationErrors={validationErrors} />
        ) : null}
      </form>
      <div className="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.WorkerImportDisabled)}
        alt="Importă deservenți auxiliari"
        title="Importă deservenți auxiliari"
      />
      <div className="spaceDiv" />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogConfigurationNotProcessed
        isVisible={showConfigNotLoadedDialog}
        footer={dialogFooterContent("OK", () => setShowConfigNotLoadedDialog(false))}
        onHide={() => setShowConfigNotLoadedDialog(false)}
      />
      <DialogMissingImportFiles
        isVisible={showMissingImportFilesDialog}
        footer={dialogFooterContent("OK", () => setShowMissingImportFilesDialog(false))}
        onHide={() => setShowMissingImportFilesDialog(false)}
        single
      />
    </div>
  );
}
