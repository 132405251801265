import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { isEmptyString } from "../../common/validations";
import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import ProcessFormErrors from "../../components/ProcessFormErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EquipmentGenerateDocsRegister() {
  const instructionsConfigURL = BASE_URL + "equipmentdocuments/get/register/configs";
  const instructionsConfigSaveURL = BASE_URL + "equipmentdocuments/generate/register";

  const uuid = sessionStorage.getItem("equipmentSessionUuid");

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [values, setValues] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [prescriptionUuid, setPrescriptionUuid] = useState(null);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios
      .post(
        instructionsConfigURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.companies.length; i++) {
          data = { ...data, ["rsvtiPerson" + i]: "" };
          data = { ...data, ["contactPerson" + i]: "" };
          data = { ...data, ["date" + i]: "" };
          data = { ...data, ["maintenancePeriod" + i]: "" };
          data = { ...data, ["maintenanceHours" + i]: "" };
        }
        setValues({ ...values, ...data });
        setConfigs(response.data.companies);
        setPrescriptionUuid(response.data.prescriptionUuid);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].rsvtiPersons.length === 1) {
        data = { ...data, ["rsvtiPerson" + i]: configs[i].rsvtiPersons[0].uuid };
      }
      if (configs[i].contactPersons.length === 1) {
        data = { ...data, ["contactPerson" + i]: configs[i].contactPersons[0].uuid };
      }
    }
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      let newfield = {
        uuid: configs[i].uuid,
        iscirOwnerUuid: values["rsvtiPerson" + i],
        contactUuid: values["contactPerson" + i],
        issuedDate: values["date" + i],
        maintenancePeriod: values["maintenancePeriod" + i],
        maintenanceHours: values["maintenanceHours" + i],
      };
      data = [...data, newfield];
    }
    axios
      .post(
        instructionsConfigSaveURL,
        {
          uuid: uuid,
          configs: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/equipmentdocuments/generatedocsview");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      if (isEmptyString(values["rsvtiPerson" + i])) {
        data.push({
          id: i * 3 + 1,
          value: "Operator RSVTI (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
      if (isEmptyString(values["contactPerson" + i])) {
        data.push({
          id: i * 3 + 1,
          value: "Responsabil punct de lucru (" + configs[i].code + ") " + STRING_CONST.VALIDATION_IS_MANDATORY,
        });
        result = false;
      }
    }
    setValidationErrors(data);
    return result;
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <button className="buttonClassGreen">Generare registre echipamente</button>
      <div className="spaceDiv" />

      <div className="rowdouble">
        <div className="column">
          <p className="pTitle">Registre instalații ISCIR</p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div className="rowdouble">
            <div className="column">
              <p className="pTitle">
                Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
              </p>
            </div>
          </div>
          {"452411a8-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ? (
            <div className="rowdouble">
              <div className="column" id={"internalLink" + index * 15}>
                <FormInput
                  key={index * 15}
                  type="date"
                  name={"date" + index}
                  label="Alege data emitere registru"
                  value={values["date" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          {"2f2e988d-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "85890afa-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "dede1572-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "ebfd13d5-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "05106d4f-9f57-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "1cc3a4d1-9f57-11ee-a0c5-74563c1b28c3" === prescriptionUuid ? (
            <div className="rowdouble">
              <div className="column" id={"internalLink" + index * 15 + 1}>
                <FormInput
                  key={index * 15 + 1}
                  type="text"
                  name={"maintenancePeriod" + index}
                  label="Perioadă calendaristică lucrări de întreținere și service"
                  value={values["maintenancePeriod" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          {"2f2e988d-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "85890afa-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "dede1572-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "ebfd13d5-9f56-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "05106d4f-9f57-11ee-a0c5-74563c1b28c3" === prescriptionUuid ||
            "1cc3a4d1-9f57-11ee-a0c5-74563c1b28c3" === prescriptionUuid ? (
            <div className="rowdouble">
              <div className="column" id={"internalLink" + index * 15 + 2}>
                <FormInput
                  key={index * 15 + 2}
                  type="text"
                  name={"maintenanceHours" + index}
                  label="Perioadă ore de funcționare lucrări de întreținere și service"
                  value={values["maintenanceHours" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 15 + 3)}>
              <FormSelect
                key={index * 15 + 3}
                name={"rsvtiPerson" + index}
                label="Operator RSVTI"
                value={values["rsvtiPerson" + index]}
                ddOptions={config.rsvtiPersons}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="rowdouble">
            <div className="column" id={"internalLink" + (index * 15 + 4)}>
              <FormSelect
                key={index * 15 + 4}
                name={"contactPerson" + index}
                label="Responsabil punct de lucru"
                value={values["contactPerson" + index]}
                ddOptions={config.contactPersons}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      ))}

      {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      {processingDialog !== true ? (
        <div className="rowdoublecentered">
          <div className="columnbutton">
            <img
              className="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateWordDocuments)}
              alt="Generare registre"
              title="Generare registre"
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}


      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
