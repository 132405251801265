import "./formInput.css";

import React from "react";

import { STRING_CONST } from "../common/globals";

const FormInputReadonly = (props) => {
  const { label, id, mandatory, ...inputProps } = props;

  return (
    <div className="formInput">
      {STRING_CONST.MANDATORY_FIELD === mandatory ? <label className="labelred">{label}</label> : ""}
      {STRING_CONST.MANDATORY_FIELD_IN_GROUP === mandatory ? <label className="labelorange">{label}</label> : ""}
      {STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP === mandatory ? <label className="labelbrown">{label}</label> : ""}
      {STRING_CONST.MANDATORY_FIELD !== mandatory &&
      STRING_CONST.MANDATORY_FIELD_IN_GROUP !== mandatory &&
      STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP !== mandatory ? (
        <label>{label}</label>
      ) : (
        ""
      )}
      <input {...inputProps} readOnly="readOnly" />
    </div>
  );
};

export default FormInputReadonly;
