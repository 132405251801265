import React from "react";
import { useNavigate } from "react-router-dom";

import { ICONS } from "../common/globals";

const GenerateButtonOperator = (props) => {
  const { status, sessionUuid, companyUuid } = props;

  const navigate = useNavigate();

  const handleGenerateTests = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/operator/generatedocstests");
  };

  const handleGenerateCertificates = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/operator/generatedocscertificates");
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/operator/generatedocsview");
  };

  return (
    <>
      {status === "199e1e6e-b4d1-11ef-b884-74563c1b28c3" ? (
        <>
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
                alt="Continuă generarea documentelor"
                title="Continuă generarea documentelor"
                onClick={(e) => handleGenerateTests(sessionUuid, companyUuid, e)}
              />
            </div>
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
                alt="Vezi documentele generate deja"
                title="Vezi documentele generate deja"
                onClick={(e) => handleViewDocuments(sessionUuid, e)}
              />
            </div>
          </div>
        </>
      ) : null}
      {status === "2c482611-b4d1-11ef-b884-74563c1b28c3" ? (
        <>
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.OperatorGenerateWordDocuments)}
                alt="Continuă generarea documentelor"
                title="Continuă generarea documentelor"
                onClick={(e) => handleGenerateCertificates(sessionUuid, companyUuid, e)}
              />
            </div>
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
                alt="Vezi documentele generate deja"
                title="Vezi documentele generate deja"
                onClick={(e) => handleViewDocuments(sessionUuid, e)}
              />
            </div>
          </div>
        </>
      ) : null}
      {status === "3d246cf5-b4d1-11ef-b884-74563c1b28c3" ? (
        <div className="rowdoublecentered">
          <div className="columnbutton">
            <img
              className="imgtoolbar"
              src={require("../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
              alt="Vezi toate documentele generate"
              title="Vezi toate documentele generate"
              onClick={(e) => handleViewDocuments(sessionUuid, e)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GenerateButtonOperator;
