import { useNavigate } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

const Missing = () => {
  const navigate = useNavigate();

  const goToStats = () => navigate("/");

  return (
    <div className="tothemiddle">
      <Header />
      <button className="buttonClassGreen">PAGINA NU EXISTĂ</button>
      <div className="spaceDiv" />
      <strong className="labelred">ACEASTĂ PAGINĂ NU EXISTĂ!</strong>
      <div className="spaceDiv" />
      <button className="longButtonClassGrayNoPadding" onClick={goToStats}>
        Mergi la pagina principală!
      </button>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Missing;
