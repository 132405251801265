import React from "react";

import Header from "./Header";
import Footer from "./Footer";

export default function Portofolio() {
  return (
    <div className="Portofolio">
      <Header />

      <main>
        <div style={{ height: "600px" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1 className="slogan3">PORTOFOLIU</h1>
        </div>
      </main>

      <Footer />
    </div>
  );
}
