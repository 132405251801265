import { Button } from "primereact/button";

const BASE_URL = "https://myrsvti.ro:444/";
//const BASE_URL = "http://localhost:8080/";

const COMPANIES_ICONS_PATH = "companies/";

const COMPANIES_DISABLED_ICONS_PATH = "disabled/" + COMPANIES_ICONS_PATH;

const EQUIPMENTS_ICONS_PATH = "equipments/";

const EQUIPMENTS_DISABLED_ICONS_PATH = "disabled/" + EQUIPMENTS_ICONS_PATH;

const ISCIR_COMPANIES_ICONS_PATH = "iscir_companies/";

const ISCIR_COMPANIES_DISABLED_ICONS_PATH = "disabled/" + ISCIR_COMPANIES_ICONS_PATH;

const ISCIR_PERSONS_ICONS_PATH = "iscir_persons/";

const ISCIR_PERSONS_DISABLED_ICONS_PATH = "disabled/" + ISCIR_PERSONS_ICONS_PATH;

const RSVTI_PERSONS_ICONS_PATH = "rsvti_persons/";

const RSVTI_PERSONS_DISABLED_ICONS_PATH = "disabled/" + RSVTI_PERSONS_ICONS_PATH;

const ICONS = {
  CompanyAdd: COMPANIES_ICONS_PATH + "company_main_add.png",
  CompanyAddDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_add.png",
  CompanyEdit: COMPANIES_ICONS_PATH + "company_main_edit.png",
  CompanyEditDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_edit.png",
  CompanyExport: COMPANIES_ICONS_PATH + "company_main_export.png",
  CompanyExportDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_export.png",
  CompanyGenerateDocuments: COMPANIES_ICONS_PATH + "company_main_generate_documents.png",
  CompanyGenerateDocumentsDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_generate_documents.png",
  CompanyImport: COMPANIES_ICONS_PATH + "company_main_import.png",
  CompanyImportDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_import.png",
  CompanyList: COMPANIES_ICONS_PATH + "company_main_list.png",
  CompanyListDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_list.png",
  CompanyRegistration: COMPANIES_ICONS_PATH + "company_main_registration.png",
  CompanyRegistrationDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_registration.png",
  CompanySave: COMPANIES_ICONS_PATH + "company_main_save.png",
  CompanySaveDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_main_save.png",
  CompanySaveAll: COMPANIES_ICONS_PATH + "company_main_save_all.png",
  CompanyBranchAdd: COMPANIES_ICONS_PATH + "company_branch_add.png",
  CompanyBranchAddDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_add.png",
  CompanyBranchEdit: COMPANIES_ICONS_PATH + "company_branch_edit.png",
  CompanyBranchEditDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_edit.png",
  CompanyBranchExport: COMPANIES_ICONS_PATH + "company_branch_export.png",
  CompanyBranchExportDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_export.png",
  CompanyBranchGenerateDocuments: COMPANIES_ICONS_PATH + "company_branch_generate_documents.png",
  CompanyBranchGenerateDocumentsDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_generate_documents.png",
  CompanyBranchGenerateWordDocuments: COMPANIES_ICONS_PATH + "company_branch_generate_word_files.png",
  CompanyBranchGenerateWordDocumentsDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_generate_word_files.png",
  CompanyBranchImport: COMPANIES_ICONS_PATH + "company_branch_import.png",
  CompanyBranchImportDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_import.png",
  CompanyBranchList: COMPANIES_ICONS_PATH + "company_branch_list.png",
  CompanyBranchListDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_list.png",
  CompanyBranchRegistration: COMPANIES_ICONS_PATH + "company_branch_registration.png",
  CompanyBranchRegistrationDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_registration.png",
  CompanyBranchSave: COMPANIES_ICONS_PATH + "company_branch_save.png",
  CompanyBranchSaveDisabled: COMPANIES_DISABLED_ICONS_PATH + "company_branch_save.png",
  CompanyBranchSaveAll: COMPANIES_ICONS_PATH + "company_branch_save_all.png",
  EquipmentGenerateOrderDocuments: EQUIPMENTS_ICONS_PATH + "equipment_generate_comanda_CNCIR.png",
  EquipmentGenerateOrderDocumentsDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_generate_comanda_CNCIR.png",
  EquipmentGenerateRegisterDocuments: EQUIPMENTS_ICONS_PATH + "equipment_generate_register.png",
  EquipmentGenerateRegisterDocumentsDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_generate_register.png",
  EquipmentGeneratePVDocuments: EQUIPMENTS_ICONS_PATH + "equipment_generate_RSVTI_PV.png",
  EquipmentGeneratePVDocumentsDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_generate_RSVTI_PV.png",
  EquipmentLifting: EQUIPMENTS_ICONS_PATH + "equipment_IR.png",
  EquipmentLiftingActivate: EQUIPMENTS_ICONS_PATH + "equipment_IR_comeback.png",
  EquipmentLiftingAdd: EQUIPMENTS_ICONS_PATH + "equipment_IR_add.png",
  EquipmentLiftingAddDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_add.png",
  EquipmentLiftingExport: EQUIPMENTS_ICONS_PATH + "equipment_IR_export.png",
  EquipmentLiftingExportDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_export.png",
  EquipmentLiftingGenerateDocuments: EQUIPMENTS_ICONS_PATH + "equipment_IR_generate_documents.png",
  EquipmentLiftingGenerateDocumentsDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_generate_documents.png",
  EquipmentLiftingGenerateWordDocuments: EQUIPMENTS_ICONS_PATH + "equipment_IR_generate_word_files.png",
  EquipmentLiftingGenerateWordDocumentsDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_generate_word_files.png",
  EquipmentLiftingImport: EQUIPMENTS_ICONS_PATH + "equipment_IR_import.png",
  EquipmentLiftingImportDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_import.png",
  EquipmentLiftingIscirLogo: EQUIPMENTS_ICONS_PATH + "equipment_IR_iscir_logo.png",
  EquipmentLiftingIscirLogoDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_iscir_logo.png",
  EquipmentLiftingChainList: EQUIPMENTS_ICONS_PATH + "equipment_IR_chain_list.png",
  EquipmentLiftingChainListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_chain_list.png",
  EquipmentLiftingLifetime: EQUIPMENTS_ICONS_PATH + "equipment_IR_lifetime.png",
  EquipmentLiftingLifetimeDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_lifetime.png",
  EquipmentLiftingList: EQUIPMENTS_ICONS_PATH + "equipment_IR_list.png",
  EquipmentLiftingListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_list.png",
  EquipmentLiftingRegistration: EQUIPMENTS_ICONS_PATH + "equipment_IR_registration.png",
  EquipmentLiftingRegistrationDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_registration.png",
  EquipmentLiftingRegistration2: EQUIPMENTS_ICONS_PATH + "equipment_IR_registration_2.png",
  EquipmentLiftingRegistration2Disabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_registration_2.png",
  EquipmentLiftingReport: EQUIPMENTS_ICONS_PATH + "equipment_IR_report.png",
  EquipmentLiftingReportDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_report.png",
  EquipmentLiftingSave: EQUIPMENTS_ICONS_PATH + "equipment_IR_save.png",
  EquipmentLiftingSaveDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IR_save.png",
  EquipmentLiftingSaveAll: EQUIPMENTS_ICONS_PATH + "equipment_IR_save_all.png",
  EquipmentPressure: EQUIPMENTS_ICONS_PATH + "equipment_IP.png",
  EquipmentPressureAdd: EQUIPMENTS_ICONS_PATH + "equipment_IP_add.png",
  EquipmentPressureAddDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_add.png",
  EquipmentPressureAddValve: EQUIPMENTS_ICONS_PATH + "equipment_IP_add_security_valve.png",
  EquipmentPressureExport: EQUIPMENTS_ICONS_PATH + "equipment_IP_export.png",
  EquipmentPressureExportDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_export.png",
  EquipmentPressureImport: EQUIPMENTS_ICONS_PATH + "equipment_IP_import.png",
  EquipmentPressureImportDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_import.png",
  EquipmentPressureList: EQUIPMENTS_ICONS_PATH + "equipment_IP_list.png",
  EquipmentPressureListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_list.png",
  EquipmentPressureSafetyValveList: EQUIPMENTS_ICONS_PATH + "equipment_IP_safety_valve_list.png",
  EquipmentPressureSafetyValveListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_safety_valve_list.png",
  EquipmentPressureRegistration: EQUIPMENTS_ICONS_PATH + "equipment_IP_registration.png",
  EquipmentPressureRegistrationDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_registration.png",
  EquipmentPressureSave: EQUIPMENTS_ICONS_PATH + "equipment_IP_save.png",
  EquipmentPressureSaveDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_IP_save.png",
  EquipmentPressureSaveAll: EQUIPMENTS_ICONS_PATH + "equipment_IP_save_all.png",
  EquipmentScrapList: EQUIPMENTS_ICONS_PATH + "equipment_scrap_list.png",
  EquipmentScrapListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_scrap_list.png",
  EquipmentTransferList: EQUIPMENTS_ICONS_PATH + "equipment_transfer_list.png",
  EquipmentTransferListDisabled: EQUIPMENTS_DISABLED_ICONS_PATH + "equipment_transfer_list.png",
  IscirCompanyAdd: ISCIR_COMPANIES_ICONS_PATH + "companies_ISCIR_add.png",
  IscirCompanyAddDisabled: ISCIR_COMPANIES_DISABLED_ICONS_PATH + "companies_ISCIR_add.png",
  IscirCompanyAuthorisationAdd: ISCIR_COMPANIES_ICONS_PATH + "companies_ISCIR_authorization_add.png",
  IscirCompanyImport: ISCIR_COMPANIES_ICONS_PATH + "companies_ISCIR_import.png",
  IscirCompanyImportDisabled: ISCIR_COMPANIES_DISABLED_ICONS_PATH + "companies_ISCIR_import.png",
  IscirCompanyList: ISCIR_COMPANIES_ICONS_PATH + "companies_ISCIR_list.png",
  IscirCompanyListDisabled: ISCIR_COMPANIES_DISABLED_ICONS_PATH + "companies_ISCIR_list.png",
  IscirCompanySave: ISCIR_COMPANIES_ICONS_PATH + "companies_ISCIR_save.png",
  IscirCompanySaveDisabled: ISCIR_COMPANIES_DISABLED_ICONS_PATH + "companies_ISCIR_save.png",
  IscirPersonAdd: ISCIR_PERSONS_ICONS_PATH + "persons_ISCIR_add.png",
  IscirPersonAddDisabled: ISCIR_PERSONS_DISABLED_ICONS_PATH + "persons_ISCIR_add.png",
  IscirPersonList: ISCIR_PERSONS_ICONS_PATH + "persons_ISCIR_list.png",
  IscirPersonListDisabled: ISCIR_PERSONS_DISABLED_ICONS_PATH + "persons_ISCIR_list.png",
  IscirPersonSave: ISCIR_PERSONS_ICONS_PATH + "persons_ISCIR_save.png",
  IscirPersonSaveDisabled: ISCIR_PERSONS_DISABLED_ICONS_PATH + "persons_ISCIR_save.png",
  Operator: RSVTI_PERSONS_ICONS_PATH + "operator.png",
  OperatorActivate: RSVTI_PERSONS_ICONS_PATH + "operator_comeback.png",
  OperatorAdd: RSVTI_PERSONS_ICONS_PATH + "operator_add.png",
  OperatorAddDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_add.png",
  OperatorEdit: RSVTI_PERSONS_ICONS_PATH + "operator_edit.png",
  OperatorEditDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_edit.png",
  OperatorExport: RSVTI_PERSONS_ICONS_PATH + "operator_export.png",
  OperatorExportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_export.png",
  OperatorGenerateDocuments: RSVTI_PERSONS_ICONS_PATH + "operator_generate_documents.png",
  OperatorGenerateDocumentsDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_generate_documents.png",
  OperatorGenerateWordDocuments: RSVTI_PERSONS_ICONS_PATH + "operator_generate_word_files.png",
  OperatorGenerateWordDocumentsDisabled: RSVTI_PERSONS_ICONS_PATH + "operator_generate_word_files.png",
  OperatorImport: RSVTI_PERSONS_ICONS_PATH + "operator_import.png",
  OperatorImportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_import.png",
  OperatorInactivate: RSVTI_PERSONS_ICONS_PATH + "operator_inactive.png",
  OperatorIscirLogo: RSVTI_PERSONS_ICONS_PATH + "operator_iscir_logo.png",
  OperatorIscirLogoDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_iscir_logo.png",
  OperatorList: RSVTI_PERSONS_ICONS_PATH + "operator_list.png",
  OperatorListDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_list.png",
  OperatorOnHoldList: RSVTI_PERSONS_ICONS_PATH + "operator_on_hold_list.png",
  OperatorOnHoldListDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_on_hold_list.png",
  OperatorRegistration: RSVTI_PERSONS_ICONS_PATH + "operator_registration.png",
  OperatorRegistrationDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_registration.png",
  OperatorReport: RSVTI_PERSONS_ICONS_PATH + "operator_report.png",
  OperatorReportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_report.png",
  OperatorRsvtiLogo: RSVTI_PERSONS_ICONS_PATH + "operator_rsvti_logo.png",
  OperatorRsvtiLogoDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_rsvti_logo.png",
  OperatorSave: RSVTI_PERSONS_ICONS_PATH + "operator_save.png",
  OperatorSaveDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "operator_save.png",
  OperatorSaveAll: RSVTI_PERSONS_ICONS_PATH + "operator_save_all.png",
  Worker: RSVTI_PERSONS_ICONS_PATH + "worker.png",
  WorkerActivate: RSVTI_PERSONS_ICONS_PATH + "worker_comeback.png",
  WorkerAdd: RSVTI_PERSONS_ICONS_PATH + "worker_add.png",
  WorkerAddDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_add.png",
  WorkerEdit: RSVTI_PERSONS_ICONS_PATH + "worker_edit.png",
  WorkerEditDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_edit.png",
  WorkerExport: RSVTI_PERSONS_ICONS_PATH + "worker_export.png",
  WorkerExportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_export.png",
  WorkerGenerateDocuments: RSVTI_PERSONS_ICONS_PATH + "worker_generate_documents.png",
  WorkerGenerateDocumentsDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_generate_documents.png",
  WorkerGenerateWordDocuments: RSVTI_PERSONS_ICONS_PATH + "worker_generate_word_files.png",
  WorkerGenerateWordDocumentsDisabled: RSVTI_PERSONS_ICONS_PATH + "worker_generate_word_files.png",
  WorkerImport: RSVTI_PERSONS_ICONS_PATH + "worker_import.png",
  WorkerImportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_import.png",
  WorkerInactivate: RSVTI_PERSONS_ICONS_PATH + "worker_inactive.png",
  WorkerList: RSVTI_PERSONS_ICONS_PATH + "worker_list.png",
  WorkerListDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_list.png",
  WorkerOnHoldList: RSVTI_PERSONS_ICONS_PATH + "worker_on_hold_list.png",
  WorkerOnHoldListDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_on_hold_list.png",
  WorkerRegistration: RSVTI_PERSONS_ICONS_PATH + "worker_registration.png",
  WorkerRegistrationDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_registration.png",
  WorkerReport: RSVTI_PERSONS_ICONS_PATH + "worker_report.png",
  WorkerReportDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_report.png",
  WorkerRsvtiLogo: RSVTI_PERSONS_ICONS_PATH + "worker_rsvti_logo.png",
  WorkerRsvtiLogoDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_rsvti_logo.png",
  WorkerSave: RSVTI_PERSONS_ICONS_PATH + "worker_save.png",
  WorkerSaveDisabled: RSVTI_PERSONS_DISABLED_ICONS_PATH + "worker_save.png",
  WorkerSaveAll: RSVTI_PERSONS_ICONS_PATH + "worker_save_all.png",
  Delete: "Delete.png",
  Info: "Info.png",
  LogoRsvti: "logo_rsvti50.png",
  ValidationError: "24x24/validation_error_24x24.png",
};

const STRING_CONST = {
  OK: "OK",
  DA: "Da",
  NU: "Nu",
  ATENTIE: "Atenţie!",
  MANDATORY_FIELD: "mandatory_field",
  MANDATORY_FIELD_IN_GROUP: "mandatory_field_in_group",
  OPTIONAL_MANDATORY_ALL_GROUP: "optional_mandatory_all_group",
  VALIDATION_IS_MANDATORY: " este câmp obligatoriu!",
  VALIDATION_IS_MANDATORY_FOR_ADDRESS: " este câmp obligatoriu pentru adresă!",
  VALIDATION_IS_MANDATORY_FOR_BANK_DETAILS: " este câmp obligatoriu pentru detalii bancă!",
  VALIDATION_IS_MANDATORY_FOR_COMPANY_MAIN_PERSON: " este câmp obligatoriu pentru reprezentant firmă!",
  VALIDATION_IS_MANDATORY_FOR_CNCIR_CONTRACT: " este câmp obligatoriu pentru contract CNCIR!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_FIXING_REPORT: " este câmp obligatoriu pentru raport lucrări reparare!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_ISCIR_COMPANY_ASSEMBLE: " este câmp obligatoriu pentru firmă ISCIR montaj!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_ISCIR_COMPANY_FIXING: " este câmp obligatoriu pentru firmă ISCIR reparare!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_ISCIR_COMPANY_MAINTENANCE: " este câmp obligatoriu pentru firmă ISCIR întreţinere!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_ISCIR_VERIFICATION_DETAILS: " este câmp obligatoriu pentru detalii autorizaţie ISCIR!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_MAINTENANCE_REPORT: " este câmp obligatoriu pentru raport lucrări întreţinere!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_PROBE_CASA_REPORT: " este câmp obligatoriu pentru proces verbal probe de casă!",
  VALIDATION_IS_MANDATORY_FOR_EQUIPMENT_PV_DOCUMENTS: " este câmp obligatoriu pentru generare procese verbale echipamente!",
  VALIDATION_IS_MANDATORY_FOR_ID_CARD_SERIAL_NUMBER: " este câmp obligatoriu pentru număr şi serie carte de identitate!",
  VALIDATION_IS_MANDATORY_FOR_MEMBER_2: " este câmp obligatoriu pentru membru 2!",
  VALIDATION_IS_MANDATORY_FOR_MEMBER_3: " este câmp obligatoriu pentru membru 3!",
  VALIDATION_IS_MANDATORY_FOR_OPERATOR_DOCUMENTS: " este câmp obligatoriu pentru generare documente deservenţi!",
  VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT: " este câmp obligatoriu pentru talon!",
  VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION: " este câmp obligatoriu pentru reautorizare!",
  VALIDATION_IS_MANDATORY_FOR_OWNER_COMPANY: " este câmp obligatoriu pentru detalii firmă deţinătoare!",
  VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_COMPANY: " este câmp obligatoriu pentru firmă operator RSVTI!",
  VALIDATION_IS_MANDATORY_FOR_RSVTI_OPERATOR_PERSON: " este câmp obligatoriu pentru operator RSVTI!",
  VALIDATION_IS_MANDATORY_FOR_SIGNER_PERSON: " este câmp obligatoriu pentru responsabil acte!",
  VALIDATION_IS_MANDATORY_FOR_WORKER_CERTIFICATE: " este câmp obligatoriu pentru autorizare!",
  VALIDATION_IS_MANDATORY_FOR_WORKER_DOCUMENTS: " este câmp obligatoriu pentru generare documente deserveţi auxiliari!",
};

// header for a dialog
const dialogHeaderElement = (text) => {
  return (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">{text}</span>
    </div>
  );
};

// footer for a dialog
const dialogFooterContent = (text, action) => {
  return (
    <div>
      <Button label={text} icon="pi pi-check" onClick={action} autoFocus />
    </div>
  );
};

// footer for a dialog
const dialogFooterContentTwoButtons = (text1, action1, text2, action2) => {
  return (
    <div>
      <Button label={text1} icon="pi pi-check" onClick={action1} autoFocus />
      <Button label={text2} icon="pi pi-check" onClick={action2} autoFocus />
    </div>
  );
};

const doNothing = (e) => {
  e.preventDefault();
};

export { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons, doNothing };
