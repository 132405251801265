import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import Sidebar from "../../components/Sidebar";

export default function ImportIscirPersons() {
  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Importă firme ISCIR</button>
      <div className="spaceDiv" />
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
    </div>
  );
}
