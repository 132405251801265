import React from "react";

import { ICONS } from "../common/globals";

const ProcessImportErrors = (props) => {
  const { validationErrors } = props;

  console.log("aaa");
  console.log(validationErrors);
  return (
    <>
      <div className="rowdouble">
        <div className="column">
          <h3 className="labelred">Corectează următoarele erori de validare:</h3>
        </div>
        {validationErrors.fileImportStatuses.map((fileImportStatus, index) => (
          <>
            <div className="column">
              Fişierul <strong className="labelbrown">{fileImportStatus.fileName}</strong> are următoarele erori de validare:
            </div>
            {null !== fileImportStatus.infos ? (
              <>
                {fileImportStatus.infos.map((info, index) => (
                  <>
                    <div className="column">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <img className="imgdownload" src={require("../static/images/" + ICONS.ValidationError)} alt="Eroare" title="Eroare" />
                      &nbsp;&nbsp;<span className="imgdownload">{info}</span>
                    </div>
                  </>
                ))}
              </>
            ) : null}
            {null !== fileImportStatus.rowStatuses ? (
              <>
                {fileImportStatus.rowStatuses.map((rowStatus, index) => (
                  <>
                    <div className="column">&nbsp;&nbsp;Linia excel cu numărul {rowStatus.rowNumber} are următoarele erori de validare:</div>
                    {null !== rowStatus.infos ? (
                      <>
                        {rowStatus.infos.map((info, index) => (
                          <>
                            <div className="column">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <img className="imgdownload" src={require("../static/images/" + ICONS.ValidationError)} alt="Eroare" title="Eroare" />
                              &nbsp;&nbsp;<span className="imgdownload">{info}</span>
                            </div>
                          </>
                        ))}
                      </>
                    ) : null}
                    {null !== rowStatus.columnStatuses ? (
                      <>
                        {rowStatus.columnStatuses.map((columnStatus, index) => (
                          <>
                            {columnStatus.infos.map((info, index) => (
                              <div className="column">
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <img className="imgdownload" src={require("../static/images/" + ICONS.ValidationError)} alt="Eroare" title="Eroare" />
                                &nbsp;&nbsp;<span className="imgdownload">{info}</span>
                              </div>
                            ))}
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                ))}
              </>
            ) : null}
          </>
        ))}
      </div>
    </>
  );
};

export default ProcessImportErrors;
