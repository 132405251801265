// prime core css
import "primereact/resources/primereact.min.css";
// prime theme css
import "primereact/resources/themes/nova/theme.css";
// custom css
import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, doNothing } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import FormSelectMultiple from "../../components/FormSelectMultiple";

export default function Reports() {
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const branchListURL = BASE_URL + "company/listsimplebranches";
  const equipmentAuthorizedURL = BASE_URL + "equipment/report/authorized";
  const equipmentUnauthorizedURL = BASE_URL + "equipment/report/unauthorized";
  const equipmentOnHoldURL = BASE_URL + "equipment/report/onhold";
  const equipmentExpiredNextDaysURL = BASE_URL + "equipment/report/expired/nextdays";
  const equipmentExpiredNextMonthURL = BASE_URL + "equipment/report/expired/nextmonth";
  const equipmentExpiredNextThreeMonthsURL = BASE_URL + "equipment/report/expired/next3months";
  const equipmentExpiredNextYearURL = BASE_URL + "equipment/report/expired/nextyear";
  const equipmentExpiredCustomURL = BASE_URL + "equipment/report/expired/custom";
  const operatorAuthorizedURL = BASE_URL + "operator/report/authorized";
  const operatorUnauthorizedURL = BASE_URL + "operator/report/unauthorized";
  const operatorOnHoldURL = BASE_URL + "operator/report/onhold";
  const operatorExpiredNextDaysURL = BASE_URL + "operator/report/expired/nextdays";
  const operatorExpiredNextMonthURL = BASE_URL + "operator/report/expired/nextmonth";
  const operatorExpiredNextThreeMonthsURL = BASE_URL + "operator/report/expired/next3months";
  const operatorExpiredNextYearURL = BASE_URL + "operator/report/expired/nextyear";
  const operatorExpiredCustomURL = BASE_URL + "operator/report/expired/custom";
  const workerAuthorizedURL = BASE_URL + "worker/report/authorized";
  const workerUnauthorizedURL = BASE_URL + "worker/report/unauthorized";
  const workerOnHoldURL = BASE_URL + "worker/report/onhold";
  const workerExpiredNextDaysURL = BASE_URL + "worker/report/expired/nextdays";
  const workerExpiredNextMonthURL = BASE_URL + "worker/report/expired/nextmonth";
  const workerExpiredNextThreeMonthsURL = BASE_URL + "worker/report/expired/next3months";
  const workerExpiredNextYearURL = BASE_URL + "worker/report/expired/nextyear";
  const workerExpiredCustomURL = BASE_URL + "worker/report/expired/custom";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [ddOptionsBranches, setDdOptionsBranches] = useState([]);

  const [values, setValues] = useState({
    companyUuid: "",
    branchUuid: [],
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Alege firmă de lucru",
      label: "Alege firmă de lucru",
    },
    {
      id: 1,
      name: "branchUuid",
      type: "selectClass",
      placeholder: "Alege punct de lucru",
      label: "Alege punct de lucru",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid, branchUuid: [] });
          loadBranches(mainCompanySessionUuid);
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCompany = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, branchUuid: [] });
    loadBranches(e.target.value);
  };

  const loadBranches = (uuid) => {
    axios
      .post(
        branchListURL,
        { uuid: uuid },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        setDdOptionsBranches(response.data);
      })
      .catch((error) => {
        console.log("Error loading branches");
        console.log(error);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const generateReport = (URL_VALUE, e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Raport.docx";
    axios
      .post(
        URL_VALUE,
        { companyUuid: values.companyUuid, branchUuid: values.branchUuid },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        link.href = URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
        );
        link.click();
      })
      .catch((error) => {
        console.log("Error generating report");
        console.log(error);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Rapoarte</button>
      <div className="spaceDiv" />
      <div className="rowdouble">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChangeCompany}
          />
        </div>
      </div>
      <div className="rowdouble">
        <div className="column">
          <FormSelectMultiple
            key={inputs[1].id}
            name={inputs[1].name}
            label={inputs[1].label}
            value={values[inputs[1].name]}
            ddOptions={ddOptionsBranches}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="rowdouble">
        <div className="column">
          <h3>Rapoarte echipamente și personal</h3>
          <table className="tableReport">
            <tbody>
              <tr>
                <td className="columnReport">Nr. crt</td>
                <td className="columnReport" width="350px">
                  Perioadă raport
                </td>
                <td className="columnReport">
                  <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingIscirLogo)} alt="Echipamente" title="Echipamente" />
                </td>
                <td className="columnReport">
                  <img src={require("../../static/images/buttons/" + ICONS.OperatorIscirLogo)} alt="Deservenți" title="Deservenți" />
                </td>
                <td className="columnReport">
                  <img
                    src={require("../../static/images/buttons/" + ICONS.WorkerRsvtiLogo)}
                    alt="Deservenți auxiliari"
                    title="Deservenți auxiliari"
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">1</td>
                <td className="columnReport">Echipamente în funcțiune și personal ISCIR autorizat</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentAuthorizedURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorAuthorizedURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerAuthorizedURL, e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">2</td>
                <td className="columnReport">Echipamente în conservare și personal ISCIR inactiv</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentOnHoldURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorOnHoldURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerOnHoldURL, e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">3</td>
                <td className="columnReport">Echipamente neautorizate și personal ISCIR neautorizat</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentUnauthorizedURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorUnauthorizedURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerUnauthorizedURL, e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="rowdouble">
        <div className="column">
          <h3>Scadență echipamente și personal</h3>
          <table className="tableReport">
            <tbody>
              <tr>
                <td className="columnReport">Nr. crt</td>
                <td className="columnReport" width="350px">
                  Perioadă raport
                </td>
                <td className="columnReport">
                  <img
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingIscirLogo)}
                    alt="autorizaţie echipament"
                    title="autorizaţie echipament"
                  />
                </td>
                <td className="columnReport">
                  <img
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingLifetime)}
                    alt="durată viaţă echipament"
                    title="durată viaţă echipament"
                  />
                </td>
                <td className="columnReport">
                  <img src={require("../../static/images/buttons/" + ICONS.OperatorIscirLogo)} alt="talon deservent" title="talon deservent" />
                </td>
                <td className="columnReport">
                  <img src={require("../../static/images/buttons/" + ICONS.OperatorRsvtiLogo)} alt="viză deservent" title="viză deservent" />
                </td>
                <td className="columnReport">
                  <img
                    src={require("../../static/images/buttons/" + ICONS.WorkerRsvtiLogo)}
                    alt="adeverinţă deservent auxiliar"
                    title="adeverinţă deservent auxiliar"
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">1</td>
                <td className="columnReport">următoarele 30 zile</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentExpiredNextDaysURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={doNothing}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextDaysURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextDaysURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerExpiredNextDaysURL, e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">2</td>
                <td className="columnReport">luna următoare</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentExpiredNextMonthURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={doNothing}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextMonthURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextMonthURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerExpiredNextMonthURL, e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">3</td>
                <td className="columnReport">următoarele 3 luni</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentExpiredNextThreeMonthsURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={doNothing}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextThreeMonthsURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextThreeMonthsURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerExpiredNextThreeMonthsURL, e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="columnReport">4</td>
                <td className="columnReport">anul viitor</td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={(e) => generateReport(equipmentExpiredNextYearURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingReport)}
                    alt="Raport echipamente"
                    title="Raport echipamente"
                    onClick={doNothing}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextYearURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.OperatorReport)}
                    alt="Raport deservenți"
                    title="Raport deservenți"
                    onClick={(e) => generateReport(operatorExpiredNextYearURL, e)}
                  />
                </td>
                <td className="columnReport">
                  <img
                    className="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.WorkerReport)}
                    alt="Raport deservenți auxiliari"
                    title="Raport deservenți auxiliari"
                    onClick={(e) => generateReport(workerExpiredNextYearURL, e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
