import "./RegisterUser.css";

import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL } from "../common/globals";
import useAuth from "../hooks/useAuth";
import Footer from "../public/Footer";
import Header from "../public/Header";

const LoginUser = () => {
  const LOGIN_URL = BASE_URL + "authenticate/login";

  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL, {
        username: user,
        password: pwd,
      });
      console.log(JSON.stringify(response?.data));
      const token = response?.data?.token;
      const roles = [response?.data?.role];
      setAuth({ user, roles, token });
      setUser("");
      setPwd("");
      navigate("/client/statistics");
    } catch (err) {
      alert("error");
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <section>
          <h1>You are logged in!</h1>
          <br />
          <p>
            <Link to={"/#"}>Pagina principală</Link>
          </p>
        </section>
      ) : (
        <div>
          <Header />
          <div className="tothemiddle">
            <br />
            <div>
              <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_007.jpg")} />
            </div>
            <br />
            <br />
            <br />

            <div>
              <form className="sz-form" onSubmit={handleSubmit}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                  {errMsg}
                </p>
                <h1 className="font-form-color">Conectare în MyRSVTI</h1>
                <br />
                <label className="font-form-color" htmlFor="username">
                  E-mail:
                </label>
                <br />
                <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
                <br />
                <br />
                <label className="font-form-color" htmlFor="password">
                  Parola:
                </label>
                <br />
                <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                <br />
                <br />
                <br />
                <button>Autentificare</button>
                {/* <p>
              <br />
              <br />
              Nu ai cont?
              <br />
              <br />
              <span className="line">
                <Link to={"/public/registerclient"}>Creează cont</Link>
                <br />
                <br />
                <Link to={"/#"}>Pagina principală</Link>
              </span>
            </p> */}
              </form>
            </div>
            <br />
            <br />
            <br />
            <div>
              <div className="surface-0">
                <div className="text-900 font-bold text-4xl mb-4 text-center">Cont demo conectare în aplicație</div>
                {/* <div className="text-700 font-bold text-4xl mb-4 text-center">Free account to log in to the app</div> */}
                {/* <div className="text-500 font-bold text-4xl mb-4 text-center">Compte gratuit pour se connecter à l'application</div> */}

                <div className="grid">
                  <div className="col-12 lg:col-4">
                    {/* <div className="p-3 h-full">
                      <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                        <div className="text-900 font-medium text-xl mb-2">Demo account</div>
                        <div className="text-600">(login information)</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                          <span className="ml-2 font-medium text-600">e-mail:</span>
                          <span className="font-bold text-2xl text-900">&nbsp; demo_en@myrsvti.ro </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                        <span className="ml-2 font-medium text-600">parola:</span>
                        <span className="font-bold text-2xl text-900">&nbsp; demo </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 lg:col-4">
                    <div className="p-3 h-full">
                      <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                        <div className="text-900 font-medium text-xl mb-2">Cont demonstrativ</div>
                        <div className="text-600">(informații conectare)</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                          <span className="ml-2 font-medium text-600">e-mail:</span>
                          <span className="font-bold text-2xl text-900">&nbsp; demo@myrsvti.ro </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                          <span className="ml-2 font-medium text-600">parola:</span>
                          <span className="font-bold text-2xl text-900">&nbsp; demo </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 lg:col-4">
                    {/* <div className="p-3 h-full">
                      <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                        <div className="text-900 font-medium text-xl mb-2">Compte démo</div>
                        <div className="text-600">(informations de connexion)</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                          <span className="ml-2 font-medium text-600">e-mail:</span>
                          <span className="font-bold text-2xl text-900">&nbsp; demo_fr@myrsvti.ro </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                        <span className="ml-2 font-medium text-600">parola:</span>
                        <span className="font-bold text-2xl text-900">&nbsp; demo </span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="surface-0 it-font text-700">
                <br />
                <div className="text-xl mb-6 text-justify line-height-3 pmarg">
                  <span className="bd-it-font">Observații:</span>
                  <br />
                  <ul className="text-700 text-xl mb-6 blt-marg black-bullet">
                    <li>
                      Contul demo este creat cu caracter informativ și demonstrativ. Utilizarea acestui cont nu presupune eventuale obligații
                      contractuale și financiare ulterioare.
                    </li>
                    <li>În contul demo nu se pot introduce, adăuga sau modifica date legate de firme, echipamente și personal ISCIR.</li>
                    <li>Contul demo conține două firme, una cu un singur punct de lucru și a doua cu puncte de lucru în toate județele țării. Cele două firme, precum si toate datele privind punctele de lucru, persoanele ISCIR și echipamente/instalații ISCIR nu sunt reale.
                    Orice asemănare cu datele unor persoane reale sau echipamente deținute în mod real de eventuale firme este pur întâmplătoare.
                    </li>
                    <li>Deoarece contul demo este public, pentru respectarea cerințelor privind protecția datelor personale prevăzute în Regulamentul nr. 679 din 27 aprilie 2016 privind protecţia persoanelor fizice în
                    ceea ce priveşte prelucrarea datelor cu caracter personal, anumite facilități ale aplicației nu sunt posibile.
                    </li>
                    <li>Cu toate acestea, pentru generarea unor documente word permise totuși din contul demo, se recomandă a nu se introduce date reale pentru generarea acestora. Utilizatorul este singurul răspunzător pentru datele introduse de el în contul demo. 
                      Informațiile introduse în contul demo vor fi șterse periodic de către administratorul aplicației, dar până la efectuarea operațiilor de ștergere, aceste informații vor fi disponibile tuturor persoanelor care vor accesa contul demo.
                    </li>
                    <li>Documente word emise pentru deservenți ISCIR în contul demo - doar stivuitorist.</li>
                    <li>Documente word emise pentru deservenți auxiliari ISCIR în contul demo - doar manevrant mecanism de ridicat, tip transpaletă.</li>
                    <li>Documente word emise pentru echipamente/instalații ISCIR în contul demo - doar stivuitoare.</li>
                    <li>Documente word emise pentru echipamente/instalații interne în contul demo - doar mecanisme de ridicat, tip transpaletă.</li>
                    <li>
                      Rapoartele sunt emise pentru toate tipurile de personal și echipamente/instalații înregistrate în contul demo pentru
                      cele două firme.
                    </li>
                    <li>Pentru orice informații suplimentare ne puteți contacta telefonic (0753012324) sau prin e-mail (office@asisco.ro).</li>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default LoginUser;
