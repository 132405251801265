// prime core css
import "primereact/resources/primereact.min.css";
// prime theme css
import "primereact/resources/themes/nova/theme.css";
// custom css
import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AllowedClassifications() {
  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Clasificări permise</button>
      <Link to={"/help/importtemplates"}>
        <button className="buttonClassGray">Definiții fișiere import</button>
      </Link>
      <Link to={"/help/iconslegend"}>
        <button className="buttonClassGray">Legendă iconițe</button>
      </Link>
    </div>
  );
}
