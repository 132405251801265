import "./App.css";

import { Routes, Route } from "react-router-dom";

// import public routes
import Contact from "./public/Contact";
import Footer from "./public/Footer";
import Header from "./public/Header";
import Layout from "./public/Layout";
import MainPage from "./public/MainPage";
import Missing from "./public/Missing";
import Prices from "./public/Prices";
import Documents from "./public/Documents";
import IrOperators from "./public/IrOperators";
import IpOperators from "./public/IpOperators";
import IrWorkers from "./public/IrWorkers";
import IrEquipments from "./public/IrEquipments";
import Tutorials from "./public/Tutorials";
import RequireAuth from "./public/RequireAuth";
import Terms from "./public/Terms";
import Gdpr from "./public/Gdpr";
import Portofolio from "./public/Portofolio";

// import security routes
import LoginUser from "./security/LoginUser";
import RegisterClient from "./security/RegisterClient";
import Unauthorized from "./public/Unauthorized";
import Logout from "./security/Logout";

// import rsvti routes
import AddCompany from "./rsvti/company/AddCompany";
import EditCompany from "./rsvti/company/EditCompany";
import ListCompanies from "./rsvti/company/ListCompanies";
import ListCompanySessions from "./rsvti/company/ListCompanySessions";
import AddCompanyBranch from "./rsvti/company/AddCompanyBranch";
import EditCompanyBranch from "./rsvti/company/EditCompanyBranch";
import ListCompanyBranches from "./rsvti/company/ListCompanyBranches";
import AddEquipmentPressure from "./rsvti/equipment/AddEquipmentPressure";
import AddEquipmentLifting from "./rsvti/equipment/AddEquipmentLifting";
import ListEquipments from "./rsvti/equipment/ListEquipments";
import ListOperators from "./rsvti/operator/ListOperators";
import AddOperator from "./rsvti/operator/AddOperator";
import EditOperator from "./rsvti/operator/EditOperator";
import ImportOperators from "./rsvti/operator/ImportOperators";
import ListOperatorSessions from "./rsvti/operator/ListOperatorSessions";
import AddPerson from "./rsvti/person/AddPerson";
import ListPersons from "./rsvti/person/ListPersons";
import ListWorkers from "./rsvti/worker/ListWorkers";
import AddWorker from "./rsvti/worker/AddWorker";
import ImportWorkers from "./rsvti/worker/ImportWorkers";
import ListWorkerSessions from "./rsvti/worker/ListWorkerSessions";
import WorkerGenerateDocsWorkerTypeSelection from "./rsvti/documentGeneration/WorkerGenerateDocsWorkerTypeSelection";
import WorkerGenerateDocsInstructions from "./rsvti/documentGeneration/WorkerGenerateDocsInstructions";
import WorkerGenerateDocsStatus from "./rsvti/documentGeneration/WorkerGenerateDocsStatus";
import WorkerGenerateDocsTests from "./rsvti/documentGeneration/WorkerGenerateDocsTests";
import WorkerGenerateDocsCertificates from "./rsvti/documentGeneration/WorkerGenerateDocsCertificates";
import WorkerGenerateViewDocuments from "./rsvti/documentGeneration/WorkerGenerateViewDocuments";
import OperatorGenerateDocsInstructions from "./rsvti/documentGeneration/OperatorGenerateDocsInstructions";
import OperatorGenerateDocsStatus from "./rsvti/documentGeneration/OperatorGenerateDocsStatus";
import OperatorGenerateDocsTests from "./rsvti/documentGeneration/OperatorGenerateDocsTests";
import OperatorGenerateDocsCertificates from "./rsvti/documentGeneration/OperatorGenerateDocsCertificates";
import OperatorGenerateViewDocuments from "./rsvti/documentGeneration/OperatorGenerateViewDocuments";

// import admin routes
import AddClient from "./admin/AddClient";
import ListClients from "./admin/ListClients";
import AddCncir from "./admin/AddCncir";
import ListCncir from "./admin/ListCncir";
import AddCountry from "./admin/AddCountry";
import ListCountries from "./admin/ListCountries";
import AddCounty from "./admin/AddCounty";
import ListCounties from "./admin/ListCounties";
import AddIscir from "./admin/AddIscir";
import AddTown from "./admin/AddTown";
import ListTowns from "./admin/ListTowns";
import ListIscir from "./admin/ListIscir";

// import example routes
import ApplyCustomCss from "./examples/ApplyCustomCss";
import { Overview, OverviewUsers, OverviewRevenue } from "./examples/Overview";
import EquipmentGenerateDocsList from "./rsvti/documentGeneration/EquipmentGenerateDocsList";
import ClientStatistics from "./rsvti/statistics/ClientStatistics";
import EquipmentGenerateViewDocuments from "./rsvti/documentGeneration/EquipmentGenerateViewDocuments";
import ListEquipmentSessions from "./rsvti/equipment/ListEquipmentSessions";
import EquipmentGenerateDocsOrder from "./rsvti/documentGeneration/EquipmentGenerateDocsOrder";
import EquipmentGenerateDocsPV from "./rsvti/documentGeneration/EquipmentGenerateDocsPV";
import ImportCompanies from "./rsvti/company/ImportCompanies";
import ImportCompanyBranches from "./rsvti/company/ImportCompanyBranches";
import ImportEquipments from "./rsvti/equipment/ImportEquipments";
import EditWorker from "./rsvti/worker/EditWorker";
import CompanyGenerateViewDocuments from "./rsvti/company/CompanyGenerateViewDocuments";
import EquipmentGenerateDocsRegister from "./rsvti/documentGeneration/EquipmentGenerateDocsRegister";
import IscirCompanyList from "./iscir/company/IscirCompanyList";
import AddIscirCompany from "./iscir/company/AddIscirCompany";
import AddIscirPerson from "./iscir/person/AddIscirPerson";
import IscirPersonList from "./iscir/person/IscirPersonList";
import ImportIscirCompanies from "./iscir/company/ImportIscirCompanies";
import ImportIscirPersons from "./iscir/person/ImportIscirPersons";
import ListScrapEquipments from "./rsvti/equipment/ListScrapEquipments";
import ListTransferEquipments from "./rsvti/equipment/ListTransferEquipments";
import ListOperatorsOnHold from "./rsvti/operator/ListOperatorsOnHold";
import ListWorkersOnHold from "./rsvti/worker/ListWorkersOnHold";
import ListSafetyValves from "./rsvti/equipment/ListSafetyValves";
import Reports from "./rsvti/report/Reports";
import AllowedClassifications from "./public/help/AllowedClassifications";
import ImportTemplates from "./public/help/ImportTemplates";
import IconsLegend from "./public/help/IconsLegend";
import CompanyGenerateDocs from "./rsvti/company/CompanyGenerateDocs";

const ROLES = {
  Admin: "e16cd547-60ad-11ef-8562-74563c1b28c3",
  FullUserModify: "ff94a426-60ad-11ef-8562-74563c1b28c3",
  FullUserView: "13b3c10f-60ae-11ef-8562-74563c1b28c3",
  PartialUserModify: "2980e294-60ae-11ef-8562-74563c1b28c3",
  PartialUserView: "344b1b3a-60ae-11ef-8562-74563c1b28c3",
  SuperAdmin: "6d9c923e-6937-11ef-805f-74563c1b28c3",
  Demo: "d554c39f-6937-11ef-805f-74563c1b28c3",
};

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Layout />}>
        {/*public routes - can be accessed without login*/}
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/files/footer" element={<Footer />} />
        <Route exact path="/files/header" element={<Header />} />
        <Route exact path="/public/contact" element={<Contact />} />
        <Route exact path="/public/documents" element={<Documents />} />
        <Route exact path="/public/iroperators" element={<IrOperators />} />
        <Route exact path="/public/ipoperators" element={<IpOperators />} />
        <Route exact path="/public/irworkers" element={<IrWorkers />} />
        <Route exact path="/public/irequipments" element={<IrEquipments />} />
        <Route exact path="/public/portofolio" element={<Portofolio />} />
        <Route exact path="/public/prices" element={<Prices />} />
        <Route exact path="/public/tutorials" element={<Tutorials />} />
        <Route exact path="/public/terms" element={<Terms />} />
        <Route exact path="/public/gdpr" element={<Gdpr />} />

        <Route exact path="/help/allowedclassifications" element={<AllowedClassifications />} />
        <Route exact path="/help/importtemplates" element={<ImportTemplates />} />
        <Route exact path="/help/iconslegend" element={<IconsLegend />} />

        {/*public security routes - can be accessed without login*/}
        <Route exact path="/public/loginuser" element={<LoginUser />} />
        <Route exact path="/public/logout" element={<Logout />} />
        <Route exact path="/public/registerclient" element={<RegisterClient />} />
        <Route exact path="/public/unauthorized" element={<Unauthorized />} />

        {/*write and import routes - can be accessed after login - actions that modify data*/}
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.FullUserModify, ROLES.PartialUserModify, ROLES.SuperAdmin]} />}>
          <Route exact path="/company/register" element={<AddCompany />} />
          <Route exact path="/company/edit" element={<EditCompany />} />
          <Route exact path="/company/import" element={<ImportCompanies />} />
          <Route exact path="/companyBranch/edit" element={<EditCompanyBranch />} />
          <Route exact path="/companyBranch/import" element={<ImportCompanyBranches />} />
          <Route exact path="/companyBranch/register" element={<AddCompanyBranch />} />
          <Route exact path="/equipment/addlifting" element={<AddEquipmentLifting />} />
          <Route exact path="/equipment/addpressure" element={<AddEquipmentPressure />} />
          <Route exact path="/equipment/import" element={<ImportEquipments />} />
          <Route exact path="/iscircompany/add" element={<AddIscirCompany />} />
          <Route exact path="/iscircompany/import" element={<ImportIscirCompanies />} />
          <Route exact path="/iscirperson/add" element={<AddIscirPerson />} />
          <Route exact path="/iscirperson/import" element={<ImportIscirPersons />} />
          <Route exact path="/operator/edit" element={<EditOperator />} />
          <Route exact path="/operator/import" element={<ImportOperators />} />
          <Route exact path="/operator/add" element={<AddOperator />} />
          <Route exact path="/person/add" element={<AddPerson />} />
          <Route exact path="/worker/add" element={<AddWorker />} />
          <Route exact path="/worker/edit" element={<EditWorker />} />
          <Route exact path="/worker/import" element={<ImportWorkers />} />
        </Route>

        {/*write and import routes - can be accessed after login - actions that modify data*/}
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.FullUserModify, ROLES.PartialUserModify, ROLES.SuperAdmin, ROLES.Demo]} />}>
          <Route exact path="/companydocuments/generatedocs" element={<CompanyGenerateDocs />} />
          <Route exact path="/equipmentdocuments/generatedocslistselection" element={<EquipmentGenerateDocsList />} />
          <Route exact path="/equipmentdocuments/generatedocsorderselection" element={<EquipmentGenerateDocsOrder />} />
          <Route exact path="/equipmentdocuments/generatedocspvselection" element={<EquipmentGenerateDocsPV />} />
          <Route exact path="/equipmentdocuments/generatedocsregisterselection" element={<EquipmentGenerateDocsRegister />} />
          <Route exact path="/operator/generatedocsinstructions" element={<OperatorGenerateDocsInstructions />} />
          <Route exact path="/operator/generatedocsstatus" element={<OperatorGenerateDocsStatus />} />
          <Route exact path="/operator/generatedocstests" element={<OperatorGenerateDocsTests />} />
          <Route exact path="/operator/generatedocscertificates" element={<OperatorGenerateDocsCertificates />} />
          <Route exact path="/worker/generatedocstypeselection" element={<WorkerGenerateDocsWorkerTypeSelection />} />
          <Route exact path="/worker/generatedocsinstructions" element={<WorkerGenerateDocsInstructions />} />
          <Route exact path="/worker/generatedocsstatus" element={<WorkerGenerateDocsStatus />} />
          <Route exact path="/worker/generatedocstests" element={<WorkerGenerateDocsTests />} />
          <Route exact path="/worker/generatedocscertificates" element={<WorkerGenerateDocsCertificates />} />
          <Route exact path="/worker/import" element={<ImportWorkers />} />
        </Route>

        {/*read routes - can be accessed after login - actions that only read data*/}
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.Admin,
                ROLES.FullUserModify,
                ROLES.FullUserView,
                ROLES.PartialUserModify,
                ROLES.PartialUserView,
                ROLES.Demo,
                ROLES.SuperAdmin,
              ]}
            />
          }
        >
          <Route exact path="/client/statistics" element={<ClientStatistics />} />
          <Route exact path="/company/generatedocsview" element={<CompanyGenerateViewDocuments />} />
          <Route exact path="/company/list" element={<ListCompanies />} />
          <Route exact path="/company/sessions" element={<ListCompanySessions />} />
          <Route exact path="/companyBranch/list" element={<ListCompanyBranches />} />
          <Route exact path="/equipment/list" element={<ListEquipments />} />
          <Route exact path="/equipment/listscrap" element={<ListScrapEquipments />} />
          <Route exact path="/equipment/listsafetyvalves" element={<ListSafetyValves />} />
          <Route exact path="/equipment/listtransfer" element={<ListTransferEquipments />} />
          <Route exact path="/equipment/sessions" element={<ListEquipmentSessions />} />
          <Route exact path="/equipmentdocuments/generatedocsview" element={<EquipmentGenerateViewDocuments />} />
          <Route exact path="/iscircompany/list" element={<IscirCompanyList />} />
          <Route exact path="/iscirperson/list" element={<IscirPersonList />} />
          <Route exact path="/operator/list" element={<ListOperators />} />
          <Route exact path="/operator/onholdlist" element={<ListOperatorsOnHold />} />
          <Route exact path="/operator/sessions" element={<ListOperatorSessions />} />
          <Route exact path="/person/list" element={<ListPersons />} />
          <Route exact path="/report/reports" element={<Reports />} />
          <Route exact path="/worker/list" element={<ListWorkers />} />
          <Route exact path="/worker/listonhold" element={<ListWorkersOnHold />} />
          <Route exact path="/worker/sessions" element={<ListWorkerSessions />} />
          <Route exact path="/worker/generatedocsview" element={<WorkerGenerateViewDocuments />} />
          <Route exact path="/operator/generatedocsview" element={<OperatorGenerateViewDocuments />} />
        </Route>

        {/*super admin routes -  - can be accessed after login - actions that modify application data*/}
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
          <Route exact path="/client/add" element={<AddClient />} />
          <Route exact path="/client/list" element={<ListClients />} />
          <Route exact path="/cncir/add" element={<AddCncir />} />
          <Route exact path="/cncir/list" element={<ListCncir />} />
          <Route exact path="/country/add" element={<AddCountry />} />
          <Route exact path="/country/list" element={<ListCountries />} />
          <Route exact path="/county/add" element={<AddCounty />} />
          <Route exact path="/county/list" element={<ListCounties />} />
          <Route exact path="/iscir/add" element={<AddIscir />} />
          <Route exact path="/iscir/list" element={<ListIscir />} />
          <Route exact path="/town/add" element={<AddTown />} />
          <Route exact path="/town/list" element={<ListTowns />} />
        </Route>

        {/*examples routes*/}
        <Route exact path="/overview" element={<Overview />} />
        <Route exact path="/overview/users" element={<OverviewUsers />} />
        <Route exact path="/overview/revenue" element={<OverviewRevenue />} />
        <Route exact path="/tests/applycustomcss" element={<ApplyCustomCss />} />
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
