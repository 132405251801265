import "../static/css/pageLayout.css";

import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Link } from "react-router-dom";

import { BASE_URL } from "../common/globals";
import Sidebar from "../components/Sidebar";
import useAuth from "../hooks/useAuth";

const baseURL = BASE_URL + "county/list";

const columns = [
  {
    field: "uuid",
    headerName: "UUID",
    width: 300,
    editable: true,
  },
  {
    field: "value",
    headerName: "Nume Judet",
    width: 150,
    editable: false,
  },
  {
    field: "relationUUID",
    headerName: "UUID",
    width: 300,
    editable: true,
  },
  {
    field: "relationValue",
    headerName: "Tara",
    width: 150,
    editable: false,
  },
];

export default function ListCounties() {
  const [post, setPost] = React.useState(null);

  const { auth } = useAuth();

  React.useEffect(() => {
    axios
      .get(baseURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setPost(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!post)
    return (
      <div className="clientArea">
        <Sidebar />
        <h1>No Post</h1>
      </div>
    );

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Lista judete</button>
      <Link to={"/county/add"}>
        <button className="buttonClassGray">Adauga Judet</button>
      </Link>
      <button className="buttonClassGray">Import judete</button>
      <div className="spaceDiv" />
      <Box sx={{ height: 500, width: "90%" }}>
        <DataGrid
          getRowId={(row) => row.uuid}
          rows={post}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
}
