import "../../static/css/pageLayout.css";

import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AddIscirPerson() {
  const baseURL = BASE_URL + "iscircompanyperson/add";
  const companyListURL = BASE_URL + "iscircompany/all";
  const personTypesURL = BASE_URL + "structure/iscircompany/person/types";

  const [ddOptionsIscirCompany, setDdOptionsIscirCompany] = useState([]);
  const [ddOptionsPersonType, setDdOptionsPersonType] = useState([]);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);

  const { auth } = useAuth();

  const [values, setValues] = useState({
    iscirCompanyUuid: "",
    lastName: "",
    firstName: "",
    phone: "",
    email: "",
  });

  const inputs = [
    {
      id: 0,
      name: "iscirCompanyUuid",
      type: "selectClass",
      placeholder: "Firmă ISCIR",
      label: "Firmă ISCIR",
    },
    {
      id: 1,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie",
      label: "Nume familie",
    },
    {
      id: 2,
      name: "firstName",
      type: "text",
      placeholder: "Prenume",
      label: "Prenume",
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "Telefon",
      label: "Telefon",
    },
    {
      id: 4,
      name: "email",
      type: "text",
      placeholder: "Email",
      label: "Email",
    },
  ];

  // load iscir companies
  useEffect(() => {
    axios
      .post(
        companyListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsIscirCompany(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        personTypesURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsPersonType(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    let data = [];
    for (let i = 0; i < ddOptionsPersonType.length; i++) {
      if (values["type" + ddOptionsPersonType[i].value] === ddOptionsPersonType[i].uuid) {
        let newfield = {
          typeUuid: ddOptionsPersonType[i].uuid,
          phone: values["phone" + ddOptionsPersonType[i].value],
          email: values["email" + ddOptionsPersonType[i].value],
          authorisation: values["authorisation" + ddOptionsPersonType[i].value],
          authorisationIssuedDate: values["authorisationIssuedDate" + ddOptionsPersonType[i].value],
        };
        data = [...data, newfield];
      }
    }
    axios
      .post(
        baseURL,
        {
          iscirCompanyUuid: values.iscirCompanyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          phone: values.phone,
          email: values.email,
          types: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        setProcessingDialog(false);
        setProcessedDialog(true);
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e, suffix) => {
    let data = {};
    data["phone" + suffix] = "";
    data["email" + suffix] = "";
    data["authorisation" + suffix] = "";
    data["authorisationIssuedDate" + suffix] = "";
    if (e.target.checked) {
      setValues({ ...values, ...data, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, ...data, [e.target.name]: "" });
    }
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Adaugă persoană ISCIR</button>
      <div className="spaceDiv" />
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAdd)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAddDisabled)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      <form className="formArea">
        <div className="rowdouble">
          <div className="row">
            <div className="column">
              <FormSelect
                key={inputs[0].id}
                name={inputs[0].name}
                label={inputs[0].label}
                value={values[inputs[0].name]}
                ddOptions={ddOptionsIscirCompany}
                onChange={onChange}
              />
            </div>
            <div className="column">
              <p>&nbsp;</p>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
            </div>
            <div className="column">
              <FormInput key={inputs[2].id} {...inputs[2]} value={values[inputs[2].name]} onChange={onChange} />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <FormInput key={inputs[3].id} {...inputs[3]} value={values[inputs[3].name]} onChange={onChange} />
            </div>
            <div className="column">
              <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
            </div>
          </div>

          {ddOptionsPersonType.map((personType, index) => (
            <>
              <div className="row">
                <div className="column">
                  <Checkbox
                    key={personType.uuid}
                    inputId={personType.value}
                    name={"type" + personType.value}
                    value={personType.uuid}
                    onChange={(e) => {
                      onChangeCheckBox(e, personType.value);
                    }}
                    checked={values["type" + personType.value] === personType.uuid}
                  />
                  <label htmlFor={personType.value} className="flex align-items-center">
                    &nbsp;&nbsp;{personType.value}
                  </label>
                </div>
                <div className="column"></div>
              </div>
              {values["type" + personType.value] === personType.uuid ? (
                <>
                  <div className="row">
                    <div className="column">
                      <FormInput
                        key={personType.uuid + "phone"}
                        name={"phone" + personType.value}
                        value={values["phone" + personType.value]}
                        onChange={onChange}
                        placeholder="Telefon"
                        label="Telefon"
                        type="text"
                      />
                    </div>
                    <div className="column">
                      <FormInput
                        key={personType.uuid + "email"}
                        name={"email" + personType.value}
                        value={values["email" + personType.value]}
                        onChange={onChange}
                        placeholder="Email"
                        label="Email"
                        type="text"
                      />
                    </div>
                  </div>
                  {"a0a5cb74-175a-11ef-b2d9-74563c1b28c3" === personType.uuid || "de848ff1-1564-11ef-8523-74563c1b28c3" === personType.uuid ? (
                    <div className="row">
                      <div className="column">
                        <FormInput
                          key={personType.uuid + "authorisation"}
                          name={"authorisation" + personType.value}
                          value={values["authorisation" + personType.value]}
                          onChange={onChange}
                          placeholder="Atestat"
                          label="Atestat"
                          type="text"
                        />
                      </div>
                      <div className="column">
                        <FormInput
                          key={personType.uuid + "authorisationIssuedDate"}
                          name={"authorisationIssuedDate" + personType.value}
                          value={values["authorisationIssuedDate" + personType.value]}
                          onChange={onChange}
                          placeholder="Data eliberare/reautorizare atestat"
                          label="Data eliberare/reautorizare atestat"
                          type="date"
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
        </div>

        {processingDialog !== true ? (
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.IscirPersonSave)}
                alt="Salvează echipament"
                title="Salvează echipament"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </form>
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAdd)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAddDisabled)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
