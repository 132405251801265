import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function IrOperators() {
    return (
        <div className="IrOperators">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_015.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Deservenți instalații de ridicat</div>

                    <div className="pmarg">
                        <div className="card">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Macaragiu">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/TMOQfeau344?si=G5WNJNyEw8VlVKVJ"
                                                                    title="Clasificare macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare elemente fixare sarcină</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/0gp-XXNwubA?si=w403QvaVdqNBFohN"
                                                                    title="Clasificare elemente de legare și prindere sarcină"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Comenzi macara Liebherr</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/9mGNQA1Ejh4?si=qyNjpFGXeFbOSoLh"
                                                                    title="Comenzi macara Liebherr"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații macaragiu</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/XoLfpl-7mWQ?si=eIS1-76FMICQZhL1"
                                                                    title="Obligații macaragiu"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Responsabilități macaragiu</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZyoKUbUnosY?si=oN2xwlr8uWeB0d5G"
                                                                    title="Responsabilități macaragiu"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/f3qGASV4_VE?si=oKhM4_DiNthTkbd7"
                                                                    title="Legislație macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Codul de semnalizare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/nr1u8jo9pR0?si=Sr72F9mQP5aJ0s2v"
                                                                    title="Codul de semnalizare, conform R1-2010"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Codul de semnalizare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/EhxZ7uRp1YE?si=ech2sfdembrD_RtS"
                                                                    title="Codul de semnalizare, conform HG 971"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Accidente macarale</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/OQk6KsTGqYM?si=nRthHXI0F6ep5D3S"
                                                                    title="Accidente macarale"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                            </div> 

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Stivuitorist">
                                    <p className="m-0">
                                        
                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare stivuitoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/7Vu5CK4XZpM?si=x0WoRQd6ytJKEqZp"
                                                                    title="Clasificare stivuitoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Prezentare stivuitoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/2g5Ao7KrxJk?si=3CTmWHOTLR9Pczsv"
                                                                    title="Prezentare stivuitoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Instrucțiuni stivuitoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/0m2s6sq36YM?si=Yuih-FWmMIISjAsj"
                                                                    title="Instrucțiuni stivuitoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații stivuitorist</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/tyKBSV8FbDM?si=fHiEmlNplrK5Fa1W"
                                                                    title="Obligații stivuitorist"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Model test stivuitorist</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/kTg3iM01pEE?si=ycOm7I3z11lRHOwp"
                                                                    title="Model test stivuitorist"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație stivuitoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/geZ2qqlBzaQ?si=uDsXlYnpsk9Lsdum"
                                                                    title="Legislație stivuitoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Accidente stivuitoare</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/z7a_w-AC15I?si=J0Fs5p0sNTyyu9eF"
                                                                    title="Accidente stivuitoare"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>






                                                
                                            </div> 

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                        <div className="surface-0">
                                            <div className="grid">


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                            </div> 

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Liftier">
                                    <p className="m-0">
                                        
                                        <div className="surface-0">
                                            <div className="grid">

                                            <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                            </div> 

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Mecanic-trolist">
                                    <p className="m-0">
                                        
                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare instalații transport cablu</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/qDBXbQSPfHE?si=zNBecCk9pXOgNNQX"
                                                                    title="Clasificare instalații transport cablu"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">



                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                
                                            </div> 

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>


                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>


                </div>




            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
