import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, STRING_CONST, dialogHeaderElement, dialogFooterContentTwoButtons, doNothing } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListSafetyValves() {
  const baseURL = BASE_URL + "equipment/list/safetyvalve";
  const deleteURL = BASE_URL + "equipment/deletesafetyvalves";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  // authentication details
  const { auth } = useAuth();

  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadPersons(e.target.value);
  };

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load persons
  const loadPersons = (uuid) => {
    axios
      .post(
        baseURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading persons");
        console.log(error);
        alert("Angajaţii nu au putut fi încărcaţi!");
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Concediază"
                  title="Concediază"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting person");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  if (!list)
    return (
      <div className="listpersons">
        <Sidebar />
        <h1>Informaţiile se incarcă!</h1>
      </div>
    );

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Listă supape de siguranta</button>
      <div className="spaceDiv" />
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentPressureSafetyValveListDisabled)}
        alt="Listă supape de siguranţă"
        title="Listă supape de siguranţă"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExport)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <div className="cardDropDownBig">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="spaceDiv" />
      <div className="cardBig">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="companyCode" sortable header="Cod companie"></Column>
          <Column field="equipmentModel" sortable header="Model echipament"></Column>
          <Column field="equipmentSeries" sortable header="Serie echipament"></Column>
          <Column field="type" sortable header="Tip valva"></Column>
          <Column field="series" sortable header="Serie valva"></Column>
          <Column field="verificationNumber" sortable header="Numar verificare"></Column>
          <Column field="verificationDate" sortable header="Data verificare"></Column>
          <Column field="pressure" sortable header="Presiune"></Column>
          <Column field="company" sortable header="Companie care a verificat"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentPressureSafetyValveListDisabled)}
        alt="Listă supape de siguranţă"
        title="Listă supape de siguranţă"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExport)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContentTwoButtons(STRING_CONST.DA, closeDeleteDialogAndDelete, STRING_CONST.NU, closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi aceasta supapă: {null != selectedRow ? selectedRow.type + " - " + selectedRow.series : ""} ?</p>
      </Dialog>
    </div>
  );
}
