import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function IpOperators() {
    return (
        <div className="IpOperators">
            <Header />

            <main>
                <div className="surface-0">
                    <br />
                    <br />
                    <div>
                        <img alt="" className="img-all" src={require("../static/images/1920x400/bckg_1920x400_017.jpg")} />
                    </div>
                    <br />
                </div>

                <div>
                    <br />
                    <br />
                </div>

                <div className="surface-0">

                    <div className="text-900 font-bold text-6xl mb-4 text-left pmarg">Deservenți instalații sub presiune</div>

                    <div className="pmarg">
                        <div className="card">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Fochist">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/hPaO_t38-go?si=gosGjwYmyyeZYBaA"
                                                                    title="Clasificare cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații fochist (PT C1-2010)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/AtH2yWx3X8g?si=HTtfBo4UxsfmJI2_"
                                                                    title="Obligații fochist (PT C1-2010)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații fochist (PT C9-2010)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/X87S6kZIXCQ?si=E6vUDmegss9fbrEg"
                                                                    title="Obligații fochist (PT C9-2010)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Fbxh9Jc8iW4?si=P3Yuahm5e2GRpO9S"
                                                                    title="Legislație cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">


                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Automatist pentru supraveghere și întreținere cazane">
                                    <p className="m-0">

                                        <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/hPaO_t38-go?si=gosGjwYmyyeZYBaA"
                                                                    title="Clasificare cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații automatist cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZH-m6ITcnMg?si=-cYyR6VblNLHUvuG"
                                                                    title="Obligații automatist cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Fbxh9Jc8iW4?si=P3Yuahm5e2GRpO9S"
                                                                    title="Legislație cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                        <div className="surface-0">
                                            <div className="grid">

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Îmbuteliator fluide sub presiune">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">


                                            <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare butelii</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/mNsclohd86M?si=1CRTusWxX9tRm5T1"
                                                                    title="Clasificare butelii"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație butelii</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/d4DuvLI-QVQ?si=60Za6qmc2cBcxUir"
                                                                    title="Legislație butelii"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație instalații GNCV</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/jQmGILuChjk?si=BywozW9BvA30aVri"
                                                                    title="Legislație instalații GNCV"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">


                                            <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații îmbuteliator fluide sub presiune (PT C3-2012)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/7vHJzKUi6uM?si=c2xXi9CLhFrP8KjV"
                                                                    title="Obligații îmbuteliator fluide sub presiune (C3)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații îmbuteliator fluide sub presiune (PT C5-2003)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/FaQZ7DjMOy4?si=7w_BZbw_h3YkS8p0"
                                                                    title="Obligații îmbuteliator fluide sub presiune (C5)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații îmbuteliator fluide sub presiune (PT C14-2021)</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/FztbLwNiibg?si=8MHTOb-C67grpmzC"
                                                                    title="Obligații îmbuteliator fluide sub presiune (C14)"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>



                                        <div className="surface-0">
                                            <div className="grid">

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Laborant operator centrale termice">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">
                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Clasificare cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/hPaO_t38-go?si=gosGjwYmyyeZYBaA"
                                                                    title="Clasificare cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații laborant centrală termică</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/W6wldB9sD8w?si=NE3L02NKwSuEiyRi"
                                                                    title="Obligații laborant centrală termică"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație cazane</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Fbxh9Jc8iW4?si=P3Yuahm5e2GRpO9S"
                                                                    title="Legislație cazane"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>


                                        <div className="surface-0">
                                            <div className="grid">

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Operator umplere recipiente GPL">
                                    <p className="m-0">

                                    <div className="surface-0">
                                            <div className="grid">


                                            <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Obligații operator umplere GPL</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/wC6j5IdOrqs?si=FqzyDCOZSloBXluq"
                                                                    title="Obligații operator umplere recipiente GPL"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Legislație instalații GPL</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/28RcRghSefU?si=3Ms-VZJx5UBUpTSg"
                                                                    title="Legislație instalații GPL"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                        <div className="surface-0">
                                            <div className="grid">

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Inspecții teritoriale ISCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/ZKvh8emrIfI?si=L0ijnxJQ4gCWBnrt"
                                                                    title="Inspecții teritoriale ISCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Sucursale CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/Y3_Ya5f_02g?si=Sw5n3Ox-RsMfEAuq"
                                                                    title="Sucursale CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 lg:col-4">
                                                    <div className="p-3 h-full txt-ctr">
                                                        <div className="text-600 font-medium text-xl mb-2">Puncte de lucru CNCIR</div>
                                                        <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                                                            {/* <div className=" align-items-center ">
                                                                <img alt="" className="image-tutorial" src={require("../static/images/350x350/docs_350x350_001.jpg")} />
                                                            </div> */}
                                                            <div>
                                                                <iframe
                                                                    width="100%"
                                                                    height="125%"
                                                                    src="https://www.youtube.com/embed/4lJdPDG3Bvo?si=zWdNS0NBdf4E19X8"
                                                                    title="Puncte de lucru CNCIR"
                                                                    frameborder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    referrerpolicy="strict-origin-when-cross-origin"
                                                                    allowfullscreen="true">
                                                                </iframe>
                                                            </div>
                                                            {/* <br></br> */}
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>

                                        <div>
                                            <br />
                                            <br />
                                        </div>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>


                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>


                </div>




            </main>
            <div>
                <br />
                <br />
            </div>

            <Footer />
        </div>
    );
}
