// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import {
  BASE_URL,
  ICONS,
  STRING_CONST,
  dialogHeaderElement,
  dialogFooterContent,
  dialogFooterContentTwoButtons,
  doNothing,
} from "../../common/globals";
import { isEmptyString } from "../../common/validations";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListEquipments() {
  const baseURL = BASE_URL + "equipment/list";
  const listEquipmentConfigURL = BASE_URL + "equipment/config/list";
  const deleteLiftingURL = BASE_URL + "equipment/lifting/delete";
  const deletePressureURL = BASE_URL + "equipment/pressure/delete";
  const equipmentCreateSessionURL = BASE_URL + "equipmentdocuments/createsession";
  const downloadEquipmentsExcelURL = BASE_URL + "equipment/export/excel";
  const downloadDocumentURL = BASE_URL + "equipment/authorization/download";
  const checkIfClassificationAllowedURL = BASE_URL + "equipmentdocuments/selection/allowed";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [ddOptionsPrescriptions, setDdOptionsPrescriptions] = useState([]);

  // state objects
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [warningDialogMessage, setWarningDialogMessage] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);
  const [showSecurityDialog, setShowSecurityDialog] = useState(null);
  const [securityMesage, setSecurityMesage] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.CONTAINS },
    authorisationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // authentication details
  const { auth } = useAuth();

  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyUuid: "",
    prescriptionUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
    {
      id: 1,
      name: "prescriptionUuid",
      type: "selectClass",
      placeholder: "Prescriptie",
      label: "Prescriptie",
    },
  ];

  // load config
  useEffect(() => {
    axios
      .post(
        listEquipmentConfigURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          setDdOptionsPrescriptions(response.data.prescriptions);
          setDdOptionsCompany(response.data.companies);
          if (null !== mainCompanySessionUuid) {
            setValues({ ...values, companyUuid: mainCompanySessionUuid });
            loadEquipments(mainCompanySessionUuid, "");
          }
        } else {
          //setShowConfigNotLoadedDialog(true);
        }
      })
      .catch((error) => {
        //setShowConfigNotLoadedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hide selected rows warning dialog
  const setWarningFalse = (e) => {
    setShowWarningDialog(false);
  };

  // hide selected rows warning dialog
  const closeRowDialog = (e) => {
    setViewRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    let URL = "";
    if (selectedRow.type === "ridicat") {
      URL = deleteLiftingURL;
    } else {
      URL = deletePressureURL;
    }
    axios
      .post(
        URL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting worker certificate");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    if ("companyUuid" === e.target.name) {
      loadEquipments(e.target.value, values.prescriptionUuid);
    } else {
      loadEquipments(values.companyUuid, e.target.value);
    }
  };

  const loadEquipments = (uuid1, uuid2) => {
    axios
      .post(
        baseURL,
        {
          companyUuid: uuid1,
          prescriptionUuid: uuid2,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading equipments");
        console.log(error);
        alert("Eqchipamentele nu au putut fi incarcate");
      });
    // console.log("Submit");
  };

  // process start list equipments
  const handleGenerate1 = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setWarningDialogMessage("Selectează minim un echipament!");
      setShowWarningDialog(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        checkIfClassificationAllowedURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          if ("OK" === response.data) {
            generateListEquipments(uuids);
          } else {
            setSecurityMesage(response.data);
            setShowSecurityDialog(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea evidenței instalațiilor nu a putut fi pornită!");
      });
  };

  const generateListEquipments = async (uuids) => {
    axios
      .post(
        equipmentCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: "a7059747-edbf-11ee-bff3-74563c1b28c3",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("equipmentSessionUuid", response.data);
        navigate("/equipmentdocuments/generatedocslistselection");
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea evidenței instalațiilor nu a putut fi pornită!");
      });
  };

  // process start order equipments
  const handleGenerate2 = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setWarningDialogMessage("Selectează minim un echipament!");
      setShowWarningDialog(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        checkIfClassificationAllowedURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          if ("OK" === response.data) {
            generateOrderEquipments(uuids);
          } else {
            setSecurityMesage(response.data);
            setShowSecurityDialog(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea comenzilor CNCIR nu a putut fi pornită!");
      });
  };

  const generateOrderEquipments = async (uuids) => {
    axios
      .post(
        equipmentCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: "c79711c0-edbf-11ee-bff3-74563c1b28c3",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("equipmentSessionUuid", response.data);
        navigate("/equipmentdocuments/generatedocsorderselection");
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea comenzilor CNCIR nu a putut fi pornită!");
      });
  };

  // process start pv equipments
  const handleGenerate3 = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setWarningDialogMessage("Selectează minim un echipament!");
      setShowWarningDialog(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        checkIfClassificationAllowedURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          if ("OK" === response.data) {
            generatePVEquipments(uuids);
          } else {
            setSecurityMesage(response.data);
            setShowSecurityDialog(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea proceselor verbale RSVTI nu a putut fi pornită!");
      });
  };

  const generatePVEquipments = async (uuids) => {
    axios
      .post(
        equipmentCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: "ba56e509-edbf-11ee-bff3-74563c1b28c3",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("equipmentSessionUuid", response.data);
        navigate("/equipmentdocuments/generatedocspvselection");
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea proceselor verbale RSVTI nu a putut fi pornită!");
      });
  };

  // process start register equipments
  const handleGenerate4 = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setWarningDialogMessage("Selectează minim un echipament!");
      setShowWarningDialog(true);
      return;
    }
    if (isEmptyString(values.prescriptionUuid)) {
      setWarningDialogMessage("Selectează prescripţia!");
      setShowWarningDialog(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        checkIfClassificationAllowedURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (null !== response && null !== response.data) {
          if ("OK" === response.data) {
            generateRegisterEquipments(uuids);
          } else {
            setSecurityMesage(response.data);
            setShowSecurityDialog(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea registrelor de supraveghere nu a putut fi pornită!");
      });
  };

  const generateRegisterEquipments = async (uuids) => {
    axios
      .post(
        equipmentCreateSessionURL,
        {
          uuids: uuids,
          prescriptionUuid: values.prescriptionUuid,
          typeUuid: "d203abfa-edbf-11ee-bff3-74563c1b28c3",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("equipmentSessionUuid", response.data);
        navigate("/equipmentdocuments/generatedocsregisterselection");
      })
      .catch((error) => {
        console.log(error);
        alert("Generarea registrelor de supraveghere nu a putut fi pornită!");
      });
  };

  // start equipment excel export
  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      // setWarningDialogMessage("Selectează minim un echipament!");
      // setShowWarningDialog(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă echipamente.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadEquipmentsExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        link.click();
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Info)}
                  alt="Detalii"
                  title="Detalii"
                  onClick={() => viewRow(rowData)}
                />
              </td>
              <td>&nbsp;&nbsp;</td>
              <td>
                <img
                  className="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Şterge"
                  title="Şterge"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  const handleGetDocument = async (type, equipmentAuthorisationDocumentUuid, equipmentAuthorisationDocumentFileName, e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = equipmentAuthorisationDocumentFileName;
    axios
      .post(
        downloadDocumentURL,
        {
          type: type,
          equipmentAuthorisationDocumentUuid: equipmentAuthorisationDocumentUuid,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], {}));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Listă echipamente</button>
      <div className="spaceDiv" />
      <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingListDisabled)} alt="Listă echipamente" title="Listă echipamente" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listsafetyvalves"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureSafetyValveList)}
          alt="Listă supape de siguranţă"
          title="Listă supape de siguranţă"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExport)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGenerateOrderDocuments)}
        alt="Generare comandă CNCIR"
        title="Generare comandă CNCIR"
        onClick={handleGenerate2}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGeneratePVDocuments)}
        alt="Generare procese verbale RSVTI"
        title="Generare procese verbale RSVTI"
        onClick={handleGenerate3}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGenerateRegisterDocuments)}
        alt="Generare registre de supraveghere"
        title="Generare registre de supraveghere"
        onClick={handleGenerate4}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration2)}
        alt="Generare evidență instalații"
        title="Generare evidență instalații"
        onClick={handleGenerate1}
      />
      <div className="spaceDiv" />
      {/* County and Town */}
      <div className="cardDropDown">
        <div className="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="cardDropDown">
        <div className="column">
          <FormSelect
            key={inputs[1].id}
            name={inputs[1].name}
            label={inputs[1].label}
            value={values[inputs[1].name]}
            ddOptions={ddOptionsPrescriptions}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="spaceDiv" />
      <div className="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="name" filter filterPlaceholder="Caută" sortable header="Denumirea instalaţiei"></Column>
          <Column field="identifier" filter filterPlaceholder="Caută" sortable header="Nr. înregistrare/an fabricaţie"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru"></Column>
          <Column field="type" filter filterPlaceholder="Caută" sortable header="Categorie"></Column>
          <Column field="authorisationNumber" filter filterPlaceholder="Caută" sortable header="Nr. autorizaţie ISCIR"></Column>
          <Column field="authorizedUntil" sortable dataType="date" header="Scadenţa"></Column>
          <Column field="status" filter filterPlaceholder="Caută" sortable header="Stare instalaţie"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <div className="spaceDiv" />
      <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingListDisabled)} alt="Listă echipamente" title="Listă echipamente" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listsafetyvalves"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureSafetyValveList)}
          alt="Listă supape de siguranţă"
          title="Listă supape de siguranţă"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExport)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGenerateOrderDocuments)}
        alt="Generare comandă CNCIR"
        title="Generare comandă CNCIR"
        onClick={handleGenerate2}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGeneratePVDocuments)}
        alt="Generare procese verbale RSVTI"
        title="Generare procese verbale RSVTI"
        onClick={handleGenerate3}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentGenerateRegisterDocuments)}
        alt="Generare registre de supraveghere"
        title="Generare registre de supraveghere"
        onClick={handleGenerate4}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration2)}
        alt="Generare evidență instalații"
        title="Generare evidență instalații"
        onClick={handleGenerate1}
      />
      <div className="spaceDiv" />
      <Dialog
        visible={viewRowDialog}
        modal
        header={dialogHeaderElement("Detalii echipament")}
        footer={dialogFooterContent(STRING_CONST.OK, closeRowDialog)}
        style={{ width: "50rem" }}
        onHide={() => setViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td className="tdleft">Denumirea instalaţiei:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.name : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Nr. înregistrare/an fabricaţie:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.identifier : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Cod punct de lucru:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Categorie:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.type : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Nr. autorizaţie ISCIR:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.authorisationNumber : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Scadenţa:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.authorizedUntil : ""}</td>
            </tr>
            <tr>
              <td className="tdleft">Stare instalaţie:</td>
              <td className="tdrigth">{null != selectedRow ? selectedRow.status : ""}</td>
            </tr>
            <tr>
              <td className="tdleft"></td>
              <td className="tdrigth">
                {null != selectedRow &&
                selectedRow.equipmentAuthorisationDocumentUuid !== null &&
                selectedRow.equipmentAuthorisationDocumentUuid !== "" ? (
                  <>
                    <img
                      className="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExport)}
                      alt={"Descarca " + selectedRow.equipmentAuthorisationDocumentFileName}
                      title={"Descarca " + selectedRow.equipmentAuthorisationDocumentFileName}
                      onClick={(e) =>
                        handleGetDocument(
                          selectedRow.type,
                          selectedRow.equipmentAuthorisationDocumentUuid,
                          selectedRow.equipmentAuthorisationDocumentFileName,
                          e
                        )
                      }
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.equipmentAuthorisationDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContentTwoButtons(STRING_CONST.DA, closeDeleteDialogAndDelete, STRING_CONST.NU, closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi echipamentul: {null != selectedRow ? selectedRow.manufacturingSeries : ""} ?</p>
      </Dialog>
      <Dialog
        visible={showWarningDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, setWarningFalse)}
        style={{ width: "50rem" }}
        onHide={() => setShowWarningDialog(false)}
      >
        <p className="m-0">{warningDialogMessage}</p>
      </Dialog>
      <Dialog
        visible={showSecurityDialog}
        modal
        header={dialogHeaderElement(STRING_CONST.ATENTIE)}
        footer={dialogFooterContent(STRING_CONST.OK, () => setShowSecurityDialog(false))}
        style={{ width: "450px" }}
        onHide={() => setShowSecurityDialog(false)}
      >
        <p className="m-0">{securityMesage}</p>
      </Dialog>
    </div>
  );
}
