import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogMissingImportFiles from "../../components/DialogMissingImportFiles";
import DialogProcessingData from "../../components/DialogProcessingData";
import ProcessImportErrors from "../../components/ProcessImportErrors";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ImportCompanyBranches() {
  const importCompanyURL = BASE_URL + "import/company";

  const [fileC, setFileC] = useState(null);
  const [fileP, setFileP] = useState(null);
  const [showProcessingDialog, setShowProcessingDialog] = useState(null);
  const [showProcessedDialog, setShowProcessedDialog] = useState(null);
  const [showNotProcessedDialog, setShowNotProcessedDialog] = useState(null);
  const [showMissingImportFilesDialog, setShowMissingImportFilesDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);

  const { auth } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileC === null && fileP === null) {
      setShowMissingImportFilesDialog(true);
      return;
    }
    setShowProcessingDialog(true);
    setValidationErrors(null);
    const formData = new FormData();
    if (fileC !== null) {
      formData.append("files", fileC);
    }
    if (fileP !== null) {
      formData.append("files", fileP);
    }
    axios
      .post(importCompanyURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setShowProcessingDialog(false);
        if (null !== response && null !== response.data) {
          if (response.data.statusType !== "OK") {
            setValidationErrors(response.data);
          } else {
            setShowProcessedDialog(true);
          }
        } else {
          setShowNotProcessedDialog(true);
        }
      })
      .catch((error) => {
        setShowProcessingDialog(false);
        setShowNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button className="buttonClassGreen">Importă firme/puncte de lucru</button>
      <div className="spaceDiv" />
      <Link to={"/companyBranch/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchList)} alt="Listă puncte de lucru" title="Listă puncte de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAdd)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchImportDisabled)}
        alt="Importă firme/puncte de lucru"
        title="Importă firme/puncte de lucru"
      />
      <form className="formArea">
        <div className="row">
          <div className="column">
            Firme şi puncte de lucru:
            <br />
            <input type="file" onChange={(e) => setFileC(e.target.files[0])} />
          </div>
          <div className="column">
            <p>&nbsp;</p>
          </div>
        </div>

        <div className="row">
          <div className="column">
            Personal firme şi puncte de lucru:
            <br />
            <input type="file" onChange={(e) => setFileP(e.target.files[0])} />
          </div>
          <div className="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {showProcessingDialog !== true ? (
          <div className="rowdoublecentered">
            <div className="columnbutton">
              <img
                className="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.CompanyBranchSaveAll)}
                alt="Importă"
                title="Importă"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {null !== validationErrors && null !== validationErrors.statusType && validationErrors.statusType !== "OK" ? (
          <ProcessImportErrors validationErrors={validationErrors} />
        ) : null}
      </form>
      <div className="spaceDiv" />
      <Link to={"/companyBranch/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchList)} alt="Listă puncte de lucru" title="Listă puncte de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAdd)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchImportDisabled)}
        alt="Importă firme/puncte de lucru"
        title="Importă firme/puncte de lucru"
      />
      <div className="spaceDiv" />
      <DialogProcessingData isVisible={showProcessingDialog} />
      <DialogDataProcessed
        isVisible={showProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowProcessedDialog(false))}
        onHide={() => setShowProcessedDialog(false)}
      />
      <DialogDataNotProcessed
        isVisible={showNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setShowNotProcessedDialog(false))}
        onHide={() => setShowNotProcessedDialog(false)}
      />
      <DialogMissingImportFiles
        isVisible={showMissingImportFilesDialog}
        footer={dialogFooterContent("OK", () => setShowMissingImportFilesDialog(false))}
        onHide={() => setShowMissingImportFilesDialog(false)}
      />
    </div>
  );
}
